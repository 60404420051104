import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Helmet from '../../utils/head'
import Loading from '../../components/molecules/Loading'
import Markups from '../../Markups'

import { pageFetchData } from '../../store/modules/page/actions'

const Main = ({ match, pageSlug }) => {
  const history = useHistory()
  const [pageName, setPageName] = useState('')
  const [placesSlug, setPlacesSlug] = useState('')
  const { pageId, resourceId, id, placeId } = useParams()
  const dispatch = useDispatch()
  const { menu, home } = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const { language } = useSelector((state) => state.language)
  const { loading, contents } = useSelector((state) => state.page)
  const slug = pageSlug || useParams().slug

  const redirectStore = () => {
    const storePages = menu.reduce(
      (array, current) => [
        ...array,
        ...current.pages.reduce(
          (_array, page) =>
            page.slug?.includes('store') ? [..._array, page] : _array,
          []
        ),
      ],
      []
    )

    const defaultStore = storePages.reduce(
      (result, current) =>
        result.slug.split('/')[2] < current.slug.split('/')[2]
          ? result
          : current,
      storePages[0]
    )

    return history.push(defaultStore.path)
  }

  useEffect(() => {
    if (match.url.includes('places')) {
      setPlacesSlug('places')
    }
  }, [match])

  useEffect(() => {
    const getData = async () => {
      if (menu) {
        if (slug === 'store') return redirectStore()

        const activePage = menu
          .map((itens) => {
            const item = itens.pages.find((pageItem) => {
              if (placeId) {
                return pageItem.slug === `places/${placeId}`
              }
              if (slug) {
                return pageItem.slug === slug.toLowerCase()
              }
              if (pageId) {
                return Number(pageItem.external_id) === Number(pageId)
              }

              return pageItem.slug === `store/${resourceId}/${id}/1`
            })

            return item
          })
          .filter((i) => i)[0]

        if (!activePage) {
          return history.push(home.path)
        }

        setPageName(activePage.title)

        dispatch(pageFetchData(activePage.external_id, activePage.couple_id))
      }

      return false
    }

    if (
      (user.profile.private && user.profile.logged) ||
      !user.profile.private
    ) {
      getData()
    }
  }, [slug, pageId, placeId, resourceId, menu])

  return (
    <>
      <Helmet pageName={pageName} lang={language.htmlTag} />
      <Loading loaded={!loading} />
      {!loading &&
        contents.map((content, index) => (
          <Markups
            key={`${content.type}-${content.id}`}
            isEven={content.isEven}
            type={content.type}
            content={content.content}
            data={content}
            afterMarkup={contents[index + 1] && contents[index + 1].type}
            beforeMarkup={contents[index - 1] && contents[index - 1].type}
            attachedToHero={!!contents.find((item) => item.type === 'hero')}
            slug={
              slug || (match.url.includes('places') ? placesSlug : 'places')
            }
          />
        ))}
    </>
  )
}

Main.defaultProps = {
  pageSlug: undefined,
}

Main.propTypes = {
  match: PropTypes.object.isRequired,
  pageSlug: PropTypes.string,
}

export default Main
