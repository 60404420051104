import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import * as S from './styles';

const Modal = ({ show, title, close, children }) => {
  const nodeRef = useRef(null);

  useEffect(() => {
    document.documentElement.style.overflow = show ? 'hidden' : 'auto';
  }, [show]);

  useEffect(() => {
    const onKeydownEscape = (e) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    document.addEventListener('keydown', onKeydownEscape);
    return () => {
      document.removeEventListener('keydown', onKeydownEscape);
    };
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={500}
      classNames="modal"
      unmountOnExit
    >
      {(state) => (
        <S.Container ref={nodeRef} show={show}>
          <S.Content
            className={state !== 'exited' ? 'modal-content__show' : ''}
          >
            <S.Header>
              <S.Title>{title}</S.Title>
              <S.Close onClick={close} />
            </S.Header>
            <S.Body>{children}</S.Body>
          </S.Content>
          <S.Overlay onClick={close} show={show} />
        </S.Container>
      )}
    </CSSTransition>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default Modal;
