import * as Yup from 'yup'

export const passwordValidation = Yup.string().required(
  'Você precisa digitar a senha'
)

const schema = Yup.object().shape({
  password: passwordValidation,
})

export default schema
