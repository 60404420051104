import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import isDomain from '../../utils/isDomain';

import Loading from '../../components/molecules/Loading';

const MainStore = ({ match }) => {
  const { home, mainList } = useSelector((state) => state.menu);
  const { login } = useParams();

  const pathStoreMain = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const domain = isDomain() ? `` : `/${login}`;
    const urlMobileParams = queryParams.get('mobile');
    const urlTemplateParams = queryParams.get('template');
    let urlParams = '';

    if (urlMobileParams && urlTemplateParams) {
      urlParams = `/?mobile=${urlMobileParams}&template=${urlTemplateParams}`;
    } else if (urlMobileParams) {
      urlParams = `/?mobile=${urlMobileParams}`;
    } else if (urlTemplateParams) {
      urlParams = `/?template=${urlTemplateParams}`;
    }
    // Redirect list
    if (mainList?.slug !== null && mainList?.slug !== undefined) {
      if (mainList.store) {
        return `${domain}/${mainList.slug}`;
      }

      return `${domain}/${home.slug}`;
    }

    if (mainList === null) {
      return `${domain}/${home.slug}`;
    }

    return `${domain}/pages/${mainList.external_id}${urlParams}`;
  };

  return (
    <Suspense fallback={<Loading />}>
      <Redirect to={pathStoreMain()} />
    </Suspense>
  );
};

MainStore.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MainStore;
