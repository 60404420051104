import { ReactComponent as IcaseiPlan1 } from './icasei-plan-1.svg'
import { ReactComponent as IcaseiPlan2 } from './icasei-plan-2.svg'
import { ReactComponent as IcaseiPlan3 } from './icasei-plan-3.svg'
import { ReactComponent as IcaseiPlan4 } from './icasei-plan-4.svg'
import { ReactComponent as IcaseiTailorMade } from './icasei-tailor-made.svg'

const icons = {
  1: IcaseiPlan1,
  2: IcaseiPlan2,
  3: IcaseiPlan3,
  4: IcaseiPlan4,
  5: IcaseiTailorMade,
}

export default icons
