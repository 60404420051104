import * as Yup from 'yup'
import documentValidator from '../../../../utils/documentValidator'

export const nameValidation = Yup.string()
  .required('O campo nome é obrigatório.')
  .matches(/[A-zÀ-û]{2,}\s/, 'Informe seu nome completo.')

export const documentValidation = Yup.string()
  .required('O campo documento é obrigatório')
  .test('validateDocument', 'O documento informado parece incorreto', (value) =>
    documentValidator(value)
  )

const schema = Yup.object().shape({
  name: nameValidation,
  document: documentValidation,
})

export default schema
