import React from 'react';

import * as S from './styles';

const SelectField = ({
  label,
  register,
  required,
  name,
  id,
  options,
  placeholder,
  fullWidth,
  invalid,
  value,
  onChange = () => {},
  onBlur = () => {},
}) => {
  const defaultParams = {
    value,
    onChange,
    onBlur,
  };

  return (
    <S.Wrapper fullWidth={fullWidth}>
      <S.Select
        name={name}
        id={id}
        fullWidth={fullWidth}
        invalid={invalid}
        aria-label={id}
        {...(!register && { ...defaultParams })}
        {...(register &&
          register(label, {
            required,
            onChange: (e) => onChange(e),
            onBlur: (e) => onBlur(e),
          }))}
      >
        {placeholder && (
          <option label={placeholder} value="" disabled>
            {placeholder}
          </option>
        )}

        {options.map((option) => (
          <option label={option.label} value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </S.Select>
      <S.Arrow />
    </S.Wrapper>
  );
};

export default SelectField;
