import styled, { css } from 'styled-components'
import hexToRgba from '../../utils/hexToRgba'

const borderColor = (theme) => hexToRgba(theme.colors.content, 0.16)
const barColor = (theme) => hexToRgba(theme.colors.content, 0.16)

export const ButtonToggle = styled.button.attrs({
  className: 'music-player__button-toggle',
})`
  ${({ theme }) => css`
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 42px;
    height: 42px;
    background: ${theme.colors.body};
    border: 1px solid ${borderColor(theme)};
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${theme.colors.main};
    z-index: 100;
    transition: filter 0.2s linear;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      filter: brightness(0.96);
    }

    > svg {
      width: 24px;
      height: 24px;
    }
  `}
`

const playerModifiers = {
  isOpen: () => css`
    opacity: 1;
    pointer-events: all;
  `,
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  ${({ theme, isOpen }) => css`
    position: fixed;
    bottom: 40px;
    left: 56px;
    width: 320px;
    background: ${theme.colors.body};
    padding: 24px;
    border: 1px solid ${borderColor(theme)};
    border-radius: 4px;
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
    box-sizing: border-box;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    ${isOpen && playerModifiers.isOpen()}

    @media (max-width: 540px) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
      z-index: 1000;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `}
`

export const ButtonClose = styled.button.attrs({
  className: 'music-player__button-close',
})`
  ${({ theme }) => css`
    color: ${theme.colors.content};
    background: none;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    transition: opacity 0.2s linear;
    opacity: 0.6;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      opacity: 1;
    }

    > svg {
      width: 12px;
      height: 12px;
    }

    @media (max-width: 540px) {
      width: 56px;
      height: 56px;

      > svg {
        width: 16px;
        height: 16px;
      }
    }
  `}
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 540px) {
    flex-direction: column;
    margin-bottom: 32px;
  }
`

export const Cover = styled.div.attrs({
  className: 'music-player__cover',
})`
  ${({ theme }) => css`
    border-radius: 50%;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.08),
      inset 1px 1px 1px 6px rgba(255, 255, 255, 0.16),
      inset -1px -1px 1px 4px rgba(0, 0, 0, 0.08);
    border: solid 4px ${theme.colors.body};
    box-sizing: content-box;
    margin-left: -4px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
    background: ${theme.colors.main};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.body};
      width: 60%;
      filter: drop-shadow(0px 4px 0px rgba(255, 255, 255, 0.2));
    }

    @media (max-width: 540px) {
      width: 120px;
      height: 120px;
      margin-right: unset;
      margin-left: unset;
      margin-bottom: 12px;

      > svg {
        filter: drop-shadow(0px 8px 0px rgba(255, 255, 255, 0.2));
      }
    }
  `}
`

export const MusicInfo = styled.div`
  flex: 1;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  ${({ theme }) => css`
    font-family: Georgia, serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    color: ${theme.colors.content};
    text-align: left;

    @media (max-width: 540px) {
      margin-bottom: 2px;
    }
  `}
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  ${({ theme }) => css`
    font-family: Georgia, serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    color: ${theme.colors.content};
    font-style: italic;
    text-align: left;

    @media (max-width: 540px) {
      margin-bottom: 2px;
    }
  `}
`

export const MusicTracks = styled.p.attrs({
  className: 'music-player__tracks',
})`
  ${({ theme }) => css`
    font-family: Georgia, serif;
    font-size: 10px;
    line-height: 16px;
    font-weight: normal;
    color: ${theme.colors.content};
    text-align: left;
  `}
`

export const Config = styled.div`
  display: flex;
  align-items: center;
`

export const ProgressWrapper = styled.div.attrs({
  className: 'music-player__progress',
})`
  width: 100%;
`

export const Progress = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 3px;
    background: ${barColor(theme)};

    @media (max-width: 540px) {
      height: 8px;
      border-radius: 4px;
    }
  `}
`

const progressWidth = ({ progress, isLoading }) => ({
  style: {
    width: isLoading ? '0%' : `${progress}%`,
  },
})

export const ProgressSlider = styled.div.attrs(progressWidth)`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 3px;
    background: ${theme.colors.main};
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: block;
      width: 8px;
      height: 16px;
      border-radius: 5px;
      box-shadow: -1px 2px 1px 1px rgba(0, 0, 0, 0.08),
        inset 1px 1px 1px 2px rgba(255, 255, 255, 0.16),
        inset -1px -1px 1px 1px rgba(0, 0, 0, 0.08);
      border: 1px solid ${theme.colors.body};
      background-color: ${theme.colors.main};
    }

    @media (max-width: 540px) {
      height: 8px;
      border-radius: 4px;
    }
  `}
`

export const ProgressRange = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const VolumeWrapper = styled.div.attrs({
  className: 'music-player__volume',
})`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonMute = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.main};
    font-size: 0;
    margin: 0 8px 0 16px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 0.2s linear;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &:focus:hover {
      opacity: 0.6;
    }
  `}
`

export const Volume = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 64px;
    height: 6px;
    border-radius: 3px;
    background: ${barColor(theme)};

    @media (max-width: 540px) {
      height: 8px;
      border-radius: 4px;
    }
  `}
`

const volumeWidth = ({ volume, muted }) => ({
  style: {
    width: muted ? '0%' : `${volume}%`,
  },
})

export const VolumeSlider = styled.div.attrs(volumeWidth)`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 3px;
    background: ${theme.colors.main};
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 540px) {
      height: 8px;
      border-radius: 4px;
    }
  `}
`

export const VolumeRange = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const Time = styled.p.attrs({
  className: 'music-player__time',
})`
  ${({ theme }) => css`
    font-family: Georgia, serif;
    font-size: 11px;
    line-height: 16px;
    font-weight: normal;
    color: ${theme.colors.content};
    text-align: left;

    margin-top: 2px;
  `}
`

export const Controls = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 540px) {
    margin-top: 32px;
  }
`

const Action = styled.button`
  ${({ theme }) => css`
    font-size: 0;
    background: none;
    border: none;
    color: ${theme.colors.main};
    transition: 0.2s linear;
    cursor: pointer;

    width: 48px;
    height: 48px;

    &:disabled {
      cursor: initial;
    }

    > svg circle {
      stroke: ${theme.colors.main};
    }

    @media (max-width: 540px) {
      width: 64px;
      height: 64px;
    }
  `}
`

export const NextPrev = styled(Action)`
  ${({ theme }) => css`
    > svg {
      width: 24px;
      filter: drop-shadow(0px 2px 0.5px rgba(0, 0, 0, 0.08));
      transition: 0.2s linear;

      > path {
        stroke: ${theme.colors.body};
      }
    }

    &:focus,
    &:hover {
      opacity: 0.8;
    }

    &:focus:hover {
      opacity: 0.65;
    }

    &:active {
      transform: scale(0.92);
      > svg {
        filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.08));
      }
    }

    @media (max-width: 540px) {
      > svg {
        width: 32px;
      }
    }
  `}
`

export const TogglePlay = styled(Action)`
  ${({ theme }) => css`
    margin: -4px 4px;
    border-radius: 35px;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.08),
      inset 1px 1px 1px 6px rgba(255, 255, 255, 0.16),
      inset -1px -1px 1px 4px rgba(0, 0, 0, 0.08);
    border: solid 4px ${theme.colors.body};
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    background-color: ${theme.colors.main};
    color: ${theme.colors.body};

    &:focus {
      opacity: 0.8;
    }

    &:hover {
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08),
        inset 1px 1px 1px 6px rgba(255, 255, 255, 0.16),
        inset -1px -1px 1px 1px rgba(0, 0, 0, 0.08);
    }

    &:active {
      transform: scale(0.96);
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08),
        inset 1px 1px 1px 6px rgba(255, 255, 255, 0.16),
        inset -1px -1px 1px 1px rgba(0, 0, 0, 0.08);
    }

    > svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 540px) {
      width: 72px;
      height: 72px;
    }
  `}
`

export const LoadingButton = styled(Action)`
  margin: 0 8px;
`
