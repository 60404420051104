import React from 'react'

import Spacer from '../../atoms/Spacer'
import CardSlider from '../../organisms/CardSlider'
import Subtitle from '../../atoms/Subtitle'

import * as S from './styles'

export default function Card() {
  return (
    <S.Container>
      <Spacer size="small" />
      <Subtitle>
        Personalize um cartão com sua mensagem no verso. O casal irá amar ♥
      </Subtitle>

      <Spacer size="small" />
      <CardSlider />
      <S.Text>
        Após as comemorações, entregaremos as mensagens em uma caixa
        personalizada para o casal.
      </S.Text>
    </S.Container>
  )
}
