import * as Yup from 'yup'
import { isPossiblePhoneNumber } from 'react-phone-number-input/input'

const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
const maxLength = 4000

export const nameValidation = Yup.string()
  .required('O campo nome é obrigatório')
  .nullable('Você precisa selecionar uma opção.')
  .matches(/[A-zÀ-û]{2,}\s+[A-zÀ-û]{2,}/, 'Esse não parece um nome completo')

export const emailValidation = Yup.string()
  .email('Digite um e-mail válido.')
  .nullable('Você precisa selecionar uma opção.')
  .required('O campo email é obrigatório.')
  .test('invalidEmail', 'Digite um e-mail válido.', (value) => {
    return value.length ? regexEmail.test(value) : true
  })

const phoneValidation = Yup.string()
  .required('O campo de telefone é obrigatório.')
  .test('invalidPhone', 'O valor informado é inválido.', (phoneNumber) => {
    return isPossiblePhoneNumber(phoneNumber || '')
  })

export const messageValidation = Yup.string()
  .max(maxLength, 'A mensagem não pode ser maior que 4000 caracteres')
  .nullable('Você precisa selecionar uma opção.')
  .required('O campo mensagem é obrigatório')

const schema = Yup.object().shape({
  name: nameValidation,
  email: emailValidation,
  phone: phoneValidation,
  message: messageValidation,
})

export default schema
