import { takeLatest, call, put, all, select } from 'redux-saga/effects'

import htmlParser from '../../../utils/htmlParser'
import api from '../../../services/api'

import { pageSuccessFetchData, pageErrorOnFetchData } from './actions'

export function* fetchData({ payload }) {
  try {
    const { preview, templateId } = yield select((state) => state.user)
    const queryParams = new URLSearchParams(window.location.search)

    const response = yield call(
      api.get,
      import.meta.env.VITE_REACT_APP_ENV === 'cd'
        ? `/api/v1/pgs/${payload.id}.json`
        : '/api/v1/pages',
      {
        params: {
          couple_id: payload.coupleId,
          external_id: payload.id,
          template_id: templateId,
          preview,
          is_mobile: queryParams.get('mobile'),
        },
      }
    )

    const { data } = response

    const welcome = {
      id: 0,
      type: 'welcome',
      content: htmlParser({
        title: data.title,
        subtitle: data.subtitle,
        image: data.image,
      }),
    }
    const contentWithoutHero = data.contents
      .filter((item) => item.type !== 'hero' && item.type !== 'hero_internal')
      .map((item, index) => ({
        ...item,
        isEven: index % 2 === 0,
        content:
          item.type === 'text' || item.type === 'rsvp_home'
            ? item.content
            : htmlParser(item.content),
      }))
    const contentWithHero = data.contents
      .filter((item) => item.type === 'hero' || item.type === 'hero_internal')
      .map((item) => ({ ...item, content: htmlParser(item.content) }))

    window.top.postMessage({ message: 'changePage', pageId: payload.id }, '*')

    yield put(
      pageSuccessFetchData(
        [...contentWithHero, welcome, ...contentWithoutHero],
        payload.id
      )
    )
  } catch (err) {
    yield put(pageErrorOnFetchData())
  }
}

export default all([takeLatest('@page/GET_DATA_REQUEST', fetchData)])
