import styled, { css } from 'styled-components'

export const Wrapper = styled.svg.attrs({
  className: 'flag',
})`
  ${({ theme }) => css`
    width: 40px;
    height: 127px;
    box-shadow: ${theme.components.flag.boxShadow
      ? theme.components.flag.boxShadow
      : 'none'};
  `}
`

export const Flag = styled.path.attrs({
  className: 'flag--background',
})`
  ${({ theme }) => css`
    fill: ${theme.colors[theme.components.flag.backgroundColor]};
  `}
`

const linesModifiers = {
  customLinesColor: (colors, color) => css`
    stroke: ${colors[color]};
  `,
}

export const Lines = styled.path`
  ${({ theme }) => css`
    stroke: ${theme.colors[theme.components.flag.color]};
    stroke-linecap: square;

    ${!!theme.components.flag.linesColor &&
    linesModifiers.customLinesColor(
      theme.colors,
      theme.components.flag.linesColor
    )}
  `}
`

export const Text = styled.text`
  ${({ theme }) => css`
    fill: ${theme.colors[theme.components.flag.color]};
    font-family: ${theme.fonts[theme.components.flag.fontFamily].family};
    letter-spacing: 1px;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 300;
    font-weight: ${theme.components.flag.fontWeight};
    text-transform: ${theme.components.flag.textTransform};
  `}
`
