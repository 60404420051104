import styled, { css, keyframes } from 'styled-components'

const skeletonPulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`

const skeletonWave = keyframes`
  0% {
    transform: translateX(-100%);
  }

  50% {
    /* +0.5s of delay between each loop */
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
`

const variants = {
  text: () => css`
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    transform-origin: 0px 55%;
    transform: scale(1, 0.6);
    border-radius: 4px / 6.7px;

    &::before {
      content: '‎';
      line-height: 1.5;
      color: transparent;
    }
  `,
  circular: () => css`
    border-radius: 50%;
  `,
  rectangular: () => css``,
  rounded: () => css`
    border-radius: 2px;
  `,
}

const animations = {
  pulse: () => css`
    animation: 1.5s ease-in-out 0.5s infinite normal none running
      ${skeletonPulse};
  `,
  wave: () => css`
    position: relative;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &::after {
      content: '';
      animation: ${skeletonWave} 1.6s linear 0.5s infinite;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.04),
        transparent
      );
      position: absolute;
      transform: translateX(-100%);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  `,
}

export const Skeleton = styled.span`
  ${({ variant, animation }) => css`
    display: block;
    background-color: rgba(0, 0, 0, 0.11);

    ${variants[variant]()}
    ${animation && animations[animation]()}
  `}
`
