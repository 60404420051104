import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import ArrowRightIcon from './ArrowRightIcon'
import Collapse from '../../molecules/Collapse'
import StoreIcon from '../../molecules/Collapse/StoreIcon'

import * as S from './styles'

export default function ListStore({ items }) {
  const history = useHistory()

  const openSinglePage = (link) => {
    history.push(link)
  }

  return (
    <S.Wrapper>
      <S.Items>
        {items.map((item) => {
          const singlePage = item.pages.length === 1 ? item.pages[0] : null

          return (
            <Collapse
              key={item.id}
              icon={<StoreIcon color="var(--main)" />}
              label={item.name}
              description={
                item.giftsQuantity === 1
                  ? '1 presente'
                  : `${item.giftsQuantity} presentes`
              }
              hideRightIcon={!!singlePage}
              onClick={
                singlePage ? () => openSinglePage(singlePage.path) : null
              }
            >
              {!singlePage && (
                <S.CollapseBody>
                  <p>Encontramos {item.pages.length} páginas com essa lista:</p>
                  <ul>
                    {item.pages.map((page) => (
                      <li key={page.id}>
                        <Link to={page.path}>
                          <ArrowRightIcon color="var(--main)" /> {page.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </S.CollapseBody>
              )}
            </Collapse>
          )
        })}
      </S.Items>
    </S.Wrapper>
  )
}
