import styled from 'styled-components'

import { hexToRgba } from '../../../utils/lightenColor'

export const Header = styled.header.attrs({
  className: 'header',
})`
  width: 100%;
  position: fixed;
  padding: 0 15px;
  min-height: 99px;
  transition: background 0.5s;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ attachedToHero, theme }) =>
    attachedToHero
      ? theme.colors[theme.components.header.attachedToHero.wrapperBackground]
      : theme.colors[theme.components.header.default.wrapperBackground]};

  @media (max-width: 768px) {
    background: ${({ attachedToHero, theme }) =>
      attachedToHero
        ? theme.colors[
            theme.components.header.attachedToHero.wrapperBackgroundMobile
          ]
        : theme.colors[
            theme.components.header.default.wrapperBackgroundMobile
          ]};
  }

  border-bottom: 1px solid
    ${({ attachedToHero, theme }) =>
      attachedToHero
        ? hexToRgba(
            theme.colors[
              theme.components.header.attachedToHero.borderBottomColor
            ],
            theme.components.header.attachedToHero.borderOpacity
          )
        : hexToRgba(
            theme.colors[theme.components.header.default.borderBottomColor],
            theme.components.header.default.borderOpacity
          )};

  @media (max-width: 768px) {
    min-height: 64px;

    background: ${({ showNavigation, theme }) =>
      showNavigation
        ? theme.colors[theme.components.header.default.wrapperBackground]
        : ''};

    border-bottom: ${({ showNavigation, theme }) =>
      showNavigation
        ? `1px solid ${hexToRgba(
            theme.colors[theme.components.header.default.borderBottomColor],
            theme.components.header.default.borderOpacity
          )}`
        : ''};
  }
`

export const FlagWrapper = styled.div.attrs({
  className: 'header__flag-wrapper',
})`
  display: ${({ attachedToHero, theme }) =>
    theme.components.header[attachedToHero ? 'attachedToHero' : 'default']
      .showFlag
      ? 'block'
      : 'none'};
  position: absolute;
  right: 85px;
  bottom: 0;
  transform: translateY(100%);

  @media (max-width: 768px) {
    display: ${(props) => (props.showNavigation ? 'block' : 'none')};
    right: 16px;
    bottom: 0;
  }
`
