import styled, { css } from 'styled-components'
import { hexToRgba } from '../../../utils/lightenColor'

export const Wrapper = styled.div.attrs({
  className: 'radio',
})`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
`

export const RadioIcon = styled.span.attrs({
  className: 'radio__icon',
})`
  ${({ theme }) => css`
    position: absolute;
    top: 25%;

    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid ${theme.colors[theme.components.radio.empty.borderColor]};
    border-radius: 50%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const Radio = styled.input`
  ${({ theme: { colors, components } }) => css`
    z-index: 1;
    opacity: 0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:focus-visible + ${RadioIcon} {
      box-shadow: 0 0 0 3px
        ${hexToRgba(colors[components.radio.selected.borderColor], 0.3)};
    }

    &:checked + ${RadioIcon} {
      border-color: ${colors[components.radio.selected.borderColor]};

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${colors[components.radio.selected.markerColor]};
      }
    }
  `}
`
