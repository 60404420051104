import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

import * as S from './styles'

const DecorativeDivider = () => {
  const theme = useTheme()
  const [Icon, setIcon] = useState(null)
  const [attrSize, setAttrSize] = useState({})

  useEffect(() => {
    const getIcon = async () => {
      const { ReactComponent: ImportedIcon } = await import(
        `./assets/skins/${theme.components.decorativeDivider.skin}/divider.svg`
      )
      setIcon(() => ImportedIcon)
    }
    getIcon()
  }, [theme])

  useEffect(() => {
    if (
      import.meta.env.VITE_REACT_APP_ENV === 'cd' ||
      import.meta.env.VITE_REACT_APP_STATIC === 'static'
    ) {
      const ele = document.getElementById(
        theme.components.decorativeDivider.skin
      )
      if (ele) {
        const size = ele.getAttribute('viewBox').split(' ')

        setAttrSize({ width: size[2], height: size[3] })
      }
    }
  }, [Icon, theme.components.decorativeDivider.skin])

  return theme.components.decorativeDivider.display ? (
    <S.Wrapper>
      <S.Divider>
        {Icon &&
        (import.meta.env.VITE_REACT_APP_ENV === 'cd' ||
          import.meta.env.VITE_REACT_APP_STATIC === 'static') ? (
          <Icon
            id={theme.components.decorativeDivider.skin}
            width={attrSize?.width}
            height={attrSize?.height}
          />
        ) : (
          Icon && <Icon />
        )}
      </S.Divider>
    </S.Wrapper>
  ) : null
}

export default DecorativeDivider
