import produce from 'immer'

import storybookData from './storyDefaultParams'

const INITIAL_STATE = {
  profile: import.meta.env.VITE_STORYBOOK_RUNNING ? storybookData : null,
  loading: true,
  preview: null,
  mobile: null,
  templateId: null,
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@user/GET_DATA_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/GET_DATA_SUCCESS': {
        draft.profile = action.payload.user
        draft.preview = action.payload.preview
        draft.mobile = action.payload.mobile
        draft.loading = false
        break
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile.logged = 1
        break
      }
      case '@user/DEFINE_TEMPLATE': {
        draft.templateId = action.payload.templateId
      }
    }
  })
}
