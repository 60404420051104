import React from 'react'

import * as S from './styles'

type SkeletonProps = {
  variant?: 'circular' | 'rectangular' | 'rounded' | 'text'
  animation?: 'pulse' | 'wave' | false
} & React.HTMLProps<HTMLSpanElement>

export default function Skeleton({
  variant = 'rounded',
  animation = 'pulse',
  width,
  height,
  ...props
}: SkeletonProps) {
  return (
    <S.Skeleton
      variant={variant}
      animation={animation}
      style={{ width, height, ...props.style }}
      {...props}
    />
  )
}
