import produce from 'immer'

import storybookData from './storyDefaultParams'

const INITIAL_STATE = import.meta.env.VITE_STORYBOOK_RUNNING
  ? storybookData
  : {
      menu: null,
      home: {},
      mainList: {},
      loading: true,
    }

export default function menu(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@menu/GET_DATA_REQUEST': {
        draft.loading = true
        break
      }
      case '@menu/GET_DATA_SUCCESS': {
        draft.menu = action.payload.data
        draft.loading = false
        break
      }
      case '@menu/SET_HOME': {
        draft.home = action.payload.page
        break
      }
      case '@menu/SET_MAIN_LIST': {
        draft.mainList = action.payload.page
        break
      }
      default:
        return state
    }
  })
}
