import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import getImage from '../../utils/getImage'
import useMedia from '../../utils/useMedia'

import * as S from './styles'

import Title from '../../components/atoms/Title'
import Spacer from '../../components/atoms/Spacer'
import Subtitle from '../../components/atoms/Subtitle'
import DecorativeDivider from '../../components/atoms/DecorativeDivider'; //eslint-disable-line

export default function Welcome({
  data,
  afterMarkup,
  beforeMarkup,
  attachedToHero,
}) {
  const { title, subtitle, image } = data
  const { profile } = useSelector((state) => state.user)
  const theme = useTheme()
  const isMobile = useMedia('(max-width: 768px)')

  if (!title && !subtitle) {
    return <Spacer size={isMobile ? 'small' : 'medium'} />
  }

  return (
    <S.Wrapper>
      {(beforeMarkup !== 'hero' || !profile.exibition_configs.countdown) && (
        <Spacer size="medium" />
      )}
      {theme.components.welcome[attachedToHero ? 'attachedToHero' : 'default']
        .showTitle &&
        title && <Title data-testid="Title">{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <DecorativeDivider />
      {image && (
        <>
          <Spacer size="small" />
          <img
            src={getImage(profile.login, image, 'home', 'userfile')}
            alt="Foto do casal"
          />
        </>
      )}
      {afterMarkup !== 'text' && afterMarkup !== 'tips' && (
        <Spacer size="medium" />
      )}
    </S.Wrapper>
  )
}

Welcome.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  afterMarkup: PropTypes.string,
  attachedToHero: PropTypes.bool,
}

Welcome.defaultProps = {
  afterMarkup: null,
  attachedToHero: false,
}
