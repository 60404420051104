import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ExpandIcon from './ExpandIcon'

import * as S from './styles'

const Collapse = ({
  openDefault,
  hideRightIcon,
  onClick,
  icon,
  label,
  description,
  children,
}) => {
  const [open, setOpen] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(null)
  const refBody = useRef(null)

  useEffect(() => {
    if (openDefault) {
      setOpen(openDefault)
    }
  }, [openDefault])

  useEffect(() => {
    if (refBody.current) {
      setBodyHeight(refBody.current.offsetHeight)
    }
  }, [refBody])

  const handlerToggleOpen = () => {
    if (onClick) {
      onClick()
    } else {
      setOpen((s) => !s)
    }
  }

  return (
    <S.Collapse>
      <S.CollapseHeader onClick={handlerToggleOpen}>
        <S.CollapseHeaderContent>
          {icon && <S.Icon>{icon}</S.Icon>}
          <S.Info>
            <S.Title>{label}</S.Title>
            {description && <S.Description>{description}</S.Description>}
          </S.Info>
        </S.CollapseHeaderContent>
        {!hideRightIcon && (
          <S.CollapseHeaderAction open={open}>
            <ExpandIcon />
          </S.CollapseHeaderAction>
        )}
      </S.CollapseHeader>
      <S.CollapseBody
        open={open}
        render={bodyHeight !== null}
        ref={refBody}
        maxHeight={bodyHeight}
      >
        {children}
      </S.CollapseBody>
    </S.Collapse>
  )
}

Collapse.defaultProps = {
  openDefault: false,
  icon: null,
  onClick: null,
  hideRightIcon: false,
}

Collapse.propTypes = {
  openDefault: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  hideRightIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Collapse
