import styled, { css } from 'styled-components'

import bg from './assets/bg.png'
import Icons from './assets/sprt-pl3.png'

import eqSkin1 from './assets/eq-skn1.gif'
import eqOffSkin1 from './assets/eq-skn1_off.gif'
import eqSkin2 from './assets/eq-skn2.gif'
import eqOffSkin2 from './assets/eq-skn2_off.gif'
import eqSkin3 from './assets/eq-skn3.gif'
import eqOffSkin3 from './assets/eq-skn3_off.gif'
import eqSkin4 from './assets/eq-skn4.gif'
import eqOffSkin4 from './assets/eq-skn4_off.gif'
import eqSkin5 from './assets/eq-skn5.gif'
import eqOffSkin5 from './assets/eq-skn5_off.gif'
import eqSkin6 from './assets/eq-skn6.gif'
import eqOffSkin6 from './assets/eq-skn6_off.gif'

const config = {
  skin1: {
    color: '#714207',
    background: '#F1EBDD',
    volumeBorder: '#9b7022',
    volumeBackground: '#ffd03f',
    iconsPosition: {
      arrowLeft: '-97px 0',
      arrowRight: '-130px 0',
      song: '-50px -153px',
      artist: '-50px -144px',
      prev: '-23px 0',
      pause: '-106px 0',
      play: '0 0',
      next: '-44px 0',
      volume: '-80px 0',
      drag: '-65px 0',
    },
    eq: eqSkin1,
    eqOff: eqOffSkin1,
  },
  skin2: {
    color: '#3A857D',
    background: '#D9EEEA',
    volumeBorder: '#016A64',
    volumeBackground: '#009E95',
    iconsPosition: {
      arrowLeft: '-97px -15px',
      arrowRight: '-130px -15px',
      song: '-59px -153px',
      artist: '-59px -144px',
      prev: '-23px -24px',
      pause: '-106px -27px',
      play: '0 -27px',
      next: '-44px -24px ',
      volume: '-80px -12px',
      drag: '-65px -17px',
    },
    eq: eqSkin2,
    eqOff: eqOffSkin2,
  },
  skin3: {
    color: '#F05A60',
    background: '#F8E6E7',
    volumeBorder: '#CF343B',
    volumeBackground: '#F65B61',
    iconsPosition: {
      arrowLeft: '-97px -30px',
      arrowRight: '-130px -30px',
      song: '-68px -153px',
      artist: '-68px -144px',
      prev: '-23px -48px',
      pause: '-106px -54px',
      play: '0 -54px',
      next: '-44px -48px ',
      volume: '-80px -24px',
      drag: '-65px -34px',
    },
    eq: eqSkin3,
    eqOff: eqOffSkin3,
  },
  skin4: {
    color: '#00AEEF',
    background: '#ECF6FA',
    volumeBorder: '#0188B4',
    volumeBackground: '#00AEEF',
    iconsPosition: {
      arrowLeft: '-97px -45px',
      arrowRight: '-130px -45px',
      song: '-77px -153px',
      artist: '-77px -144px',
      prev: '-23px -72px',
      pause: '-106px -81px',
      play: '0 -81px',
      next: '-44px -72px ',
      volume: '-80px -36px',
      drag: '-65px -51px',
    },
    eq: eqSkin4,
    eqOff: eqOffSkin4,
  },
  skin5: {
    color: '#FD9320',
    background: '#FFF2E5',
    volumeBorder: '#DD8113',
    volumeBackground: '#FF9721',
    iconsPosition: {
      arrowLeft: '-97px -60px',
      arrowRight: '-130px -60px',
      song: '-86px -153px',
      artist: '-86px -144px',
      prev: '-23px -96px',
      pause: '-106px -108px',
      play: '0 -108px',
      next: '-44px -96px',
      volume: '-80px -48px',
      drag: '-65px -68px',
    },
    eq: eqSkin5,
    eqOff: eqOffSkin5,
  },
  skin6: {
    color: '#323232',
    background: '#F6F6F6',
    volumeBorder: '#515151',
    volumeBackground: '#6A6A6A',
    iconsPosition: {
      arrowLeft: '-97px -75px',
      arrowRight: '-130px -75px',
      song: '-95px -153px',
      artist: '-95px -144px',
      prev: '-23px -120px',
      pause: '-106px -135px',
      play: '0 -135px',
      next: '-44px -120px',
      volume: '-80px -60px',
      drag: '-65px -85px',
    },
    eq: eqSkin6,
    eqOff: eqOffSkin6,
  },
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  width: 616px;
  max-width: ${(props) => (props.show ? '100%' : 'auto')};
  height: 93px;
  background: ${(props) => config[props.skin].background} url(${bg}) no-repeat
    left;
  color: ${(props) => config[props.skin].color};
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;

  position: fixed;
  bottom: 0;
  left: ${(props) => (props.show ? 0 : '-566px')};
  transition: left 0.5s;
  z-index: 10;
`

export const PlayerTop = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerTopLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

export const MusicInfo = styled.div`
  flex: 1;
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  font-size: 12px;
  font-size: 0.75rem;

  &::before {
    background: url(${Icons}) no-repeat;
    background-position: ${(props) => config[props.skin].iconsPosition.song};
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 8px;
  }
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  font-size: 12px;
  font-size: 0.75rem;

  &::before {
    background: url(${Icons}) no-repeat;
    background-position: ${(props) => config[props.skin].iconsPosition.artist};
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 8px;
  }
`

export const Tracks = styled.p`
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 20px;
  font-size: 1.25rem;
`

export const Controls = styled.div.attrs({
  className: 'music-player__controls',
})`
  width: 72px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Prev = styled.button.attrs({
  className: 'music-player__previous',
})`
  background: url(${Icons}) no-repeat;
  width: 21px;
  height: 24px;
  background-position: ${(props) => config[props.skin].iconsPosition.prev};
  border: none;
`

export const Pause = styled.button.attrs({
  className: 'music-player__pause',
})`
  background: url(${Icons}) no-repeat;
  width: 23px;
  height: 27px;
  background-position: ${(props) => config[props.skin].iconsPosition.pause};
  margin-bottom: 2px;
  border: none;
`

export const Play = styled.button.attrs({
  className: 'music-player__play',
})`
  background: url(${Icons}) no-repeat;
  width: 23px;
  height: 27px;
  background-position: ${(props) => config[props.skin].iconsPosition.play};
  margin-bottom: 2px;
  border: none;
`

export const Next = styled.button.attrs({
  className: 'music-player__next',
})`
  background: url(${Icons}) no-repeat;
  width: 21px;
  height: 24px;
  background-position: ${(props) => config[props.skin].iconsPosition.next};
  border: none;
`

export const ShowButton = styled.button`
  border: none;
  background: none;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ArrowIcon = styled.div`
  height: 15px;
  width: 8px;
  background: url(${Icons}) no-repeat center center;
  background-position: ${(props) =>
    props.show
      ? config[props.skin].iconsPosition.arrowLeft
      : config[props.skin].iconsPosition.arrowRight};
`

export const PlayerBottom = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerBottomLeft = styled.div`
  padding: 0 15px;

  flex: 1;
  display: flex;
  align-items: center;
`

export const Progress = styled.div.attrs({
  className: 'music-player__progress',
})`
  position: relative;
  flex: 1;
  height: 6px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const PregressTrack = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress * 2}%`,
  },
}))`
  position: absolute;
  height: 100%;
  max-width: 100%;
  background: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const PregressBar = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  position: relative;
  height: 100%;
  background: ${(props) => config[props.skin].volumeBackground};
  border: 1px solid ${(props) => config[props.skin].volumeBorder};
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &::after {
    content: '';
    position: absolute;
    top: -7px;
    right: -3px;
    display: inline-block;
    width: 15px;
    height: 17px;
    background: url(${Icons}) no-repeat;
    background-position: ${(props) => config[props.skin].iconsPosition.drag};
  }
`

export const Volume = styled.div.attrs({
  className: 'music-player__volume',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  margin-left: 15px;

  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(${Icons}) no-repeat;
    background-position: ${(props) => config[props.skin].iconsPosition.volume};
  }

  @media (max-width: 616px) {
    display: none;
  }
`

export const VolumeWrraper = styled.div`
  position: relative;
  width: 120px;
  height: 4px;
  background: #fff;
  border: 1px solid #d1d1d1;
`

export const VolumeRange = styled.input.attrs({
  type: 'range',
})`
  position: absolute;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  background: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`

export const VolumeSlider = styled.div.attrs((props) => ({
  style: {
    width: `calc(${props.volume}% - 2px)`,
  },
}))`
  ${({ skin }) => css`
    position: relative;
    height: 100%;
    background: ${config[skin].volumeBackground};
    border: 1px solid ${config[skin].volumeBorder};
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    box-sizing: initial;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: block;
      width: 15px;
      height: 17px;
      background: url(${Icons}) no-repeat;
      background-position: ${config[skin].iconsPosition.drag};
      pointer-events: none;
    }
  `}
`

export const EqButton = styled.button.attrs({
  className: 'music-player__equalizer',
})`
  ${({ paused, skin }) => css`
    border: none;
    width: 50px;
    height: 50px;
    background: url(${paused ? config[skin].eqOff : config[skin].eq}) no-repeat
      center center;
  `}
`
