import styled, { keyframes, css } from 'styled-components'

import bg from './assets/bg.png'
import iconsSkin1 from './assets/skin1_sprites.png'
import iconsSkin2 from './assets/skin2_sprites.png'
import iconsSkin3 from './assets/skin3_sprites.png'
import iconVolume from './assets/icon_volume.png'
import controlBg from './assets/control_bg.png'
import volumeBg from './assets/volume_bg.png'
import eqSkin1 from './assets/skin1_eq.gif'
import eqOffSkin1 from './assets/skin1_eq_off.gif'
import eqSkin2 from './assets/skin2_eq.gif'
import eqOffSkin2 from './assets/skin2_eq_off.gif'
import eqSkin3 from './assets/skin3_eq.gif'
import eqOffSkin3 from './assets/skin3_eq_off.gif'

const barAnimate = keyframes`
  0% {
    left: 40px;
  }
  25% {
    left: 70px;
  }
  30% {
    left: 60px;
  }
  70% {
    left: 100px;
  }
  100% {
    left: 40px;
  }
`

const config = {
  skin1: {
    icons: iconsSkin1,
    eq: eqSkin1,
    eqOff: eqOffSkin1,
    color: '#fc6935',
  },
  skin2: {
    icons: iconsSkin2,
    eq: eqSkin2,
    eqOff: eqOffSkin2,
    color: '#9fa747',
  },
  skin3: {
    icons: iconsSkin3,
    eq: eqSkin3,
    eqOff: eqOffSkin3,
    color: '#4878a8',
  },
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  width: 616px;
  max-width: ${(props) => (props.show ? '100%' : 'auto')};
  height: 155px;
  background: #ececec url(${bg}) no-repeat;
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  text-align: left;
  color: ${(props) => config[props.skin].color};
  position: fixed;
  bottom: 0;
  left: ${(props) => (props.show ? '0' : '-566px')};
  transition: left 0.5s;
  z-index: 10;

  @media (max-width: 616px) {
    height: 93px;
    background: #ececec;
  }
`

export const PlayerTop = styled.div`
  position: relative;
  height: 46%;
  display: flex;
  align-items: center;

  @media (max-width: 616px) {
    display: none;
  }
`

export const RadioStations = styled.div`
  position: absolute;
  left: 126px;
  width: 293px;
  height: 41px;
  background: url(${(props) => config[props.skin].icons}) no-repeat 0 0;
`

export const Bar = styled.div`
  position: absolute;
  left: 40px;
  background: ${(props) => config[props.skin].color};
  height: 100%;
  width: 3px;

  ${(props) =>
    props.anima &&
    css`
      animation: ${barAnimate} 2s;
    `}
`

export const ButtonOnOff = styled.button`
  position: absolute;
  right: 9px;
  top: 9px;
  border: none;
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  width: 26px;
  height: 52px;
  background-position: ${(props) =>
    props.paused ? '-161px -45px' : '-125px -45px'};
`

export const PlayerMiddle = styled.div`
  height: 27%;
  display: flex;

  @media (max-width: 616px) {
    height: 55%;
    border-bottom: 1px solid #ccc;
    background: linear-gradient(#ececec, #d6d6d6);
  }
`

export const PlayerMiddleLeft = styled.div`
  flex: 1;
  padding: 0 15px;

  display: flex;
  align-items: center;
`

export const MusicInfo = styled.div`
  flex: 1;
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  font-size: 11px;
  font-size: 0.6875rem;
  text-shadow: 1px 1px 3px #fff;
  font-weight: 300;
  line-height: 1.5;
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  font-size: 11px;
  font-size: 0.6875rem;
  text-shadow: 1px 1px 3px #fff;
  font-weight: 300;
  line-height: 1.5;
`

export const Tracks = styled.p`
  font-size: 15px;
  font-size: 0.9375rem;
  color: #494947;
  padding: 0 15px;
`

export const Controls = styled.div.attrs({
  className: 'music-player__controls',
})`
  width: 89px;
  height: 35px;
  background: url(${controlBg}) no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Prev = styled.button.attrs({
  className: 'music-player__previous',
})`
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  background-position: -32px -48px;
  width: 28px;
  height: 29px;
  border: none;
`

export const Pause = styled.button.attrs({
  className: 'music-player__pause',
})`
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  background-position: -61px -78px;
  width: 28px;
  height: 29px;
  border: none;
`

export const Play = styled.button.attrs({
  className: 'music-player__play',
})`
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  background-position: -60px -48px;
  width: 28px;
  height: 29px;
  border: none;
`

export const Next = styled.button.attrs({
  className: 'music-player__next',
})`
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  background-position: -32px -48px;
  width: 28px;
  height: 29px;
  border: none;
`

export const ShowButton = styled.button`
  background: none;
  border: none;
  height: 100%;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ArrowIcon = styled.div`
  width: 19px;
  height: 21px;
  background: url(${(props) => config[props.skin].icons}) no-repeat;
  background-position: ${(props) =>
    props.show ? '-219px -47px' : '-219px -67px'};
`

export const PlayerBottom = styled.div`
  height: 27%;
  display: flex;

  @media (max-width: 616px) {
    height: 45%;
    background: linear-gradient(#d6d6d6, #ececec);
  }
`

export const PlayerBottomLeft = styled.div`
  padding: 0 15px;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Progress = styled.div.attrs({
  className: 'music-player__progress',
})`
  position: relative;
  flex: 1;
  height: 8px;
  background: rgba(150, 150, 150, 0.4);
`

export const PregressTrack = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress * 2}%`,
  },
}))`
  position: absolute;
  height: 100%;
  max-width: 100%;
  background: #444;
  box-shadow: inset 0 1px 2px #fff;
`

export const PregressBar = styled.div.attrs((props) => ({
  style: {
    width: `calc(${props.progress}% - 6px)`,
  },
}))`
  position: absolute;
  top: 33%;
  left: 3px;
  height: 4px;
  background: ${(props) => config[props.skin].color};
  box-shadow: inset 0 1px 2px #fff;

  &::after {
    content: '';
    position: absolute;
    top: -7px;
    right: -3px;
    display: inline-block;
    width: 15px;
    height: 17px;
    background: url(${(props) => config[props.skin].icons}) no-repeat;
    background-position: 0 -49px;
  }
`

export const Volume = styled.div.attrs({
  className: 'music-player__volume',
})`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;

  @media (max-width: 616px) {
    display: none;
  }
`

export const VolumeIcon = styled.div`
  height: 14px;
  width: 14px;
  background: url(${iconVolume}) no-repeat center center;
`

export const VolumeWrrapper = styled.div`
  position: relative;
  width: 180px;
  height: 10px;
  background: url(${volumeBg}) no-repeat;
`

export const VolumeSlider = styled.div.attrs((props) => ({
  style: {
    width: `calc(${props.volume}% - 6px)`,
  },
}))`
  position: absolute;
  top: 30%;
  left: 3px;
  height: 4px;
  background-color: ${(props) => config[props.skin].color};
  box-shadow: inset 0 1px 2px #fff;
`

export const VolumeRange = styled.input.attrs({
  type: 'range',
})`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`

export const EqButton = styled.button.attrs({
  className: 'music-player__equalizer',
})`
  ${({ paused, skin }) => css`
    border: none;
    background: url(${paused ? config[skin].eqOff : config[skin].eq}) no-repeat
      center center;
    height: 100%;
    width: 50px;
  `}
`
