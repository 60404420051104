import { takeLatest, call, put, all, select } from 'redux-saga/effects'

import api from '../../../services/api'
import isDomain from '../../../utils/isDomain'
import htmlParser from '../../../utils/htmlParser'

import { menuSuccessFetchData, menuSetHome, menuSetMainList } from './actions'

export function* fetchData({ payload }) {
  try {
    const { login } = yield select((state) => state.user.profile)
    const response = yield call(api.get, `api/v1/menus/${payload.coupleId}`)

    let home = null

    let mainList = null

    const data = htmlParser(
      response.data.data.menus.map((menu) => ({
        ...menu,
        pages: menu.pages.map((page) => {
          const pageData = {
            ...page,
            path: isDomain()
              ? `/${page.slug || `pages/${page.external_id}`}`
              : `/${login}/${page.slug || `pages/${page.external_id}`}`,
          }

          if (pageData.home) {
            home = pageData
          }

          if (pageData.store) {
            mainList = pageData
          }

          return pageData
        }),
      }))
    )
    yield put(menuSetHome(home))
    yield put(menuSetMainList(mainList))
    yield put(menuSuccessFetchData(data))
  } catch (err) {
    console.error('Falha na autenticação, verifique seus dados!')
  }
}

export default all([takeLatest('@menu/GET_DATA_REQUEST', fetchData)])
