export default {
  avatar: 'CQpVd9mBJPpgRTSu.jpeg',
  couple_id: 484359,
  couple_name: 'Ana e Dudu',
  description_share:
    'Aqui vamos contar a vocês, queridos amigos e familiares, os momentos mais marcantes da nossa história de amor.',
  login: 'prielu',
  bride_name: 'Ana Duart',
  groom_name: 'Dudu Duart',
  exibition_configs: {
    app: true,
    countdown: true,
    flag: false,
    social_icons: true,
    visitors: true,
    whatsapp: true,
  },
  visitors: 1337,
}
