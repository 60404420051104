import React from 'react';

const Icon = ({ color }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2 3L6.636 3.60429L8.468 5.57143H2V6.42857H8.468L6.632 8.39571L7.2 9L10 6L7.2 3Z"
      fill={color || '#666'}
    />
  </svg>
);

export default Icon;
