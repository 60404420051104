import React from 'react';
import PropTypes from 'prop-types';

import Photo from '../../atoms/Photo';

import * as S from './styles';

export default function CardDialog({ image, description, onClose, show }) {

  function handleClickOff({ target, currentTarget }) {
    if (target === currentTarget) {
      onClose();
    }
  }

  return show ? (
    <S.Modal onClick={handleClickOff}>
      <S.Container>
        <Photo url={image} proportion="1:1" />
        <S.Footer>
          <S.CardTitle>Modelo {description}</S.CardTitle>
          <S.ButtonClose onClick={onClose}>Fechar</S.ButtonClose>
        </S.Footer>
      </S.Container>
    </S.Modal>
  ) : null;
}

CardDialog.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
