import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import Brand from '../../atoms/Brand'
import Social from '../../atoms/Social'
import * as S from './styles'

const Footer = () => {
  const { profile } = useSelector((state) => state.user)
  const theme = useTheme()

  return (
    <S.Footer id="footer">
      <S.Container>
        <S.SocialList isShowing={!!profile.exibition_configs.social_icons}>
          {profile.exibition_configs.social_icons && (
            <>
              <S.SocialItem>
                <Social name="facebook" />
              </S.SocialItem>

              <S.SocialItem>
                <Social name="twitter" />
              </S.SocialItem>

              <S.SocialItem>
                <Social name="pinterest" />
              </S.SocialItem>
            </>
          )}
        </S.SocialList>

        {profile.exibition_configs.visitors && (
          <S.Text>
            <S.VisitorsAmount>{profile.visitors}</S.VisitorsAmount> visitantes
            já conferiram nosso{' '}
            <a
              href="https://www.icasei.com.br/site-de-casamento"
              rel="nofollow noreferrer"
              target="_blank"
            >
              site de casamento
            </a>
            .
          </S.Text>
        )}
        <Brand
          planId={theme.configs.isTailorMade ? 5 : profile.plan_id}
          isPlanApple={profile.apple_plan}
        />
      </S.Container>
    </S.Footer>
  )
}

export default Footer
