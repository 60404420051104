import styled, { keyframes, css } from 'styled-components'
import { TitleXS, ContentMobile, Warning } from '../../_settings/typography'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const Modal = styled.section.attrs({
  className: 'cancel-dialog__background',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 500;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ContainerModifiers = {
  backgroundColor: (theme) => css`
    background-color: ${theme.colors[
      theme.components.cardDialogCancel.background
    ]};
  `,
}

export const Container = styled.div.attrs({
  className: 'cancel-dialog',
})`
  ${({ theme }) => css`
    background-color: white;
    width: 920px;
    max-width: 100%;
    animation: 0.5s ${fadeIn};
    display: flex;

    ${theme.components.cardDialogCancel.background &&
    ContainerModifiers.backgroundColor(theme)};

    @media (max-width: 768px) {
      width: 290px;
    }
  `}
`

export const Info = styled.div.attrs({
  className: 'cancel-dialog__wrapper',
})`
  width: 50%;
  padding: 24px 75px 24px 48px;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 19px;
  }
`

const contentFontFamily = (theme) =>
  theme.fonts[theme.components.dialog.contentFontFamily].family

const contentColor = (theme) =>
  theme.colors[
    theme.components.dialog.cancelContentColor
      ? theme.components.dialog.cancelContentColor
      : theme.components.dialog.contentColor
  ]

export const Title = styled.h1.attrs({
  className: 'cancel-dialog__title',
})`
  ${({ theme }) => css`
    ${TitleXS}
    color: ${contentColor(theme)};
    font-family: ${contentFontFamily(theme)};
    text-align: left;
    margin-bottom: 24px;
  `}
`

const strongFontFamily = (theme) =>
  theme.fonts[theme.components.cardDialogCancel.strong.fontFamily].family

const strongFontWeight = (theme) =>
  theme.components.cardDialogCancel.strong.fontWeight

export const Description = styled.p`
  ${({ theme }) => css`
    margin-bottom: 24px;
    ${ContentMobile}
    text-align: left;
    color: ${contentColor(theme)};
    font-family: ${contentFontFamily(theme)};

    strong {
      font-family: ${strongFontFamily(theme)};
      font-weight: ${strongFontWeight(theme)};
    }

    @media (max-width: 768px) {
      ${Warning}
    }
  `}
`

export const ButtonExit = styled.button.attrs({
  className: 'cancel-dialog__cancel-button',
})`
  ${({ theme }) => css`
    ${ContentMobile}
    color: ${contentColor(theme)};
    font-family: ${contentFontFamily(theme)};
    margin-top: 24px;
    border: none;
    background: none;
  `}
`

export const ImageWrapper = styled.div.attrs({
  className: 'cancel-dialog__image',
})`
  width: 50%;
  padding: 24px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`
