export default function getUrlParameterByName(name, urlStr) {
  const url = urlStr || window.location.href
  const searchName = name.replace(/[[\]]/g, '\\$&')

  const regex = new RegExp(`[?&]${searchName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
