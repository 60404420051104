import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, useLocation, useHistory } from 'react-router-dom'

import { pageview } from '../lib/gtm'
import isDomain from '../utils/isDomain'
import Route from './Route'

import SignIn from '../pages/SignIn'
import Main from '../pages/Main'
import Cart from '../pages/Cart'
import Intro from '../pages/Intro'
import SpecialStore from '../pages/SpecialStore'
import MainStore from '../pages/MainStore'
import MainRsvp from '../pages/MainRsvp'
import IframeFacebookAuth from '../pages/IframeFacebookAuth'

export default function Routes() {
  const history = useHistory()
  const { pathname } = useLocation()
  const {
    profile: { private: shouldBePrivate },
  } = useSelector((state) => state.user)
  const storeConfig = useSelector((state) => state.cart.config)

  const generatePath = (path) => {
    return isDomain() ? path : `/:login/${path.replace(/^\//, '')}`
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname])

  useEffect(() => {
    const unlisten = history.listen(() => pageview(window.location.pathname))
    return unlisten
  }, [history])

  return (
    <Switch>
      <Route
        path={generatePath('/:lang?/protected')}
        exact
        component={SignIn}
        isSignPage
      />
      <Route
        path={generatePath('/:lang?/rsvp')}
        component={MainRsvp}
        isPrivate={shouldBePrivate}
        exact
      />
      <Route
        path={generatePath('/:lang?/pages/show/:pageId')}
        component={Main}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/pages/:pageId')}
        component={Main}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/places/:placeId')}
        component={Main}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/cart/:cartStep?')}
        component={Cart}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/store/:resourceId/:id/:page?')}
        component={storeConfig?.especialPage ? SpecialStore : MainStore}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/store')}
        component={storeConfig?.especialPage ? SpecialStore : MainStore}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/facebooklogin')}
        component={IframeFacebookAuth}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?/:slug')}
        component={Main}
        isPrivate={shouldBePrivate}
      />
      <Route
        path={generatePath('/:lang?')}
        component={Intro}
        isPrivate={shouldBePrivate}
      />
    </Switch>
  )
}
