import React, { useState, useCallback } from 'react';

import Subtitle from '../../../../atoms/Subtitle';
import Spacer from '../../../../atoms/Spacer';

import { ReactComponent as IconContentCopy } from '../../../../../assets/img/icon-content-copy.svg';

import * as S from '../styles';

const Pix = ({ name, email, pixCode, pixImage }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyContent = useCallback(
    (event) => {
      event.preventDefault();
      navigator.clipboard.writeText(pixCode);

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 5000);
    },
    [pixCode]
  );

  return (
    <>
      <Subtitle>Agora falta pouco!</Subtitle>
      <S.Content>
        {name}, um email de confirmação do pedido foi enviado para{' '}
        <strong>{email}</strong>
      </S.Content>
      <Spacer size="small" />
      <S.Card>
        <S.CardIcon>
          <S.PixIcon />
        </S.CardIcon>
        <S.CardContentPix>
          <p>
            Você escolheu pagar com PIX. Agora, basta escanear o QR Code ou
            copiar o código fornecido abaixo, para prosseguir com o pagamento na
            instituição financeira em que o serviço está cadastrado.
          </p>
        </S.CardContentPix>
      </S.Card>
      <S.PixCard>
        <S.PixCardCodeWrapper>
          <img src={pixImage} alt="QR Code com o codigo pix." />
          <p>QR Code-Pix</p>
        </S.PixCardCodeWrapper>
        <S.PixCardInfoWrapper>
          <S.CardInfoInfoColumn>
            <div>
              <S.CardInfoIconWrapper>
                <S.IconClock />
              </S.CardInfoIconWrapper>
              <div>
                <p>Prazo de expiração</p>
                <span>Seu código PIX tem validade de 48 horas.</span>
              </div>
            </div>
            <div>
              <S.CardInfoIconWrapper>
                <S.IconPaper />
              </S.CardInfoIconWrapper>
              <div>
                <p>Confirmação de pagamento</p>
                <span>Ao confirmar seu pagamento, enviaremos um e-mail.</span>
              </div>
            </div>
          </S.CardInfoInfoColumn>
          <S.Divider />
          <S.CardInfoPixCodeWrapper>
            <div>
              <p>Copiar Código PIX:</p>
              <span>{pixCode}</span>
            </div>
            <button type="button" onClick={handleCopyContent}>
              <IconContentCopy />
              <S.Tooltip copied={!!copied}>
                <p>Copiado!</p>
              </S.Tooltip>
            </button>
          </S.CardInfoPixCodeWrapper>
        </S.PixCardInfoWrapper>
      </S.PixCard>
    </>
  );
};

export default Pix;
