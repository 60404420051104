export default function formatTime(seconds) {
  let minutes = Math.floor(seconds / 60)
  let secs = Math.floor(seconds % 60)

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  if (secs < 10) {
    secs = `0${secs}`
  }

  return `${minutes}:${secs}`
}
