import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import * as S from './styles';

import { ReactComponent as Lock } from '../../../assets/img/lock.svg';

const Recaptcha = forwardRef((props, ref) => {
  const reCaptchaRef = useRef();
  const { setRecaptchaV3, setRecaptchaV2, recaptchaVersion } = props;

  const executeRecaptchaV3 = () => {
    const { grecaptcha } = window;

    if (grecaptcha) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute('6LejqscUAAAAAFXNlvS6Drzly056oxGdF6O2oNfW', {
            action: 'homepage',
          })
          .then((token) => {
            setRecaptchaV3(token);
          });
      });
    } else {
      setTimeout(() => {
        createRecaptchaV3();
      }, 1000);
    }
  };

  const createRecaptchaV3 = () => {
    if (
      !document.querySelector(
        'script[src="https://www.google.com/recaptcha/api.js?render=6LejqscUAAAAAFXNlvS6Drzly056oxGdF6O2oNfW"]'
      )
    ) {
      const el = document.createElement('script');
      el.src =
        'https://www.google.com/recaptcha/api.js?render=6LejqscUAAAAAFXNlvS6Drzly056oxGdF6O2oNfW';
      document.head.insertAdjacentElement('afterbegin', el);
      el.async = true;
      el.defer = true;

      el.onload = () => {
        executeRecaptchaV3();
      };
    } else {
      executeRecaptchaV3();
    }
  };

  const resetRecaptchaV3 = () => {
    const { grecaptcha } = window;

    if (grecaptcha) {
      if (typeof grecaptcha.reset === 'function') {
        grecaptcha.reset('6LejqscUAAAAAFXNlvS6Drzly056oxGdF6O2oNfW');
        executeRecaptchaV3();
      }
    }
  };

  const resetRecaptchaV2 = () => {
    setRecaptchaV2('');
    reCaptchaRef.current.reset();
  };

  useImperativeHandle(ref, () => ({ resetRecaptchaV3, resetRecaptchaV2 }));

  useEffect(() => {
    createRecaptchaV3();
  }, []);

  return (
    <>
      {recaptchaVersion === 'v3' ? (
        <S.Wrapper>
          <S.Badge>
            <S.Icon>
              <Lock />
            </S.Icon>
            <S.Text>
              Este site é protegido por reCaptcha e Google.{' '}
              <a href="https://policies.google.com/privacy">
                Políticas de privacidade
              </a>{' '}
              e{' '}
              <a href="https://policies.google.com/terms">termos de serviço</a>
            </S.Text>
          </S.Badge>
        </S.Wrapper>
      ) : (
        <S.Wrapper>
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey="6LekR8cUAAAAAG6yTM71N5J3jeRMhEecvwsx9sOr"
            onChange={(value) => setRecaptchaV2(value)}
            onExpired={() => setRecaptchaV2('')}
            onErrored={() => setRecaptchaV2('')}
            size="normal"
          />
        </S.Wrapper>
      )}
    </>
  );
});

Recaptcha.defaultProps = {
  recaptchaVersion: 'v3',
  setRecaptchaV2: () => {},
};

Recaptcha.propTypes = {
  recaptchaVersion: PropTypes.string,
  setRecaptchaV3: PropTypes.func.isRequired,
  setRecaptchaV2: PropTypes.func,
};

export default Recaptcha;
