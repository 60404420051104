import styled, { css } from 'styled-components'

const wrapperModifiers = {
  additionalMarginTop: () => css`
    margin-top: 100px;
    @media (max-width: 768px) {
      margin-top: 64px;
    }
  `,
}

export const Wrapper = styled.main`
  ${({ additionalMarginTop }) => css`
    ${additionalMarginTop && wrapperModifiers.additionalMarginTop()}
  `}
`

export default Wrapper
