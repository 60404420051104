import React from 'react';

import * as S from './styles';

const Radio = ({
  disabled,
  label,
  register,
  required,
  id,
  name,
  value,
  checked,
  dataTestId,
  onChange = () => {},
  onBlur = () => {},
}) => {
  const defaultParams = {
    onChange,
    onBlur,
  };

  return (
    <S.Wrapper>
      <S.Radio
        disabled={disabled}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        data-testid={dataTestId}
        {...(!register && { ...defaultParams })}
        {...(register &&
          register(label, {
            required,
            onChange: (e) => onChange(e),
            onBlur: (e) => onBlur(e),
          }))}
      />
      <S.RadioIcon />
    </S.Wrapper>
  );
};

export default Radio;
