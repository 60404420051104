import React, { useRef, useState, useEffect, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import formatTime from '../../../utils/formatTime'
import Dialog from '../../organisms/Dialog'
import skins from './skins'
import api from '../../../services/api'

export default function Player({ data }) {
  const audioRef = useRef(null)
  const { externalId } = useSelector((state) => state.page)
  const { couple_id } = useSelector((state) => state.user.profile)
  const [playlist, setPlaylist] = useState([])
  const [audioState, setAudioState] = useState('stopped')
  const [selectedTrackIndex, setSelectedTrackIndex] = useState(-1)
  const [volume, setVolume] = useState(100)
  const [muted, setMuted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const timeFormatted = useMemo(
    () =>
      loading
        ? '00:00 / 00:00'
        : `${formatTime(currentTime)} / ${formatTime(duration)}`,
    [loading, currentTime, duration]
  )
  const [player, setPlayer] = useState('')
  const [skin, setSkin] = useState('')
  const PlayerSelected = useMemo(() => skins[player], [player])

  const shouldShowDialog = () =>
    data.config.ask ||
    window.device.safari() ||
    window.device.android() ||
    window.device.ios()

  useEffect(() => {
    const { config, playlist: playlistData } = data
    const [playerName, playerSkin] = config.player.split(' ')

    setPlayer(playerName)
    setSkin(playerSkin)
    setShowDialog(shouldShowDialog())
    setVolume(config.volume)
    setPlaylist(playlistData)
    setSelectedTrackIndex(0)
  }, [])

  useEffect(() => {
    if (playlist.length <= 1 && playlist.length !== 0) {
      audioRef.current.loop = true
    }

    const featuredMusicIndex = playlist.findIndex((music) => {
      return music && music.pages?.filter((p) => +p === externalId)[0]
    })

    if (featuredMusicIndex > -1) {
      setSelectedTrackIndex(featuredMusicIndex)
    }
  }, [externalId, playlist])

  useEffect(() => {
    if (selectedTrackIndex > -1) {
      audioRef.current.src = playlist[selectedTrackIndex].url

      if (audioState === 'playing') {
        audioRef.current.play()
      }
    }
  }, [selectedTrackIndex, playlist])

  useEffect(() => {
    audioRef.current.volume = volume / 100
  }, [volume])

  useEffect(() => {
    audioRef.current.muted = muted

    if (!muted && audioRef.current.volume === 0) {
      setVolume(data.config.volume)
    }
  }, [muted, data.config.volume])

  function handlePlay() {
    setAudioState('playing')
    audioRef.current.play()
  }

  function handlePause() {
    setAudioState('paused')
    audioRef.current.pause()
  }

  function handleChangeVolume(event) {
    const newVolume = Number(event.target.value)
    setVolume(newVolume)

    if (newVolume === 0) {
      setMuted(true)
    } else {
      setMuted(false)
    }
  }

  function handleToggleMuted() {
    setMuted(!muted)
  }

  function handleTimeupdate() {
    const { current } = audioRef
    const percentage = (current.currentTime / current.duration) * 100

    setProgress(percentage)
    setCurrentTime(current.currentTime)
  }

  function handleChangeProgress(event) {
    audioRef.current.currentTime = Number(event.target.value)
    handleTimeupdate()
  }

  function handleNextTrack() {
    if (playlist.length < 2) {
      audioRef.current.currentTime = 0
      return
    }

    const nextTrackIndex = selectedTrackIndex + 1

    if (nextTrackIndex === playlist.length) {
      setSelectedTrackIndex(0)
    } else {
      setSelectedTrackIndex(nextTrackIndex)
    }
  }

  function handlePrevTrack() {
    if (audioRef.current.currentTime > 10 || playlist.length < 2) {
      audioRef.current.currentTime = 0
      return
    }

    const prevTrackIndex = selectedTrackIndex - 1
    if (prevTrackIndex < 0) {
      setSelectedTrackIndex(playlist.length - 1)
    } else {
      setSelectedTrackIndex(prevTrackIndex)
    }
  }

  function handleEndedTrack() {
    handleNextTrack()
    setAudioState('playing')
  }

  function handleLoadedData() {
    setLoading(false)
    setDuration(audioRef.current?.duration || 0)
  }

  function handleLoadStart() {
    setLoading(true)
  }

  useEffect(() => {
    if ('mediaSession' in navigator && playlist.length) {
      const currentTrack = playlist[selectedTrackIndex]

      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: currentTrack.title,
        artist: currentTrack.artist,
      })

      navigator.mediaSession.setActionHandler('previoustrack', handlePrevTrack)
      navigator.mediaSession.setActionHandler('nexttrack', handleNextTrack)
    }
  }, [selectedTrackIndex, playlist])

  useEffect(() => {
    if (timeFormatted.startsWith('00:03')) {
      const musicId = playlist[selectedTrackIndex].id
      api.put(`api/v1/music/${musicId}/played?couple_id=${couple_id}`)
    }
  }, [timeFormatted, playlist, selectedTrackIndex, couple_id])

  return (
    <Fragment>
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeupdate}
        onEnded={handleEndedTrack}
        onLoadedData={handleLoadedData}
        onLoadStart={handleLoadStart}
        onPlay={() => setAudioState('playing')}
        onPause={() => setAudioState('paused')}
      />
      {!!showDialog && (
        <Dialog
          content="Esse site possui uma playlist musical. Deseja reproduzir enquanto navega?"
          bottomAction={{
            action: () => {},
            label: 'Não',
          }}
          topAction={{
            action: handlePlay,
            label: 'Sim',
          }}
        />
      )}
      {playlist.length && selectedTrackIndex > -1 && (
        <PlayerSelected
          skin={skin}
          audioState={audioState}
          volume={volume}
          muted={muted}
          progress={progress}
          duration={duration}
          currentTime={currentTime}
          timeFormatted={timeFormatted}
          title={playlist[selectedTrackIndex].title}
          artist={playlist[selectedTrackIndex].artist}
          musicTotal={playlist.length}
          musicSelected={selectedTrackIndex + 1}
          loading={loading}
          onPlay={handlePlay}
          onPause={handlePause}
          onNext={handleNextTrack}
          onPrev={handlePrevTrack}
          onChangeVolume={handleChangeVolume}
          onToggleMuted={handleToggleMuted}
          onChangeProgress={handleChangeProgress}
        />
      )}
    </Fragment>
  )
}

Player.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      volume: PropTypes.number,
      ask: PropTypes.number,
      random: PropTypes.bool,
      player: PropTypes.string,
    }),
    playlist: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        artist: PropTypes.string,
        album: PropTypes.string,
        title: PropTypes.string,
        page: PropTypes.number,
      })
    ),
  }).isRequired,
}
