import styled, { css } from 'styled-components'

import { Content, ContentMobile, Warning } from '../../../_settings/typography'

export const Wrapper = styled.section.attrs({
  className: 'resume',
})`
  background-color: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};
  padding: 0 15px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const Container = styled.div`
  max-width: 921px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Header = styled.div.attrs({
  className: 'resume__header',
})`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 40px 0 21px 0;
    border-bottom: 1px solid
      ${theme.components.cart?.resumeDividerBorderColor
        ? theme.colors[theme.components.cart.resumeDividerBorderColor]
        : `rgba(96, 95, 99, 0.1)`};
    margin-bottom: 24px;

    > p {
      ${Content}
      color: ${theme.colors[theme.components.cart.resumeTextColor]};
      font-weight: bold;

      @media (max-width: 768px) {
        font-weight: bold;
        color: ${theme.colors[theme.components.cart.resumeTextColor]};
      }
    }
  `}
`

export const Row = styled.div.attrs({
  className: 'resume__item',
})`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 26px;
    margin-bottom: 24px;
    border-bottom: 1px solid
      ${theme.components.cart?.resumeDividerBorderColor
        ? theme.colors[theme.components.cart.resumeDividerBorderColor]
        : `rgba(96, 95, 99, 0.1)`};

    > p {
      ${ContentMobile}
      color: ${theme.colors[theme.components.cart.resumeTextColor]};
    }
  `}
`

export const ButtonRemoveCard = styled.button`
  background: none;
  border: none;
  ${Warning}
  margin-left: 8px;

  color: ${({ theme }) => theme.colors[theme.components.cart.removeTextColor]};
`

export const Footer = styled.div.attrs({
  className: 'resume__footer',
})`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 16px;

  p {
    ${Content}
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
    font-weight: bold;

    @media (max-width: 768px) {
      ${ContentMobile}
      color: ${({ theme }) =>
        theme.colors[theme.components.cart.resumeTextColor]};
      font-weight: bold;
    }
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p:not(:first-child) {
      font-weight: normal;
    }
  }
`
