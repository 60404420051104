import ReactHtmlParser from 'react-html-parser'

const deepParser = (value, callback) => {
  if (typeof value === 'object' && value !== null) {
    return Array.isArray(value)
      ? value.map((arrayValue) => deepParser(arrayValue, callback))
      : Object.keys(value).reduce((obj, key) => {
          obj[key] = deepParser(value[key], callback)
          return obj
        }, {})
  }
  return callback(value)
}

const htmlParser = (html) => {
  return deepParser(html, (value) =>
    typeof value === 'string' && /<[\s\S]+?\/?>/.test(value)
      ? ReactHtmlParser(value)
      : value
  )
}

export default htmlParser
