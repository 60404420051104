import React, { useMemo, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

import * as gtm from '../../../../lib/gtm'
import Subtitle from '../../../atoms/Subtitle'
import Spacer from '../../../atoms/Spacer'
import Title from '../../../atoms/Title'
import DecorativeDivider from '../../../atoms/DecorativeDivider'; // eslint-disable-line
import Resume from './Resume'

import Pix from './layouts/Pix'
import BankSlip from './layouts/BankSlip'
import CreditCard from './layouts/CreditCard'

import * as S from './styles'

const Confirmation = ({
  paymentMethod,
  paymentId,
  bankSlipLink,
  cardBrand,
  date,
  paymentInfo,
  products,
  card,
  ...rest
}) => {
  const name = useSelector((state) => state.cart.infos.name.split(' ')[0])
  const { email } = useSelector((state) => state.cart.infos)
  const { profile } = useSelector((state) => state.user)
  const { language } = useSelector((state) => state.language)

  const redirectToBoleto = useCallback(() => {
    window.open(bankSlipLink, '_blank')
  }, [bankSlipLink])

  const returnPurchaseDateAndCard = useMemo(() => {
    if (language.htmlTag === 'en') {
      return (
        <>
          Purchase made in <strong>{moment(date).format('MM/DD/YYYY')}</strong>{' '}
          at {moment(date).format('hh A')} via{' '}
          {paymentMethod === 'bank-slip'
            ? 'Boleto Bancário'
            : `${
                cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1)
              } Cartão`}
        </>
      )
    }

    const paymentType = {
      'bank-slip': 'Boleto Bancário',
      pix: 'Pix',
      'credit-card': `Cartão ${
        cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1)
      }`,
    }

    return (
      <>
        Compra realizada em <strong>{moment(date).format('DD/MM/YYYY')}</strong>{' '}
        às {moment(date).format('HH:mm:ss')} via {paymentType[paymentMethod]}
      </>
    )
  }, [language, cardBrand, date, paymentMethod])

  useEffect(() => {
    const items = products.map((product, index) => ({
      index,
      item_id: String(product.id),
      item_name: product.name,
      currency: 'BRL',
      item_category: product.category.name,
      item_category2: 'Site do casal',
      item_list_id: product.item_list_id,
      item_list_name: product.item_list_name,
      price: product.price,
      quantity: product.quantity,
    }))

    if (card) {
      items.push({
        index: items.length,
        item_id: String(card.id),
        item_name: 'Cartão iCasei',
        currency: 'BRL',
        item_category: 'Cartão impresso',
        item_category2: 'Site do casal',
        price: card.price,
        item_variant: card.description,
      })
    }

    gtm.purchase({
      value: products.reduce(
        (partialTotal, product) =>
          partialTotal + product.price * product.quantity,
        card?.price || 0
      ),
      payment_type: paymentMethod,
      transaction_id: paymentId,
      items,
    })
  }, [])

  return (
    <S.Wrapper>
      <Spacer size="medium" />
      {paymentMethod === 'pix' && (
        <Pix
          name={name}
          email={email}
          pixCode={rest.qrcode_text}
          pixImage={rest.qrcode_base64}
        />
      )}
      {paymentMethod === 'bank-slip' && (
        <BankSlip
          name={name}
          email={email}
          paymentId={paymentId}
          redirectToBoleto={redirectToBoleto}
        />
      )}
      {paymentMethod === 'credit-card' && (
        <CreditCard name={name} email={email} paymentId={paymentId} />
      )}
      <Spacer size="small" />
      <Title className="notranslate">{`${profile.bride_name} & ${profile.groom_name}`}</Title>
      <DecorativeDivider />
      <Subtitle>Abaixo um resumo da compra:</Subtitle>
      <Spacer size="small" />
      <S.ResumeInfo>
        <Resume paymentInfo={paymentInfo} />
        <S.ConfirmationInfo>
          <p>{returnPurchaseDateAndCard}</p>
        </S.ConfirmationInfo>
      </S.ResumeInfo>
    </S.Wrapper>
  )
}

Confirmation.defaultProps = {
  bankSlipLink: '',
  cardBrand: '',
}

Confirmation.propTypes = {
  paymentMethod: propTypes.string.isRequired,
  bankSlipLink: propTypes.string,
  date: propTypes.string.isRequired,
  cardBrand: propTypes.string,
  paymentId: propTypes.oneOfType([propTypes.string, propTypes.number])
    .isRequired,
  paymentInfo: propTypes.shape({
    installments: propTypes.number,
    total: propTypes.number.isRequired,
    products: propTypes.array.isRequired, // eslint-disable-line
  }).isRequired,
}

export default Confirmation
