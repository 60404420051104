import styled, { css } from 'styled-components'
import PhoneInput from 'react-phone-number-input/input'

import { Warning, ContentMobile } from '../../_settings/typography'
import addPixelUnitToNumber from '../../../utils/addPixelUnitToNumber'

export const Container = styled.div`
  margin-bottom: 16px;
`

const wrapperModifiers = {
  focus: (theme) => css`
    border-color: ${theme.colors[theme.components.textField.focusBorderColor]};
  `,
  rounded: (theme) => css`
    border-radius: ${theme.components.textField.borderRadius}px;
  `,
  invalid: () => css`
    border-color: var(--alert);
  `,
}

export const Wrapper = styled.div`
  ${({ theme, inputIsFocused, invalid }) => css`
    display: flex;
    position: relative;
    height: 56px;
    border: 1px solid var(--formBorder);
    color: ${theme.colors[theme.components.textField.contentColor]};
    ${inputIsFocused && wrapperModifiers.focus(theme)}
    ${theme.components.textField.rounded && wrapperModifiers.rounded(theme)}
    ${invalid && wrapperModifiers.invalid()}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 10000s;
      -webkit-text-fill-color: var(--content) !important;
    }
  `}
`

export const Select = styled.select`
  width: 100%;
  height: 100%;
  appearance: none;
  color: transparent;
  border: transparent;
  background: transparent;
  outline: none;

  option {
    color: var(--content);
    background: var(--body);
  }

  &:focus-visible {
    background: rgba(0, 0, 0, 0.03);
  }
`

export const InputWrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`

const labelModifiers = {
  textTransform: (theme) => css`
    text-transform: ${theme.components.textField.label.textTransform};
  `,
  letterSpacing: (theme) => css`
    letter-spacing: ${theme.components.textField.label.letterSpacing};
  `,
  focusedOrFilled: (theme) => css`
    top: 6px;
    transform: translateY(0);
    color: ${theme.components.textField.label.selected.color
      ? theme.colors[theme.components.textField.label.selected.color]
      : theme.colors.main};
    font-family: ${theme.components.textField.label.selected.fontFamily
      ? theme.fonts[theme.components.textField.label.selected.fontFamily].family
      : ''};
    font-size: ${!!theme.components.textField.label.selected.fontSize &&
    addPixelUnitToNumber(theme.components.textField.label.selected.fontSize)};
    text-transform: ${!!theme.components.textField.label.selected
      .textTransform &&
    theme.components.textField.label.selected.textTransform};
  `,
}

export const Label = styled.label`
  ${({ theme }) => css`
    ${ContentMobile}
    position: absolute;
    top: 28px;
    left: 0;
    transform: translateY(-50%);
    transition: all 100ms ease-in-out;

    ${!!theme.components.textField.label.textTransform &&
    labelModifiers.textTransform(theme)};

    ${!!theme.components.textField.label.letterSpacing &&
    labelModifiers.letterSpacing(theme)};
  `}
`

export const Input = styled(PhoneInput)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 22px 0 0 0;
    font-size: 12px;
    line-height: 24px;
    background: transparent;
    color: currentColor;

    &:valid ~ label,
    &:focus ~ label {
      ${labelModifiers.focusedOrFilled(theme)}
    }
  `}
`

const errorTextModifiers = {
  customColor: (theme) => css`
    color: ${theme.colors[theme.components.textField.helperMessageColor]};
  `,
}

export const ErrorText = styled.p`
  ${({ theme }) => css`
    ${Warning}
    margin-top: 8px;
    color: var(--alert);

    ${!!theme.components.textField.helperMessageColor &&
    errorTextModifiers.customColor(theme)}
  `}
`
