export default {
  menu: [
    {
      id: 454,
      couple_id: 484359,
      order: 1,
      title: 'Páginas',
      pages: [
        {
          id: 2124,
          external_id: 19642974,
          couple_id: 484359,
          menu_id: 454,
          title: 'Página Inicial',
          order: 6,
          active: true,
          home: true,
          custom: false,
          slug: 'home',
          store: false,
          path: '/anaelucas2020/home',
        },
        {
          id: 2125,
          external_id: 19642975,
          couple_id: 484359,
          menu_id: 454,
          title: 'Nossa história',
          order: 7,
          active: true,
          home: false,
          custom: false,
          slug: 'about',
          store: false,
          path: '/anaelucas2020/about',
        },
        {
          id: 2126,
          external_id: 19642978,
          couple_id: 484359,
          menu_id: 454,
          title: 'Confirmação de Presença',
          order: 10,
          active: true,
          home: false,
          custom: false,
          slug: 'rsvp',
          store: false,
          path: '/anaelucas2020/rsvp',
        },
        {
          id: 2127,
          external_id: 19642980,
          couple_id: 484359,
          menu_id: 454,
          title: 'Mensagens aos noivos',
          order: 12,
          active: true,
          home: false,
          custom: false,
          slug: 'messages',
          store: false,
          path: '/anaelucas2020/messages',
        },
        {
          id: 2128,
          external_id: 19642981,
          couple_id: 484359,
          menu_id: 454,
          title: 'Fornecedores',
          order: 13,
          active: true,
          home: false,
          custom: false,
          slug: 'suppliers',
          store: false,
          path: '/anaelucas2020/suppliers',
        },
        {
          id: 2129,
          external_id: 19642982,
          couple_id: 484359,
          menu_id: 454,
          title: 'Cerimônia Religiosa',
          order: 14,
          active: true,
          home: false,
          custom: false,
          slug: 'places/15',
          store: false,
          path: '/anaelucas2020/places/15',
        },
        {
          id: 2130,
          external_id: 19642983,
          couple_id: 484359,
          menu_id: 454,
          title: 'Festa',
          order: 15,
          active: true,
          home: false,
          custom: false,
          slug: 'places/16',
          store: false,
          path: '/anaelucas2020/places/16',
        },
        {
          id: 2131,
          external_id: 19642984,
          couple_id: 484359,
          menu_id: 454,
          title: 'Chá-bar',
          order: 16,
          active: true,
          home: false,
          custom: false,
          slug: 'places/17',
          store: false,
          path: '/anaelucas2020/places/17',
        },
        {
          id: 2132,
          external_id: 19642985,
          couple_id: 484359,
          menu_id: 454,
          title: 'Dicas',
          order: 17,
          active: true,
          home: false,
          custom: false,
          slug: 'places/18',
          store: false,
          path: '/anaelucas2020/places/18',
        },
        {
          id: 2134,
          external_id: 19643052,
          couple_id: 484359,
          menu_id: 454,
          title: 'Loucuras de casamento',
          order: 21,
          active: true,
          home: false,
          custom: true,
          slug: null,
          store: false,
          path: '/anaelucas2020/pages/19643052',
        },
        {
          id: 2133,
          external_id: 19642989,
          couple_id: 484359,
          menu_id: 454,
          title: 'Sobre os Padrinhos',
          order: 21,
          active: true,
          home: false,
          custom: false,
          slug: 'bestman',
          store: false,
          path: '/anaelucas2020/bestman',
        },
      ],
    },
    {
      id: 455,
      couple_id: 484359,
      order: 2,
      title: 'Presentes',
      pages: [
        {
          id: 2137,
          external_id: 19642977,
          couple_id: 484359,
          menu_id: 455,
          title: 'Lista de Casamento Virtual',
          order: 9,
          active: true,
          home: false,
          custom: false,
          slug: 'store/9/1/1',
          store: false,
          path: '/anaelucas2020/store/9/1/1',
        },
        {
          id: 2136,
          external_id: 19642976,
          couple_id: 484359,
          menu_id: 455,
          title: 'Lista em Loja',
          order: 10,
          active: true,
          home: false,
          custom: false,
          slug: 'gifts',
          store: false,
          path: '/anaelucas2020/gifts',
        },
      ],
    },
  ],
  home: {
    id: 2124,
    external_id: 19642974,
    couple_id: 484359,
    menu_id: 454,
    title: 'Página Inicial',
    order: 6,
    active: true,
    home: true,
    custom: false,
    slug: 'home',
    store: false,
    path: '/anaelucas2020/home',
  },
  loading: false,
}
