import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '../../../atoms/TextField';

import documentValidator from '../../../../utils/documentValidator';
import schema from './validations';

import * as S from './styles';

const BankSlipForm = forwardRef((props, ref) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    return { ...data };
  };

  const returnValidationToParent = () => {
    handleSubmit(onSubmit)();

    const { name, document } = getValues();

    if (!name.length && !document.length) {
      return false;
    }

    if (!documentValidator(document)) {
      return false;
    }

    return { name, document };
  };

  useImperativeHandle(ref, () => ({
    validateForm: returnValidationToParent,
  }));

  return (
    <S.Form>
      <S.Hint>
        Para concluir sua compra realize o pagamento do boleto bancário e
        aguarde a compensação. (O prazo de compensação é de 48h úteis).
      </S.Hint>
      <TextField
        required
        type="text"
        name="name"
        label="name"
        register={register}
        placeholder="Nome completo"
        invalid={!!errors?.name?.message}
        helperText={errors?.name?.message}
      />
      <TextField
        required
        type="text"
        name="document"
        label="document"
        register={register}
        invalid={!!errors?.document?.message}
        helperText={errors?.document?.message}
        placeholder="CPF / CNPJ"
        mask="documents"
      />
    </S.Form>
  );
});

export default BankSlipForm;
