import React from 'react'
import PropTypes from 'prop-types'

import Icons from './assets'
import * as S from './styles'

const Brand = ({ link, planId, isPlanApple }) => {
  const Icon = isPlanApple && planId === 2 ? Icons[1] : Icons[planId]

  return (
    <S.Wrapper
      href={link}
      target="_blank"
      rel="nofollow"
      aria-label="Site iCasei"
    >
      <Icon width="100%" height="100%" />
    </S.Wrapper>
  )
}

Brand.defaultProps = {
  link: 'https://www.icasei.com.br/site-de-casamento',
  planId: 1,
  isPlanApple: false,
}

Brand.propTypes = {
  link: PropTypes.string,
  planId: PropTypes.oneOf([1, 2, 3, 4]),
  isPlanApple: PropTypes.bool,
}

export default Brand
