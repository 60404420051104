import styled from 'styled-components'

import {
  Content as ContentTypography,
  Warning as WarningTypography,
} from '../../../_settings/typography'

export const Wrapper = styled.div.attrs({
  className: 'buyer-info',
})`
  width: 100%;
  padding: 80px 15px;
  background: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Feedback = styled.p.attrs({
  className: 'buyer-info__feedback',
})`
  ${ContentTypography};

  margin-bottom: 40px;
  color: ${(props) => (props.type === 'error' ? 'var(--error)' : '')};
`

export const Form = styled.form.attrs({
  className: 'buyer-info__form',
})`
  width: 100%;
  max-width: 350px;
`

export const Line = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Col = styled.div`
  flex: 1;
  max-width: ${(props) => (props.width ? `${props.width}px` : '')};
  margin-bottom: -16px;
  &:not(:last-child) {
    margin-right: 30px;
  }
`

export const Warning = styled.div.attrs(({ invalid }) => ({
  className: `text-input__hint${invalid && '--invalid'}`,
}))`
  ${WarningTypography};
  margin-top: 8px;
  color: ${(props) => (props.invalid ? 'var(--alert)' : '')};
`
