import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'app-banner',
})`
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(68, 68, 68, 0.8);
  padding: 16px 16px 16px 0;
  z-index: 100;
  transform: ${(props) => (props.show ? 'translateY(0)' : 'translateY(100%)')};
  transition: ${(props) =>
    props.show ? 'all 400ms ease-out 500ms' : 'all 400ms ease-in'};

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const CloseButton = styled.button`
  display: flex;
  padding: 16px;
  cursor: pointer;
  background: transparent;
`

export const Image = styled.img.attrs({
  className: 'app-banner__image',
})`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin-right: 8px;
`

export const Text = styled.p`
  flex: 1;
  text-align: left;
  padding-right: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: white;

  @media (max-width: 544px) {
    margin-right: auto;
    strong {
      display: block;
    }
  }
`

export const DownloadButton = styled.button.attrs({
  className: 'app-banner__download-button',
})`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #64b5f6;
  text-transform: uppercase;
  background: white;
  padding: 8px 16px;
  border-radius: 4px;
`
