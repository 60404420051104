import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as gtm from '../../../../lib/gtm';
import { formatPrice } from '../../../../utils/format';
import * as cartActions from '../../../../store/modules/cart/actions';

import CardDialogCancel from '../../../molecules/CardDialogCancel';

import * as S from './styles';

export default function ResumeCard() {
  const [showModal, setShowModal] = useState(false);
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const [totalFormatted, totalGiftsFormatted] = useSelector((state) => {
    const totalGifts = state.cart.products.reduce((totalSum, product) => {
      return totalSum + product.price * product.quantity;
    }, 0);

    const cardValue = state.cart.card ? state.cart.card.price : 0;

    return [
      formatPrice(totalGifts + cardValue, language.htmlTag),
      formatPrice(totalGifts, language.htmlTag),
    ];
  });

  const card = useSelector((state) =>
    state.cart.card
      ? {
          ...state.cart.card,
          valueFormatted: formatPrice(state.cart.card.price, language.htmlTag),
        }
      : null
  );

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function dispatchGtmRemoveFromCart() {
    gtm.removeFromCart({
      value: card.price,
      items: [
        {
          item_id: String(card.id),
          item_name: 'Cartão iCasei',
          currency: 'BRL',
          item_category: 'Cartão impresso',
          item_category2: 'Site do casal',
          price: card.price,
          item_variant: card.description,
        },
      ],
    });
  }

  function handleRemoveCard() {
    setShowModal(false);
    dispatchGtmRemoveFromCart();
    dispatch(cartActions.removeCard());
    if (window.hj) {
      window.hj('trigger', 'card-removed');
    }
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <p>Seu pedido</p>
          <p>Valor</p>
        </S.Header>
        <S.Row>
          <p>Presente(s)</p>
          <p>{totalGiftsFormatted}</p>
        </S.Row>

        {card && (
          <S.Row>
            <p>
              Cartão iCasei
              <S.ButtonRemoveCard onClick={handleOpenModal}>
                (Remover)
              </S.ButtonRemoveCard>
            </p>
            <p>{card.valueFormatted}</p>
          </S.Row>
        )}

        <S.Footer>
          <p>Total</p>
          <span>
            <p>{totalFormatted}</p>
          </span>
        </S.Footer>
      </S.Container>

      {card && (
        <CardDialogCancel
          image={card.image_preview}
          show={showModal}
          onClose={handleModalClose}
          onRemoveCard={handleRemoveCard}
        />
      )}
    </S.Wrapper>
  );
}
