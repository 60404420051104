import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'animate.css';

import './utils/checkAgent';

import history from './services/history';
import Routes from './routes/index';

import { store, persistor } from './store';
import Initializer from './Initializer';

import Theme from './Theme';
import GlobalStyle from './components/_settings/global';

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Initializer>
            <Router history={history}>
              <Theme>
                <GlobalStyle />
                <Routes />
              </Theme>
            </Router>
          </Initializer>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
