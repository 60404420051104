import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Search from '../Search';
import { countries, getCountryById } from '../../../utils/countries';
import * as S from './styles';

const SelectCountry = ({ country, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const currentCountry = getCountryById(country);

  const onSelect = (country) => {
    setShowModal(false);
    onChange(country.id);
  };

  const onKeyDownFlag = (e) => {
    if (e.key === 'Enter') {
      setShowModal(true);
    }
  };

  return (
    <>
      <S.FlagWrapper
        role="button"
        tabIndex="0"
        onClick={() => setShowModal(true)}
        onKeyDown={onKeyDownFlag}
        data-testid="SelectCountrySelectDDI"
      >
        <S.Flag src={currentCountry.flag || ''} alt="" />
        <S.Arrow />
      </S.FlagWrapper>

      <Modal
        title="Selecione seu DDI"
        show={showModal}
        close={() => setShowModal(false)}
      >
        <Search
          options={countries || []}
          onSelect={onSelect}
          filter={(search, country) => {
            const regex = new RegExp(search, 'gi');
            return regex.test(country.callingCode) || regex.test(country.name);
          }}
          renderItem={(country) => (
            <S.Option>
              <span>+{country.callingCode}</span>
              <span>{country.name}</span>
              <img src={country.flag} />
            </S.Option>
          )}
        />
      </Modal>
    </>
  );
};

SelectCountry.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SelectCountry;
