import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as S from './styles';
import TextField from '../../atoms/TextField';

const Search = ({ options, onSelect, filter, renderItem, optionSize = 46 }) => {
  const [search, setSearch] = useState('');

  const onKeyDownSelect = (e, option) => {
    if (e.key === 'Enter') {
      onSelect(option);
    }
  };

  const Option = ({ index, style }) => {
    const option = options[index];
    return (
      <S.Option
        tabIndex="0"
        style={style}
        onKeyDown={(e) => onKeyDownSelect(e, option)}
        onClick={() => onSelect(option)}
        data-testid="SelectCountryOption"
      >
        {renderItem(option, index)}
      </S.Option>
    );
  };

  if (filter) {
    options = options.filter((option) => filter(search, option));
  }

  return (
    <S.Container>
      <S.TextField
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        name="search"
        placeholder="Pesquisar"
      />
      <S.Options>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={options.length}
              itemSize={optionSize}
              width={width}
            >
              {Option}
            </List>
          )}
        </AutoSizer>
      </S.Options>
    </S.Container>
  );
};

Search.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.func,
  optionSize: PropTypes.number,
  renderItem: PropTypes.func.isRequired,
};

export default Search;
