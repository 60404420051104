export const lightenWithOpacity = (hexColor, opacity) => {
  const color = [
    parseInt(hexColor.substring(1, 3), 16),
    parseInt(hexColor.substring(3, 5), 16),
    parseInt(hexColor.substring(5), 16),
  ]

  const adjustedColor = color.map((value) => {
    return Math.round(opacity * value + (1 - opacity) * 255) // Based on a white background
  })

  return `#${adjustedColor.map((value) => {
    return value.toString(16)
  })}`.replace(/,/g, '')
}

export const hexToRgba = (hexColor, alpha = 1) => {
  if (hexColor && hexColor.includes('rgb')) return hexColor

  const stringValues =
    hexColor?.length === 4
      ? [
          hexColor?.slice(1, 2),
          hexColor?.slice(2, 3),
          hexColor?.slice(3, 4),
        ].map((n) => `${n}${n}`)
      : [
          hexColor?.slice(1, 3) || 0,
          hexColor?.slice(3, 5) || 0,
          hexColor?.slice(5, 7) || 0,
        ]

  const [r, g, b] = stringValues.map((n) => parseInt(n, 16))

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
