import React from 'react';
import PropTypes from 'prop-types';

import { Previous, Next, Play, Pause } from './icons';

import * as S from './styles';

const Player = ({ audioState, onPlay, onPause, onNext, onPrev }) => {
  return (
    <S.Wrapper show={audioState !== 'stopped' ? 1 : 0}>
      <Previous onClick={onPrev} />
      {audioState === 'playing' ? (
        <Pause onClick={onPause} />
      ) : (
        <Play onClick={onPlay} />
      )}
      <Next onClick={onNext} />
    </S.Wrapper>
  );
};

Player.propTypes = {
  audioState: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

Player.defaultProps = {
  audioState: 'stopped',
  onPlay: () => {},
  onPause: () => {},
  onNext: () => {},
  onPrev: () => {},
};

export default Player;
