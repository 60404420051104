import { takeLatest, call, put, all, select } from 'redux-saga/effects'

import api from '../../../services/api'
import history from '../../../services/history'
import isDomain from '../../../utils/isDomain'

import { signInSuccess, signSetError } from './actions'

export function* signIn({ payload }) {
  try {
    const { password } = payload

    if (!password) {
      return
    }

    yield call(api.post, '/api/v1/protected', {
      password,
    })

    yield put(signInSuccess())

    const { login } = yield select((state) => state.user.profile)
    history.push(isDomain() ? `/` : `/${login}`)
  } catch (err) {
    yield put(
      signSetError(
        'Senha inválida.Verifique a senha digitada e tente novamente'
      )
    )
  }
}

export default all([takeLatest('@auth/SIGN_IN_REQUEST', signIn)])
