export function menuFetchData(coupleId) {
  return {
    type: '@menu/GET_DATA_REQUEST',
    payload: { coupleId },
  }
}

export function menuSuccessFetchData(data) {
  return {
    type: '@menu/GET_DATA_SUCCESS',
    payload: { data },
  }
}

export function menuSetHome(page) {
  return {
    type: '@menu/SET_HOME',
    payload: { page },
  }
}

export function menuSetMainList(page) {
  return {
    type: '@menu/SET_MAIN_LIST',
    payload: { page },
  }
}
