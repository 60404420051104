const components = {
  button: {
    skin: 'Skin1',
    rounded: false,
    borderRadius: 4,
    background: true,
    fontFamily: false,
    fontWeight: false,
    fontSize: null,
    fontSizeMobile: null,
    contrastOffset: 1,
    textTransform: 'none',
    darkenOnHover: false,
    textColor: null,
    boxShadow: null,
    border: null,
    darken: null,
    disabled: {
      opacity: 0.3,
    },
    hover: {
      border: null,
      textColor: 'body',
      background: null,
      backgroundOpacity: null,
      backgroundDarken: 0.1,
    },
    secondaryProps: {
      border: null,
      darken: null,
      textColor: 'content',
      borderColor: 'content',
      fontWeight: false,
      background: null,
      hover: {
        border: null,
        textColor: 'body',
        background: 'content',
        backgroundOpacity: 1,
        backgroundDarken: 0.1,
      },
      disabled: {
        opacity: 0.3,
      },
    },
    tertiaryProps: {
      border: null,
      darken: null,
      textColor: 'content',
      borderColor: 'content',
      fontWeight: false,
      background: null,
      hover: {
        border: null,
        textColor: 'body',
        background: 'content',
        backgroundOpacity: 1,
        backgroundDarken: 0.1,
      },
      disabled: {
        opacity: 0.3,
      },
    },
  },
  brand: {
    color: 'content',
    colorHover: 'content',
    opacity: true,
    opacityLevel: 0.3,
    opacityLevelHover: 1,
  },
  cardItem: {
    skin: 'Skin1',
    fontSize: '14px',
    padding: '12px 0',
    textTransform: false,
    width: false,
    borderRadius: '0',
    buttons: {
      fontFamily: 'fontMenu',
      selected: {
        color: false,
        border: false,
        backgroundColor: false,
      },
      preview: {
        color: false,
        border: false,
        backgroundColor: false,
      },
      select: {
        color: false,
        border: false,
        backgroundColor: false,
      },
    },
  },
  decorativeDivider: {
    display: true,
    skin: 'Skin1',
    color: 'main',
  },
  charDivider: '&',
  dialog: {
    skin: 'Skin3',
    headerColor: 'main',
    contentColor: 'content',
    contentFontFamily: 'fontText',
    cancelContentColor: null,
    backgroundColor: false,
    headBoxShadow: false,
    iconColor: null,
    contentBackground: null,
    cardCancel: {
      variant: 'primary',
    },
    button: {
      variant: 'secondary',
    },
  },
  loading: {
    wrapperBackground: 'lightBackground',
    loaderColor: 'darkBackground',
  },
  internalHero: {
    skin: 'Skin1',
  },
  hero: {
    skin: 'Skin1',
  },
  survey: {
    skin: 'Skin1',
    decorativeDivider: false,
    floatingBackground: [],
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
    containerBackground: {
      even: 'body',
      odd: 'body',
    },
    barColor: 'main',
    barOpacity: '0.04',
    borderRadiusFirstOption: '0px',
    borderRadiusLastOption: '0px',
  },
  place: {
    skin: 'Skin1',
    imageFormat: 'square',
    wrapperBackground: 'body',
    containerBackground: 'lightBackground',
    mobileContainerBackground: 'lightBackground',
    containerBorderRadius: '0px',
    siteLink: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  tips: {
    skin: 'Skin1',
  },
  countdown: {
    display: true,
    wrapperBackground: 'body',
    countdownColor: 'main',
    titleColor: 'content',
    titleTipography: 'content',
    titleFontSize: null,
    titleFontSizeMobile: null,
    titleLetterSpacing: false,
    titleLetterSpacingMobile: false,
    titleFontStyle: 'normal',
    contentColor: 'content',
    textTransform: 'none',
    opacity: '1',
    floatingBackground: [],
  },
  tracker: {
    skin: 'Skin1',
    textColor: false,
    textTypography: false,
    valueTypography: false,
    valueFontSize: null,
    valueFontSizeMobile: null,
    valueFontFamily: null,
    topBorderRadius: '2px 2px 0 0',
    bottomBorderRadius: '0 0 2px 2px',
    labelTextTransform: 'uppercase',
  },
  bestman: {
    wrapperBackground: 'body',
    containerBackground: 'body',
    contentBackground: 'lightBackground',
    contentBorderRadius: '0px',
    contentAlign: 'center',
    titleColor: 'content',
    titleSize: null,
    imageFormat: 'square',
  },
  externalStore: {
    skin: 'Skin1',
    wrapperBackground: 'body',
    containerBackground: 'body',
    contentBackground: 'lightBackground',
    photoWrapperBackground: null,
    infoBorderRadius: '0px',
    siteLink: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  supplier: {
    skin: 'Skin1',
    wrapperBackground: 'body',
    containerBackground: 'body',
    contentBackground: 'lightBackground',
    infoBorderRadius: '0px',
    siteLink: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  messagesForm: {
    wrapperBackground: 'lightBackground',
  },
  messages: {
    skin: 'Skin1',
    fontWeight: 'bold',
    messageAccentColor: 'main',
    messageAccentOpacity: 1,
    replyAccentColor: 'content',
    replyAccentOpacity: 0.54,
    replyBackground: null,
    replyBorder: null,
    replyBorderRadius: '0px',
    dividerOpacity: 1,
  },
  messagesHome: {
    skin: 'Skin1',
    wrapperBackground: 'lightBackground',
  },
  rsvp: {
    wrapperBackground: 'lightBackground',
  },
  rsvpHome: {
    divider: true,
    wrapperBackground: {
      even: 'lightBackground',
      odd: 'lightBackground',
    },
  },
  header: {
    skin: 'Skin1',
    fontFamily: 'fontNameTop',
    dividerColor: false,
    default: {
      wrapperBackground: 'body',
      borderBottomColor: 'menu',
      borderOpacity: 0.08,
      coupleNameOption: 'firstLetter',
      coupleNameSize: null,
      coupleNameWeight: 300,
      coupleNameColor: 'coupleName',
      coupleNameStyle: 'normal',
      menuColor: 'menu',
      showFlag: true,
    },
    attachedToHero: {
      wrapperBackground: 'transparentBackground',
      borderBottomColor: 'menu',
      borderOpacity: 0,
      coupleNameSize: null,
      coupleNameWeight: 300,
      coupleNameOption: 'firstLetter',
      coupleNameColor: 'coupleName',
      coupleNameStyle: 'normal',
      menuColor: 'menu',
      showFlag: false,
    },
    whenShowNavigation: {
      coupleNameSize: null,
      coupleNameWeight: null,
      coupleNameColor: null,
      coupleNameStyle: null,
    },
  },
  navigationDropdown: {
    skin: 'Skin1',
    arrowColor: null,
    borderTop: null,
    borderTopAttachedToHero: null,
    borderColor: null,
    borderOpacity: 1,
    titleColorMobile: null,
    titleFontWeightMobile: null,
    activeLinkFontWeight: 'bold',
    activeLinkFontFamily: 'fontText',
    attachedToHero: {
      svgColor: null,
      titleColor: null,
    },
  },
  radio: {
    empty: {
      borderColor: 'content',
    },
    selected: {
      borderColor: 'content',
      markerColor: 'content',
    },
  },
  textField: {
    skin: 'Skin1',
    rounded: false,
    borderRadius: 4,
    contentColor: 'content',
    focusBorderColor: 'main',
    helperMessageColor: null,
    label: {
      textTransform: '',
      letterSpacing: '',
      selected: {
        fontSize: null,
        fontFamily: null,
        color: null,
        textTransform: null,
      },
    },
    phoneCountrySelect: {
      arrowColor: 'main',
      textColor: 'content',
      background: 'body',
      highlightedBackground: 'lightBackground',
    },
  },
  selectField: {
    rounded: false,
    borderRadius: 4,
    arrowColor: null,
  },
  selectFieldCustom: {
    border: null,
    arrowColor: null,
    textColor: null,
    fontFamily: null,
    textTransform: null,
    fontSizeMobile: null,
    borderRadius: null,
    borderRadiusWhenOpened: null,
  },
  socialIcon: {
    skin: 'Skin1',
    rounded: true,
    backgroundColor: 'lightBackground',
    backgroundOpacity: 1,
    backgroundColorHover: 'main',
    backgroundOpacityHover: 1,
    boxShadow: null,
    borderRadius: null,
  },
  backToTop: {
    skin: 'Skin2',
    contentColor: 'content',
    fontWeight: 'normal',
    fontSize: null,
    fontSizeMobile: null,
    fontFamily: 'fontText',
    textTransform: 'uppercase',
  },
  product: {
    skin: 'Skin1',
    wrapperBackground: 'lightBackground',
    border: 'transparentBackground',
    borderAddedCart: 'transparentBackground',
    rounded: false,
    borderRadius: 4,
    value: {
      fontFamily: false,
    },
    button: {
      textTransform: '',
      addedBackground: 'content',
      disabledBackground: 'transparentBackground',
      addedBorder: null,
    },
  },
  navigation: {
    skin: 'Skin1',
    wrapperBackground: 'body',
    contentColor: 'menu',
    topDistance: 56,
    lateralDistance: 32,
    fontSize: 14,
    menuIconColor: 'menu',
    textTransform: 'uppercase',
    menuInactiveIconColor: 'menu',
    attachedToHero: {
      hamburguerColor: null,
    },
  },
  navigationCheckout: {
    textTransform: 'uppercase',
    iconColor: false,
    iconOpacity: '0.8',
    color: false,
  },
  flag: {
    backgroundColor: 'main',
    color: 'body',
    textTransform: 'none',
    fontWeight: '300',
    fontFamily: 'fontText',
    linesColor: null,
    boxShadow: false,
  },
  forecast: {
    dateFontWeight: false,
    fontSize: 14,
  },
  welcome: {
    default: {
      showTitle: true,
    },
    attachedToHero: {
      showTitle: true,
    },
  },
  title: {
    textColor: 'main',
    textTransform: 'none',
    lineHeight: 'normal',
    lineHeightMobile: 'normal',
  },
  subtitle: {
    fontFamily: 'fontText',
    fontStyle: 'normal',
    textColor: 'content',
    fontSize: null,
    letterSpacing: null,
    letterSpacingMobile: null,
    lineHeight: null,
    lineHeightMobile: 'normal',
    lineHeightWithoutPixel: false,
    textTransform: 'none',
  },
  album: {
    accentColor: 'darkBackground',
    contentColor: 'content',
    buttonVariant: 'primary',
    textFieldVariant: 'default',
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  recentPhotos: {
    divider: true,
  },
  photo: {
    skin: 'Skin1',
    fullWidth: true,
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
    floatingBackground: [],
  },
  video: {
    skin: 'Skin1',
    fullWidth: true,
    borderWidth: {
      desktop: 0,
      mobile: 0,
    },
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
    floatingBackground: [],
  },
  text: {
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  map: {
    fullWidth: true,
    borderWidth: {
      desktop: 0,
      mobile: 0,
    },
    wrapperBackground: {
      even: 'body',
      odd: 'body',
    },
  },
  store: {
    categories: {
      titleColor: 'main',
      titleSize: null,
      textTransform: false,
      borderColor: false,
      borderRadius: false,
    },
    filters: {
      fullWidth: false,
      wrapperBackground: 'lightBackground',
      wrapperBackgroundOpacity: 1,
      wrapperBackgroundMobile: null,
      wrapperBackgroundMobileOpacity: 1,
      borderColor: 'transparentBackground',
      borderRadius: '0px',
      buttons: {
        borderColor: 'formBorder',
      },
      changeColorOnSelected: true,
    },
  },
  cart: {
    wrapperBackground: 'lightBackground',
    stepsSkin: 'Skin1',
    stepsPosition: 'afterTitle',
    resumeTextColor: 'content',
    removeTextColor: 'alert',
    slickButtonColor: 'content',
    resumeDividerBorderColor: null,
    steps: {
      activeButtonColor: false,
      activeButtonBackground: null,
      activeButtonBorder: null,
      activeButtonBorderRadius: null,
      disabledButtonBorder: null,
      disabledButtonOpacity: 0.36,
      buttonTextTransform: false,
      disabledButtonBorderRadius: null,
    },
    quantityBox: {
      borderRadius: false,
    },
  },
  cartButton: {
    iconColor: 'body',
    backgroundColor: 'main',
    notificationBackgroundColor: 'alert',
    notificationTextColor: 'white',
  },
  cartConfirmation: {
    strong: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  payment: {
    borderRadius: false,
  },
  shareWhatsapp: {
    buttonBorderColor: 'main',
    buttonTextColor: 'main',
    buttonBorderRadius: '0',
    buttonFontSize: false,
    iconColor: 'main',
    fontWeight: false,
    fontFamily: null,
    boxShadow: false,
  },
  recaptcha: {
    iconOpacity: 1,
    iconColor: 'lightBackground',
    borderRadius: null,
    fontSize: false,
    link: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
  footer: {
    borderTopColor: null,
    borderTopOpacity: null,
  },
  cardDialog: {
    background: 'body',
    textColor: 'content',
  },
  cardDialogCancel: {
    background: false,
    strong: {
      fontFamily: 'fontText',
      fontWeight: 'bold',
    },
  },
}

export default components
