export function signInRequest(password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { password },
  }
}

export function signInSuccess() {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
  }
}

export function signSetError(error) {
  return {
    type: '@auth/SIGN_SET_ERROR',
    payload: { error },
  }
}
