import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

import defaultLayout from '../pages/_layouts/Default';
import cleanLayout from '../pages/_layouts/Clean';
import AppBanner from '../components/molecules/AppBanner';

import { store } from '../store';
import isDomain from '../utils/isDomain';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isSignPage,
  ...rest
}) {
  const homePath = store.getState().menu;
  const { profile } = store.getState().user;
  const { pathname } = useLocation();
  const { params } = rest.computedMatch;
  const { login, slug, placeId } = params;

  const showBanner = useMemo(() => {
    return (
      !pathname.includes('/facebooklogin') &&
      !pathname.includes('/cart/confirmation') &&
      profile.exibition_configs.app
    );
  }, [profile, pathname]);

  if (!pathname.includes('/facebooklogin') && !profile.logged && isPrivate) {
    return <Redirect to={isDomain() ? '/protected' : `/${login}/protected`} />;
  }

  if (
    (isSignPage && !profile.private) ||
    (pathname.includes('/facebooklogin') && isSignPage)
  ) {
    return <Redirect to={homePath.home.path} />;
  }

  const Layout =
    (!profile.private || profile.logged) &&
    (slug ||
      placeId ||
      pathname.includes('/rsvp') ||
      pathname.includes('/cart') ||
      pathname.includes('/store') ||
      pathname.includes('/pages'))
      ? defaultLayout
      : cleanLayout;

  /* eslint-disable */

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
          {showBanner && <AppBanner />}
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isSignPage: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isSignPage: false,
};
