import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const Steps = ({ step }) => {
  const [Skin, setSkin] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.cart.stepsSkin}/index.jsx`
      );
      setSkin(() => response.default);
    }

    getSkin();
  }, [theme]);

  return (
    Skin && (
      <Skin
        step={step}
        firstStep="1. Carrinho"
        secondStep="2. Informações"
        thirdStep="3. Pagamento"
        fourthStep="4. Confirmação"
      />
    )
  );
};

Steps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Steps;
