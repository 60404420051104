import React from 'react';

import Subtitle from '../../../../atoms/Subtitle';
import Spacer from '../../../../atoms/Spacer';
import { ReactComponent as CreditCardIcon } from '../../../../../assets/img/credit-card.svg';

import * as S from '../styles';

const CreditCard = ({ name, email, paymentId }) => (
  <>
    <Subtitle>Compra finalizada com sucesso!</Subtitle>
    <S.Content>
      {name}, um email de confirmação foi enviado para <strong>{email}</strong>
    </S.Content>
    <Spacer size="small" />
    <S.Card>
      <S.CardIcon>
        <CreditCardIcon />
      </S.CardIcon>
      <S.CardContent>
        <p>
          <strong>Identificação da transação (TID):</strong> {paymentId}
        </p>
        <p>
          <strong>Importante:</strong> O estabelecimento que realizou a venda
          chama-se ICASEI PRESENTES e assim aparecerá em sua fatura do cartão de
          crédito.
        </p>
      </S.CardContent>
    </S.Card>
  </>
);

export default CreditCard;
