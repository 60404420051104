import styled, { css } from 'styled-components'

import { TitleSM } from '../../_settings/typography'

export const Wrapper = styled.div.attrs({
  className: 'title',
})`
  width: 100%;
  padding: 20px 15px;
`

export const Title = styled.h2`
  ${({ theme }) => css`
    ${TitleSM};
    font-family: var(--title-font-family);
    text-transform: ${theme.components.title.textTransform};
    line-height: ${theme.components.title.lineHeight};

    @media (max-width: 768px) {
      line-height: ${theme.components.title.lineHeightMobile};
    }
  `}
`
