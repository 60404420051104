import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--base-font-family);
  color: var(--content);
  font-size: 14px;
  padding: 80px 15px;
  margin: 120px 0;
  background: var(--lightBackground);

  @media (max-width: 768px) {
    padding: 50px 15px;
    margin: 50px 0;
  }
`

export const CollapseContainer = styled.div`
  max-width: 350px;
  width: 100%;
`

export const Collapse = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
`

export const CollapseBody = styled.div`
  padding: 16px 16px 16px 24px;

  p {
    opacity: 0.5;
    margin-bottom: 20px;
    font-family: var(--base-font-family);
  }

  ul {
    li {
      a {
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        font-family: var(--base-font-family);
        color: var(--content);
        text-decoration: none;
        font-size: 14px;

        @media (max-width: 768px) {
          font-size: 13px;
        }

        svg {
          transition: all 500ms;
          margin-right: 12px;
        }

        &:hover {
          svg {
            transform: scale(1.2) translateX(4px);
          }
        }
      }
    }
  }
`
