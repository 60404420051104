import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Head = ({ pageName, lang }) => {
  const { profile } = useSelector((state) => state.user);
  const weddingDate = useMemo(
    () => moment(profile.wedding_datetime).format('DD/MM/YYYY'),
    [profile.wedding_datetime]
  );

  const filteredName = useMemo(
    () =>
      typeof pageName === 'string'
        ? pageName
        : pageName.filter((item) => typeof item === 'string')[0] || '',
    [pageName]
  );
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>
        {profile.couple_name} ({weddingDate}) {`${filteredName}`} | iCasei
      </title>
      <meta name="lang" content={lang} />
      <meta
        name="description"
        content={`Site de casamento oficial de ${profile.couple_name} - ${weddingDate}. Eles fizeram o site de casamento no iCasei. ${filteredName}`}
      />
      <link rel="canonical" href={window.location.href} />
      <link rel="alternate" hrefLang="x-default" href={window.location.href} />
    </Helmet>
  );
};

Head.defaultProps = {
  pageName: '',
};

Head.propTypes = {
  pageName: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

export default Head;
