import styled, { keyframes } from 'styled-components'

const heartUp = keyframes`
    0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) rotate(10deg);
  }

  50% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  90% {
    opacity: 0;
    transform: translate3d(0, -117px, 0) scale(1.2) rotate(-10deg);
  }

  100% {
    opacity: 0;
  }
`

export const Wrapper = styled.div.attrs({
  className: 'loading-screen',
})`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ theme }) =>
    theme.colors[theme.components.loading.wrapperBackground]};
  content: '';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.show};
  z-index: ${(props) => (props.show === '0' ? '-1' : '200')};
  transition: ${(props) =>
    props.show === '0' ? 'opacity 2s 1s ease-out, z-index 2s step-end' : ''};
`

export const Container = styled.div`
  margin: auto;
  width: 4rem;
  max-height: 4rem;
  height: 100%;
  opacity: ${(props) => props.show};
  transition: ${(props) => (props.show === '0' ? 'opacity 1.3s ease-out' : '')};
`

export const Heart = styled.div.attrs({
  className: 'loading-screen__heart',
})`
  position: absolute;

  &:nth-child(1) svg {
    opacity: 0;
    animation: ${heartUp} 1.4s infinite ease-in;
  }
  &:nth-child(2) svg {
    position: absolute;
    left: 1rem;
    opacity: 0;
    animation: ${heartUp} 1.4s 0.3s infinite ease-in;
  }
  &:nth-child(3) svg {
    position: absolute;
    left: 0.5rem;
    opacity: 0;
    animation: ${heartUp} 1.4s 0.6s infinite ease-in;
  }

  svg {
    fill: ${({ theme }) => theme.colors[theme.components.loading.loaderColor]};
  }
`
