import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
  :root {
    ${({ theme: { colors, fonts, components } }) => css`
      --coupleName: ${colors?.coupleName || '#FFF'};
      --menu: ${colors?.menu || '#FFF'};
      --content: ${colors?.content || '#FFF'};
      --title-text-color: ${colors[components?.title?.textColor] || '#FFF'};
      --main: ${colors?.main || '#FFF'};
      --body: ${colors?.body || '#FFF'};
      --formBorder: ${colors?.formBorder || '#000'};
      --lightBackground: ${colors?.lightBackground || '#FFF'};
      --darkBackground: ${colors?.darkBackground || '#FFF'};
      --transparentBackground: ${colors?.transparentBackground || '#FFF'};
      --alert: ${colors?.alert || '#EF5350'};
      --black: ${colors?.black || '#000000'};
      --white: ${colors?.white || '#ffffff'};
      --error: #ef5350;

      --base-font-family: '${fonts?.fontText?.family || 'Helvetica Neue'}';
      --menu-font-family: '${fonts?.fontMenu?.family || 'Helvetica Neue'}';
      --title-font-family: '${fonts?.fontTitle?.family || 'Helvetica Neue'}';
      --name-font-family: '${fonts?.fontNameTop?.family || 'Helvetica Neue'}';
    `}
  }

  * {
    margin:0;
    padding:0;
    box-sizing: border-box;

    &::selection {
      background: var(--main);
      color: var(--body);
    }
  }

  html, body, #root{
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-weight: 300;
  }

  iframe {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }

  #root {
    min-height: 100vh;
  }

  body{
    -webkit-font-smoothing: antialiased;
    font-family: var(--base-font-family), sans-serif;
    background: var(--body);
  }

  html {
    font-size: 100%;
  }

  body, input, button{
    color: #222;
  }

  button{
    cursor: pointer;
    border: none;
  }

  ul {
    list-style: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  /* Hides reCaptcha badge */
  .grecaptcha-badge {
    visibility: hidden;
  }

  ${({ theme: { configs } }) => css`
    ${configs.customCss};
  `}

  /* Removes Safari default input style */
  input, input[type="search"], text-area {
    -webkit-appearance: none;
  }

  body.no-scroll {
    height: 100%;
    overflow: hidden;
  }
`
