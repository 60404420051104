import React, {
  useEffect,
  useState,
  Suspense,
  useCallback,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import useHistory from '../../../utils/useHistory'
import getImage from '../../../utils/getImage'
import getMeta from '../../../utils/getMeta'
import getUrlParameterByName from '../../../utils/getUrlParameterByName'

import * as S from './styles'

import Loading from '../../molecules/Loading'

const AnimatedIntro = ({
  customBackground,
  isCd,
  coupleInfo,
  introSettings,
}) => {
  const [customBackgroundState, setCustomBackgroundState] = useState('')
  const [coupleData, setCoupleData] = useState(null)
  const [customIntro, setCustomIntro] = useState(null)
  const [skipped, setSkipped] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const history = useHistory()
  const login = getMeta('login')
  const { home } = useSelector((state) => state.menu)
  const { language } = useSelector((state) => state.language)

  const warningMessage = useMemo(() => {
    if (language.htmlTag === 'en') {
      return `<div class="notranslate"><a href="https://www.icasei.com.br/site-de-casamento" rel="nofollow" target="_blank">Wedding website</a> and <a href="https://www.icasei.com.br/lista-de-casamento" rel="nofollow" target="_blank">Wedding list</a> by iCasei <br> All rights reserved.</div>`
    }

    if (language.htmlTag === 'es') {
      return `<div class="notranslate"><a href="https://www.icasei.com.br/site-de-casamento" rel="nofollow" target="_blank">Sitio web de bodas</a> y <a href="https://www.icasei.com.br/lista-de-casamento" rel="nofollow" target="_blank">lista de bodas</a> por iCasei <br> Todos los derechos reservados.</div>`
    }

    if (language.htmlTag === 'fr') {
      return `<div class="notranslate"><a href="https://www.icasei.com.br/site-de-casamento" rel="nofollow" target="_blank">Site Web de mariage</a> et <a href="https://www.icasei.com.br/lista-de-casamento" rel="nofollow" target="_blank">liste de mariage</a> par iCasei <br> Tous droits réservés.</div>`
    }

    if (language.htmlTag === 'de') {
      return `<div class="notranslate"><a href="https://www.icasei.com.br/site-de-casamento" rel="nofollow" target="_blank">Hochzeitswebsite</a> und <a href="https://www.icasei.com.br/lista-de-casamento" rel="nofollow" target="_blank">Hochzeitsliste</a> von iCasei <br> Alle rechte vorbehalten.</div>`
    }

    return `<div class="notranslate"><a href="https://www.icasei.com.br/site-de-casamento" rel="nofollow" target="_blank">Site de casamento</a> e <a href="https://www.icasei.com.br/lista-de-casamento" rel="nofollow" target="_blank">Lista de casamento</a> por iCasei <br> Todos os direitos reservados.</div>`
  }, [language.htmlTag])

  const redirectUser = useCallback(
    () => history.push(`/${home.slug}`),
    [history, home.slug]
  )

  const handleSkipIntro = useCallback(async () => {
    const dev = getUrlParameterByName('dev')
    const prw = getUrlParameterByName('prw')
    if (dev) {
      // eslint-disable-next-line no-console
      console.log('onClick: Pular Introdução')
      return
    }

    if (prw) {
      window.location.reload()
    } else {
      setSkipped(true)
    }
  }, [])

  const handleLoad = () => {
    setLoaded(true)
  }

  const backgroundToBase64 = (url) => {
    const httpRequest = new XMLHttpRequest()
    httpRequest.onload = () => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        setCustomBackgroundState(fileReader.result)
      }
      fileReader.readAsDataURL(httpRequest.response)
    }
    httpRequest.open('GET', url)
    httpRequest.responseType = 'blob'
    httpRequest.send()
  }

  const loadCustomIntro = (intro) => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.get(intro)
  }

  useEffect(() => {
    let customBackgroundPath = ''

    if (isCd) {
      backgroundToBase64(`https://sites.icasei.com.br/${customBackground}`)
    } else {
      customBackgroundPath = customBackground
        ? getImage(login, customBackground, null, 'thumb')
        : null
    }

    setCustomBackgroundState(customBackgroundPath)

    setCoupleData({
      coupleFirstName: {
        bride: coupleInfo.brideName.split(' ')[0],
        groom: coupleInfo.groomName.split(' ')[0],
      },
      coupleSimplifiedName: {
        bride: coupleInfo.brideName.charAt(0).toUpperCase(),
        groom: coupleInfo.groomName.charAt(0).toUpperCase(),
      },
      coupleSingleName: {
        bride: coupleInfo.brideName,
        groom: coupleInfo.groomName,
      },
      weddingDate: {
        timestamp: coupleInfo.weddingDate,
      },
    })
    setCustomIntro(loadCustomIntro('intro'))
  }, [
    coupleInfo.brideName,
    coupleInfo.groomName,
    coupleInfo.weddingDate,
    customBackground,
    isCd,
    language.route,
    login,
  ])

  useEffect(() => {
    if (!skipped) return

    redirectUser()
  }, [redirectUser, skipped])

  useEffect(() => {
    if (!loaded) return undefined

    const timeout = setTimeout(() => {
      handleSkipIntro()
    }, 12000)

    return () => clearTimeout(timeout)
  }, [handleSkipIntro, loaded])

  const Intro = React.lazy(() => {
    return import(`./skins/${customIntro || introSettings.path}/index.tsx`)
  })

  return (
    <S.Wrapper>
      <Suspense fallback={<Loading loaded={false} />}>
        <Intro
          coupleData={coupleData}
          buttonMessage="Pular Introdução"
          warningMessage={warningMessage}
          background={customBackgroundState}
          locale={language.htmlTag}
          onSkipIntro={handleSkipIntro}
          onLoad={handleLoad}
          delay={1300}
        />
      </Suspense>

      <Loading loaded={loaded} />
    </S.Wrapper>
  )
}

AnimatedIntro.defaultProps = {
  customBackground: '',
}

AnimatedIntro.propTypes = {
  customBackground: PropTypes.string,
  isCd: PropTypes.bool.isRequired,
  coupleInfo: PropTypes.shape({
    brideName: PropTypes.string,
    groomName: PropTypes.string,
    weddingDate: PropTypes.string,
  }).isRequired,
  introSettings: PropTypes.shape({
    id: PropTypes.number,
    path: PropTypes.string,
  }).isRequired,
}

export default AnimatedIntro
