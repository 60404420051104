import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { signInRequest, signSetError } from '../../store/modules/auth/actions';
import { setRouteLanguage } from '../../store/modules/language/actions';

import Helmet from '../../utils/head';
import Subtitle from '../../components/atoms/Subtitle';
import DecorativeDivider from '../../components/atoms/DecorativeDivider';
import TextField from '../../components/atoms/TextField';
import Button from '../../components/atoms/Button';
import Dialog from '../../components/organisms/Dialog';

import schema from './validations';

import * as S from './styles';

const SignIn = ({ match }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const profile = useSelector((state) => state.user.profile);
  const [bride, groom] = useMemo(
    () => [profile.bride_name.split(' ')[0], profile.groom_name.split(' ')[0]],
    [profile]
  );
  const { error, loading } = useSelector((state) => state.auth);

  const onSubmit = (data) => {
    if (data.password) {
      dispatch(signInRequest(data.password));
    }
  };

  useEffect(() => {
    if (match.params.lang) {
      dispatch(setRouteLanguage(match.params.lang));
    }
  }, [dispatch, match.params.lang]);

  return (
    <S.Wrapper>
      <Helmet pageName="Proteção por Senha" lang={language.htmlTag} />
      {error && (
        <Dialog
          content={error}
          topAction={{
            label: 'OK',
            action: () => {},
          }}
        />
      )}
      <S.Container>
        <Subtitle>{`Digite a senha para acessar o site de ${bride} & ${groom}`}</Subtitle>
        <DecorativeDivider />
        <S.Form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <TextField
            label="password"
            name="password"
            type="password"
            placeholder="Senha"
            register={register}
            invalid={!!error || errors?.password?.message}
            onChange={() => dispatch(signSetError(''))}
            helperText={errors?.password?.message}
          />
          <Button type="submit">{loading ? 'Carregando...' : 'Entrar'}</Button>
        </S.Form>
        <S.InfoText>
          Este site é privado e as informações só podem ser vista por quem
          possui a senha.
        </S.InfoText>
      </S.Container>
    </S.Wrapper>
  );
};

export default SignIn;
