import { all, takeLatest, put, call } from 'redux-saga/effects'

import api from '../../../services/api'

import { fetchUserDataSuccess } from './actions'

export function* fetchUserData({ payload }) {
  try {
    const { user, preview, mobile } = payload

    const response = yield call(api.get, 'api/v1/couple', {
      params: {
        login: user,
        preview,
        mobile,
      },
    })

    const { data } = response

    yield put(fetchUserDataSuccess(data, preview, mobile))
  } catch (err) {
    console.log(err)
  }
}

export default all([takeLatest('@user/GET_DATA_REQUEST', fetchUserData)])
