import produce from 'immer'

const INITIAL_STATE = {
  show: true,
}

export default function appBanner(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@appBanner/CLOSE_APP_BANNER': {
        draft.show = false
        break
      }
      default:
    }
  })
}
