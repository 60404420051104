import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import FloatingImage from '../FloatingImage';

import * as S from './styles';

const Photo = ({ isWidget, maxWidth, proportion, url, urlMobile, size }) => {
  const theme = useTheme();

  return (
    <S.Wrapper maxWidth={maxWidth}>
      <S.Photo
        url={url}
        urlMobile={urlMobile}
        proportion={proportion}
        backgroundSize={size}
      />
      {isWidget &&
        theme.components.photo.floatingBackground.map((background, index) => (
          <FloatingImage
            key={String(index)}
            desktopInfiniteBackgroundSize={
              background.desktopInfiniteBackgroundSize
            }
            zIndex={background.zIndex}
            width={background.width}
            height={background.height}
            left={background.left}
            right={background.right}
            top={background.top}
            bottom={background.bottom}
            transform={background.transform}
            maskImage={background.maskImage}
            background={background.background}
            backgroundSize={background.backgroundSize}
            backgroundImage={background.backgroundImage}
            backgroundPosition={background.backgroundPosition}
            tablet={background.tablet}
            mobile={background.mobile}
          />
        ))}
    </S.Wrapper>
  );
};

Photo.defaultProps = {
  maxWidth: '100%',
  urlMobile: '',
  size: 'cover',
  isWidget: false,
};

Photo.propTypes = {
  isWidget: PropTypes.bool,
  maxWidth: PropTypes.string,
  proportion: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlMobile: PropTypes.string,
  size: PropTypes.oneOf(['cover', 'contain']),
};

export default Photo;
