import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Subtitle = ({ children }) => (
  <S.Wrapper>
    <S.Subtitle>{children}</S.Subtitle>
  </S.Wrapper>
);

Subtitle.defaultProps = {
  children: ''
}

Subtitle.propTypes = {
  children: PropTypes.string,
};

export default Subtitle;
