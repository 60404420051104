const exceptionCreditCards = (number) => {
  switch (number) {
    case '5454555555555555':
    case '3841001111222233334':
      return true
    default:
      return false
  }
}

export default exceptionCreditCards
