import styled, { css } from 'styled-components'
import { ReactComponent as ArrowIcon } from '../../../assets/img/arrow.svg'

export const Flag = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`

export const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
  margin: 0 8px;
  padding: 0 8px;
  width: 60px;

  &:focus-visible {
    box-shadow: 0px 0px 1px 1px #222;
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  border-bottom: 1px solid #ddd;
  color: #222;

  span {
    display: block;
    text-align: left;
  }

  span:nth-child(1) {
    width: 60px;
  }

  span:nth-child(2) {
    flex: 1;
    margin: 0 20px;
  }

  img {
    width: 26px;
    border-radius: 100px;
  }
`

const arrowModifiers = {
  arrowColor: (theme, color) => css`
    path {
      fill: ${theme.colors[color]};
    }
  `,
}

export const Arrow = styled(ArrowIcon)`
  ${({ theme }) => css`
    transform: translateY(2px) rotate(180deg);

    & > path {
      fill: var(--content);
    }

    ${!!theme.components.selectFieldCustom.arrowColor &&
    arrowModifiers.arrowColor(
      theme,
      theme.components.selectFieldCustom.arrowColor
    )}
  `}
`
