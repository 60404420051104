export function pageFetchData(id, coupleId) {
  return {
    type: '@page/GET_DATA_REQUEST',
    payload: { id, coupleId },
  }
}

export function pageSuccessFetchData(contents, externalId) {
  return {
    type: '@page/GET_DATA_SUCCESS',
    payload: {
      contents,
      externalId,
    },
  }
}

export function pageErrorOnFetchData() {
  return {
    type: '@page/GET_DATA_ERROR',
  }
}
