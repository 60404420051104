import { css } from 'styled-components'

export const TitleXL = css`
  ${({
    theme: {
      typography: {
        titleXL: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--title-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: normal;
    letter-spacing: ${desktop.letterSpacing};
    color: var(--title-text-color);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const TitleLG = css`
  ${({
    theme: {
      typography: {
        titleLG: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--title-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: normal;
    letter-spacing: ${desktop.letterSpacing};
    color: var(--title-text-color);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const TitleMD = css`
  ${({
    theme: {
      typography: {
        titleMD: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--title-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: ${desktop.letterSpacing};
    color: var(--title-text-color);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const TitleSM = css`
  ${({
    theme: {
      typography: {
        titleSM: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--title-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: normal;
    letter-spacing: ${desktop.letterSpacing};
    text-align: center;
    color: var(--title-text-color);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const TitleXS = css`
  ${({
    theme: {
      typography: {
        titleXS: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--title-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: 1.5;
    letter-spacing: ${desktop.letterSpacing};
    text-align: center;
    color: var(--content);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const Content = css`
  ${({
    theme: {
      typography: {
        content: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--base-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: 2;
    letter-spacing: ${desktop.letterSpacing};
    text-align: center;
    color: var(--content);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const ContentMobile = css`
  ${({
    theme: {
      typography: {
        contentMobile: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--base-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: 2;
    letter-spacing: ${desktop.letterSpacing};
    text-align: center;
    color: var(--content);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const Menu = css`
  ${({
    theme: {
      typography: {
        menu: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--base-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    text-transform: ${desktop.textTransform};
    font-style: normal;
    line-height: normal;
    letter-spacing: ${desktop.letterSpacing};
    color: var(--menu);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      text-transform: ${mobile.textTransform};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`

export const Warning = css`
  ${({
    theme: {
      typography: {
        warning: { desktop, mobile },
      },
    },
  }) => css`
    font-family: var(--base-font-family);
    font-size: ${desktop.fontSize}px;
    font-weight: ${desktop.fontWeight};
    font-stretch: ${desktop.fontStretch};
    font-style: normal;
    line-height: 2;
    letter-spacing: ${desktop.letterSpacing};
    color: var(--content);

    @media (max-width: 768px) {
      font-size: ${mobile.fontSize}px;
      font-weight: ${mobile.fontWeight};
      font-stretch: ${mobile.fontStretch};
      letter-spacing: ${mobile.letterSpacing};
    }
  `};
`
