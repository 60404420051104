import styled, { css } from 'styled-components'

import { Warning } from '../../_settings/typography'

export const Wrapper = styled.div.attrs({
  className: 'recaptcha',
})`
  margin-bottom: 16px;
  margin-top: 16px;
`

export const Badge = styled.div.attrs({
  className: 'recaptcha__badge',
})`
  ${({ theme }) => css`
    width: 100%;
    height: 56px;
    padding: 0 16px;
    border: 1px solid var(--formBorder);
    border-radius: ${theme.components.textField.rounded ? '4px' : '0'};
    display: flex;
    align-items: center;
    border-radius: ${theme.components.recaptcha.borderRadius};
  `}
`

const iconModifiers = {
  iconOpacity: (opacity) => css`
    svg path {
      opacity: ${opacity};
    }
  `,
  iconColor: (theme, color) => css`
    svg path {
      fill: ${theme.colors[color]};
    }
  `,
}

export const Icon = styled.div`
  ${({ theme }) => css`
    min-width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--main);
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      path {
        fill: var(--body);
        opacity: 1;
      }
    }

    ${!!theme.components.recaptcha?.iconOpacity &&
    iconModifiers.iconOpacity(theme.components.recaptcha.iconOpacity)}

    ${!!theme.components.recaptcha?.iconColor &&
    iconModifiers.iconColor(theme, theme.components.recaptcha.iconColor)}
  `}
`

const textModifiers = {
  fontSize: (theme) => css`
    font-size: ${theme.components.recaptcha.fontSize}px;

    @media (max-width: 768px) {
      font-size: ${theme.components.recaptcha.fontSize}px;
    }
  `,
}

const linkFontFamily = (theme) =>
  theme.fonts[theme.components.recaptcha.link.fontFamily].family

const linkFontWeight = (theme) => theme.components.recaptcha.link.fontWeight

export const Text = styled.p`
  ${({ theme }) => css`
    ${Warning};
    line-height: 1.6;
    width: 100%;
    max-width: 238px;

    ${!!theme.components.recaptcha.fontSize && textModifiers.fontSize(theme)};

    a {
      font-family: ${linkFontFamily(theme)};
      font-weight: ${linkFontWeight(theme)};
      text-decoration: none;
      color: var(--content);
      transition: opacity 0.2s linear;
    }

    a:hover {
      opacity: 0.8;
    }

    a:focus {
      text-decoration: underline;
    }
  `}
`
