import React from 'react';
import { useSelector } from 'react-redux';
import FacebookAuthButton from '../../components/widgets/Album/FacebookAuthButton';

import * as S from './styles';

const IframeFacebookAuth = () => {
  const { user } = useSelector((state) => state);

  return (
    <S.Container>
      <FacebookAuthButton idNoivo={user.profile.couple_id} getAccountUser />
    </S.Container>
  );
};

export default IframeFacebookAuth;
