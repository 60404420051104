const mapPaths = {
  about: 'sobre',
  home: 'home',
  messages: 'home',
  suppliers: 'home',
  bestman: 'home',
  gifts: 'home',
  fornecedores: 'fornecedores',
  places: 'local',
  pages: '',
}

const getInnerPath = (path) => {
  if (typeof path === 'string') {
    return Object.keys(mapPaths).reduce((last, current) => {
      if (path.includes(current)) return mapPaths[current]
      return last
    }, '')
  }

  return ''
}

export default function getImage(login, image = '', path, type = 'thumb') {
  if (image.includes('https:/') || image.includes('http:/')) {
    return image
  }

  if (import.meta.env.VITE_REACT_APP_ENV === 'cd') {
    const innerPath = getInnerPath(path)
    const refPath =
      import.meta.env.VITE_REACT_APP_STATIC === 'static' ? `images` : `/images`

    if (path) {
      return `${refPath}/${login.charAt(
        0
      )}/${login}/imagens/${innerPath}/${image}`
    }

    if (image.includes(`${login.charAt(0)}/${login}`)) {
      return image
    }

    // eslint-disable-next-line no-undef
    return `${refPath}/${login.charAt(0)}/${login}/imagens/${image}`
  }

  const innerPath = getInnerPath(path)

  const origin =
    type === 'userfile'
      ? 'https://www.icasei.com.br/images/thumb/1/1/public/0/0/0/0/0/0/0/0/'
      : 'https://sites.icasei.com.br/userfiles/'

  return image.includes(`${login.charAt(0)}/${login}`)
    ? origin + image
    : `${origin}${login.charAt(0)}/${login}/imagens/${innerPath}/${image}`
}
