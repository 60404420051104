import styled, { keyframes, css } from 'styled-components'
import { Warning } from '../../_settings/typography'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const Modal = styled.section.attrs({
  className: 'card-dialog__background',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 500;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div.attrs({
  className: 'card-dialog',
})`
  ${({ theme }) => css`
    ${theme?.components?.cardDialog?.background &&
    css`
      background-color: ${theme.colors[theme.components.cardDialog.background]};
    `}
    width: 540px;
    max-width: 100%;
    padding: 24px 24px 8px 24px;
    animation: 0.5s ${fadeIn};
  `};
`

export const CardTitle = styled.p`
  ${Warning}
  color: ${({ theme }) =>
    theme.colors[theme.components.cardDialog.contentColor]};
  font-weight: 500;
  text-transform: uppercase;
`

export const ButtonClose = styled.button`
  ${Warning}
  color: ${({ theme }) => theme.colors[theme.components.cardDialog.textColor]};
  text-transform: uppercase;
  border: none;
  background: transparent;
  width: 130px;
  text-align: right;
`

export const Footer = styled.footer.attrs({
  className: 'card-dialog__footer',
})`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`
