import styled from 'styled-components'
import { TitleXS, Content } from '../../_settings/typography'

export const Container = styled.section.attrs({
  className: 'gift-cards',
})``

export const Title = styled.h1`
  ${TitleXS}
  font-family: var(--base-font-family);
  padding: 20px 15px;

  @media (max-width: 768px) {
    ${Content}
    line-height: 1.5;
  }
`

export const Text = styled.p`
  padding: 80px 15px;
  ${Content}

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`
