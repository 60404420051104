import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import withPlayerMobile from '../../utils/withPlayerMobile';
import artistCover from './assets/default-cover-art.png';

import * as S from './styles';

function PlayerDefault({
  skin,
  audioState,
  volume,
  progress,
  title,
  artist,
  loading,
  onChangeVolume,
  onPlay,
  onPause,
  onNext,
  onPrev,
}) {
  const [show, setShow] = useState(false);
  const paused = useMemo(() => audioState !== 'playing', [audioState]);

  function handleToggleShow() {
    setShow(!show);
  }

  return (
    <Fragment>
      <S.ShowButton
        type="button"
        onClick={handleToggleShow}
        show={show ? 1 : 0}
        skin={skin}
        aria-label="Abrir/Fechar player de música"
      />
      <S.Player show={show ? 1 : 0} skin={skin}>
        <S.PlayerContainer>
          <S.Controls>
            <S.Prev title="Música anterior" onClick={onPrev} />
            {paused ? (
              <S.Play title="Tocar Música" onClick={onPlay} />
            ) : (
              <S.Pause title="Pausar" onClick={onPause} />
            )}
            <S.Next title="Próxima canção" onClick={onNext} />
          </S.Controls>
          <S.Volume>
            <S.VolumeIcon />
            <S.VolumeWrrapper>
              <S.VolumeSlider volume={volume} />
              <S.VolumeRange
                min={0}
                max={100}
                value={volume}
                onChange={onChangeVolume}
                aria-label="Volume"
              />
            </S.VolumeWrrapper>
          </S.Volume>
          <S.Info>
            <S.ArtistCover src={artistCover} alt="artist" />
            <S.InfoRight>
              <div>
                <S.InfoText> {loading ? 'Carregando...' : title} </S.InfoText>
                <S.InfoText hiddenMobile>
                  {loading ? 'Carregando...' : artist}
                </S.InfoText>
              </div>
              <S.Progress>
                <S.ProgressBar progress={progress} />
              </S.Progress>
            </S.InfoRight>
          </S.Info>
        </S.PlayerContainer>
      </S.Player>
    </Fragment>
  );
}

PlayerDefault.propTypes = {
  skin: PropTypes.string.isRequired,
  audioState: PropTypes.string,
  volume: PropTypes.number,
  progress: PropTypes.number,
  title: PropTypes.string,
  artist: PropTypes.string,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onChangeVolume: PropTypes.func,
};

PlayerDefault.defaultProps = {
  audioState: 'stopped',
  volume: 100,
  progress: 0,
  title: '',
  artist: '',
  loading: false,
  onPlay: () => {},
  onPause: () => {},
  onNext: () => {},
  onPrev: () => {},
  onChangeVolume: () => {},
};

export default withPlayerMobile(PlayerDefault);
