import styled, { css } from 'styled-components'

import { Content } from '../../_settings/typography'
import { hexToRgba } from '../../../utils/lightenColor'

const footerModifiers = {
  customBorderTop: ({ colors, components }) => css`
    border-top: 1px solid
      ${hexToRgba(
        colors[components.footer.borderTopColor],
        components.footer.borderTopOpacity || 1
      )};
  `,
}

export const Footer = styled.footer.attrs({
  className: 'footer',
})`
  ${({ theme }) => css`
    padding: 40px 15px;
    border-top: 1px solid rgba(96, 95, 99, 0.1);

    ${theme.components.footer.borderTopColor &&
    footerModifiers.customBorderTop(theme)}
  `}
`

export const Container = styled.div`
  max-width: 1110px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const SocialList = styled.ul.attrs({
  className: 'footer__social-list',
})`
  display: flex;
  min-width: 80px;

  @media (max-width: 768px) {
    margin-bottom: ${(props) => (props.isShowing ? '40px' : '0')};
  }
`

export const SocialItem = styled.li.attrs({
  className: 'footer__social-item',
})`
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const Text = styled.p.attrs({
  className: 'footer__visits-counter',
})`
  ${Content}

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin-bottom: 32px;

    position: initial;
    transform: initial;
  }
`

export const VisitorsAmount = styled.span``
