import styled from 'styled-components'

import bg from './assets/bg.png'
import iconArrowLeft from './assets/icon_playing_off.png'
import iconArrowRight from './assets/icon_playing.png'
import iconArtist from './assets/icon_artist.png'
import iconSong from './assets/icon_song.png'
import controlBg from './assets/control_bg.png'
import iconPrev from './assets/control_btn_prev_bg.png'
import iconNext from './assets/control_btn_next_bg.png'
import iconPause from './assets/control_btn_pause_bg.png'
import iconPlay from './assets/control_btn_play_bg.png'
import eqOff from './assets/eq_off.gif'
import eq from './assets/eq.gif'
import iconVolume from './assets/icon_volume.png'

function getColorBySkin(skin) {
  switch (skin) {
    case 'skin1':
      return '#dd6b6b'
    case 'skin2':
      return '#00a5ed'
    case 'skin3':
      return '#86b401'
    case 'skin4':
      return '#f2bd4d'
    case 'skin5':
      return '#3b3b3b'
    case 'skin6':
      return '#dc4d07'
    case 'skin7':
      return '#b3323b'
    default:
      return '#dd6b6b'
  }
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  height: 93px;
  width: 616px;
  max-width: ${(props) => (props.show ? '100%' : 'auto')};
  background: url(${bg}) right no-repeat;

  position: fixed;
  bottom: 0;
  left: ${(props) => (props.show ? 0 : '-566px')};
  transition: left 0.5s;
  z-index: 10;
`

export const PlayerTop = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerTopLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

export const MusicInfo = styled.div`
  flex: 1;
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  padding-left: 18px;
  background: url(${iconSong}) no-repeat left center;
  font-family: 'PT sans', Arial, Helvetica, sans-serif;
  color: #676767;
  font-size: 15px;
  font-size: 0.9375rem;
  text-align: left;
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  padding-left: 18px;
  background: url(${iconArtist}) no-repeat left center;
  font-family: 'PT sans', Arial, Helvetica, sans-serif;
  color: #676767;
  font-size: 15px;
  font-size: 0.9375rem;
  text-align: left;
`

export const Tracks = styled.p`
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-family: 'PT sans', Arial, Helvetica, sans-serif;
  color: #676767;
  font-size: 21px;
  font-size: 1.3125rem;
`

export const Controls = styled.div.attrs({
  className: 'music-player__controls',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  background: url(${controlBg}) no-repeat center center;
`

export const Prev = styled.button.attrs({
  className: 'music-player__previous',
})`
  border: none;
  width: 21px;
  height: 20px;
  background: url(${iconPrev}) no-repeat center center;

  &:active {
    opacity: 0.8;
    transform: scale(0.9);
  }
`

export const Play = styled.button.attrs({
  className: 'music-player__play',
})`
  border: none;
  width: 31px;
  height: 33px;
  background: url(${iconPlay}) no-repeat center center;

  &:active {
    opacity: 0.8;
    transform: scale(0.9);
  }
`

export const Pause = styled.button.attrs({
  className: 'music-player__pause',
})`
  border: none;
  width: 31px;
  height: 33px;
  background: url(${iconPause}) no-repeat center center;

  &:active {
    opacity: 0.8;
    transform: scale(0.9);
  }
`

export const Next = styled.button.attrs({
  className: 'music-player__next',
})`
  border: none;
  width: 21px;
  height: 20px;
  background: url(${iconNext}) no-repeat center center;

  &:active {
    opacity: 0.8;
    transform: scale(0.9);
  }
`

export const ShowButton = styled.button`
  border: none;
  background: url(${(props) => (props.show ? iconArrowLeft : iconArrowRight)})
    no-repeat center center;
  width: 50px;
  height: 100%;
`

export const PlayerBottom = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerBottomLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

export const Progress = styled.div.attrs({
  className: 'music-player__progress',
})`
  flex: 1;
  height: 12px;
  background: rgba(150, 150, 150, 0.3);
  overflow: hidden;
`

export const PregressTrack = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress * 2}%`,
  },
}))`
  height: 100%;
  background: #c8c8c8;
`

export const ProgressBar = styled.div`
  background: ${(props) => getColorBySkin(props.skin)};
  height: 100%;
  width: 50%;
  box-shadow: inset 0 3px 6px rgba(255, 255, 255, 0.2);
`

export const Volume = styled.div.attrs({
  className: 'music-player__volume',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  margin-left: 15px;

  @media (max-width: 616px) {
    display: none;
  }
`

export const VolumeIcon = styled.div`
  height: 14px;
  width: 14px;
  background: url(${iconVolume}) no-repeat center center;
`

export const VolumeWrrapper = styled.div`
  position: relative;
  width: 130px;
  height: 12px;
  background-color: #c8c8c8;
`

export const VolumeSlider = styled.div.attrs((props) => ({
  style: {
    width: `${props.volume}%`,
  },
}))`
  position: absolute;
  height: 100%;
  background-color: ${(props) => getColorBySkin(props.skin)};
`

export const VolumeRange = styled.input.attrs({
  type: 'range',
})`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`

export const EqButton = styled.button.attrs({
  className: 'music-player__equalizer',
})`
  border: none;
  background: url(${(props) => (props.paused ? eqOff : eq)}) no-repeat center
    center;
  width: 50px;
  height: 100%;
`
