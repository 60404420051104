import { combineReducers } from 'redux'

import appBanner from './appBanner/reducer'
import auth from './auth/reducer'
import user from './user/reducer'
import menu from './menu/reducer'
import cart from './cart/reducer'
import page from './page/reducer'
import language from './language/reducer'
import ui from './ui/reducer'

export default combineReducers({
  appBanner,
  auth,
  user,
  menu,
  cart,
  page,
  language,
  ui,
})
