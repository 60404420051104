import styled, { css } from 'styled-components'

import { Content, ContentMobile, Warning } from '../../../_settings/typography'

export const Wrapper = styled.div.attrs({
  className: 'resume',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};
`

export const Container = styled.div`
  width: 100%;
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};
  padding: 0 95px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Header = styled.div.attrs({
  className: 'resume__header',
})`
  ${Content};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  line-height: 1;

  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--formBorder);
  margin-bottom: 26px;
  padding: 80px 0 22px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-weight: bold;

    margin-bottom: 24px;
    padding: 40px 0 8px;
  }
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props?.flexJustifyContent
      ? props.flexJustifyContent
      : props?.rightAlign
      ? 'flex-end'
      : 'flex-start'};
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }

  ${(props) =>
    props?.flexDirection &&
    `
      flex-direction: ${props.flexDirection};
    `}

  ${(props) =>
    props?.flexAlignItems &&
    `
    align-items: ${props.flexAlignItems};
    `}

  &:nth-child(1) {
    max-width: 60%;

    @media (max-width: 768px) {
      max-width: 50%;
      padding-right: 15px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &:nth-child(2) {
    max-width: 20%;

    @media (max-width: 768px) {
      max-width: 50%;
      padding-left: 15px;
    }
  }

  &:nth-child(3) {
    max-width: 20%;

    @media (max-width: 768px) {
      display: none;
    }
  }
`

export const ColumnItens = styled.div`
  &:nth-child(1) {
    display: block;
    @media (min-width: 769px) {
      display: none;
    }
  }

  /* &:nth-child(3) {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 40px;
    flex-direction: row;
    align-items: flex-start;
  } */
`

export const Product = styled.div.attrs({
  className: 'resume__item',
})`
  ${Content};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};

  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid var(--formBorder);
  margin-bottom: 26px;
  padding: 0 0 24px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    ${ContentMobile};
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
    line-height: normal;

    margin-bottom: 24px;

    ${Column} {
      &:nth-child(2) {
        align-items: flex-start;
      }

      &:nth-child(3) {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        margin-top: 40px;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
`

export const ProductTitle = styled.p`
  ${ContentMobile};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  margin-left: 30px;
  text-align: left;
  line-height: 1.43;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 12px;
  }
`

export const LabelPrice = styled.p`
  ${ContentMobile};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  text-align: left;
  line-height: 1.43;
  margin-top: 16px;

  @media (max-width: 768px) {
    ${(props) =>
      !props?.isVariableValue &&
      `
    margin-top: 0;
    `}
  }
`

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const QuantityContainer = styled.div`
  ${(props) =>
    props?.isVariableValue &&
    `
    margin: 0 20px;
    `}
`

export const QuantityButton = styled.button`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background: none;
  border: none;

  & > svg {
    path {
      fill: ${(props) =>
        props.disabled ? 'var(--formBorder)' : 'var(--content)'};
    }
    circle {
      stroke: ${(props) =>
        props.disabled ? 'var(--formBorder)' : 'var(--content)'};
    }

    &:hover {
      path {
        fill: ${(props) =>
          props.disabled ? 'var(--formBorder)' : 'var(--main)'};
      }
      circle {
        stroke: ${(props) =>
          props.disabled ? 'var(--formBorder)' : 'var(--main)'};
      }
    }
  }
`

export const QuantityBox = styled.div.attrs({
  className: 'quantity-box',
})`
  ${({ theme }) => css`
    margin: 0 8px;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--formBorder);
    user-select: none;
    font-weight: bolder;
    ${theme?.components?.cart?.quantityBox?.borderRadius &&
    css`
      border-radius: ${theme.components.cart.quantityBox.borderRadius}px;
    `}

    ${(props) =>
      props?.disabled &&
      `
      opacity: 0.5;
    `}
  `};
`

export const NumberFieldWrapper = styled.div.attrs({
  className: 'number-wrapper',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

export const NumberFielBox = styled.div.attrs({
  className: 'number-box',
})`
  border: 1px solid var(--formBorder);
  min-height: 56px;
  padding: 16px 8px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 4px;
  ${(props) =>
    props?.isInvalid &&
    `
    border-color: var(--error);
    `}
`

export const NumberField = styled.input.attrs({
  className: 'number-box',
})`
  ${ContentMobile};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  text-align: right;
  width: 72px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  ${(props) =>
    props?.isInvalid &&
    `
    color: var(--error);
    `}
`

export const Cipher = styled.span.attrs({
  className: 'cipher',
})`
  ${ContentMobile};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  ${(props) =>
    props?.isInvalid &&
    `
      color: var(--error);
    `}
`

export const GrupArrowsButtons = styled.div.attrs({
  className: 'grup-arrows-buttons',
})`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  margin-left: 4px;

  ${(props) =>
    props?.isInvalid &&
    `
    svg path {
      fill: var(--error);
    }
    `}
`

export const ArrowUpButton = styled.button.attrs({
  className: 'arrow-up-button',
})`
  width: 20px;
  height: 10px;
  background: none;
  border: none;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
`

export const ArrowDownButton = styled.button.attrs({
  className: 'arrow-down-button',
})`
  width: 20px;
  height: 10px;
  background: none;
  border: none;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
`

export const HelpText = styled.div`
  ${Warning};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};

  ${(props) =>
    props?.isInvalid &&
    `
      color: var(--error);
    `}
`

export const DeleteItem = styled.div`
  ${Warning};

  user-select: none;
  color: ${({ theme }) => theme.colors[theme.components.cart.removeTextColor]};
  cursor: pointer;
`

export const Footer = styled.div.attrs({
  className: 'resume__footer',
})`
  ${Content};
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  line-height: normal;
  font-weight: bold;

  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--formBorder);
  margin-bottom: 80px;
  padding: 0 0 21px;

  @media (max-width: 768px) {
    padding: 0 0 40px;
  }

  @media (max-width: 768px) {
    ${ContentMobile};
    border: none;
    margin-bottom: 0;
    font-weight: bold;
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};

    ${Column} {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        max-width: 50%;
        padding-left: 0;
        font-weight: bold;
        justify-content: flex-start;
      }

      &:nth-child(3) {
        max-width: 50%;
        display: flex;
        font-weight: bold;
      }
    }
  }
`
