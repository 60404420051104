export default [
  {
    id: 0,
    type: 'hero',
    content: {
      type: 'image',
      data: {
        cropX: '0',
        cropY: '0',
        cropW: '1920',
        cropH: '920',
        cropWidth: '1920',
        cropHeight: '920',
        default: 'mirage/in-the-woods.jpg',
        image: null,
      },
    },
  },
  {
    id: 0,
    type: 'welcome',
    content: {
      title: 'Nosso casamento',
      subtitle:
        'A melhor forma de compartilhar esse momento com vocês é unindo sonhos.',
    },
  },
  {
    id: 9039790,
    type: 'video',
    content: 'https://www.youtube.com/embed/_sDG9RePZHI',
    isEven: false,
  },
  { id: 9039886, type: 'polls', content: '44686', isEven: true },
  {
    id: 9042677,
    type: 'video',
    content:
      'https://player.vimeo.com/video/23146674?title=0&amp;byline=0…amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=98026',
    isEven: false,
  },
  {
    id: 9048410,
    type: 'image',
    content: 'Bybb8Xk2GxC0ULAi.jpeg',
    isEven: true,
  },
  {
    id: 0,
    type: 'recent_photos',
    content: [
      {
        id: 13680024,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/DeZsr6pltCtfExFd.jpg',
        provider: 'storage',
        magic_url: {},
        post_id: 367117,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680023,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/86SG4PyYAi5R6LB1.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367116,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680022,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/NC5K4hg3UfvW8dMw.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367115,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680021,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/qDfkDXzXC5LX6j1V.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367114,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680020,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/UWZ94DO8Q5yoMw3w.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367113,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680019,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/g2Tqm0vScmUCx7Po.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367112,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680018,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/nnTUcSM8t2JpdBol.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367111,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
      {
        id: 13680017,
        imagem:
          'https://sites.icasei.com.br/images/thumb/1/0/public/0/0/0/0/0/0/0/0/a/anaelucas2020/imagens/album/k0r4QrbqzUvKMjV6.jpeg',
        provider: 'storage',
        magic_url: {},
        post_id: 367110,
        album_id: 386166,
        album_title: 'Sobre nós',
      },
    ],
    isEven: false,
  },
]
