import styled, { css } from 'styled-components'

import { Warning } from '../../_settings/typography'

export const Wrapper = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: center;
  margin-top: -36px;
  position: ${({ isEnd }) => (isEnd ? 'absolute' : 'fixed')};
  bottom: ${({ isEnd }) => (isEnd ? null : '30px')};
  width: 100%;
  z-index: 10;

  @media (max-width: 768px) {
    margin-top: -28px;
    bottom: ${({ isEnd }) => (isEnd ? null : '15px')};
  }
`

export const Container = styled.div`
  max-width: 1110px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const FloatingCart = styled.div`
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 100ms ease-out;

  &:hover {
    transform: translateY(-3px);
  }
`

export const Notification = styled.div.attrs({
  className: 'cart-notification',
})`
  ${({ theme: { colors, components } }) => css`
    ${Warning};
    color: white;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${colors[components.cartButton.notificationBackgroundColor]};
    color: ${colors[components.cartButton.notificationTextColor]};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      font-weight: bold;
      width: 19px;
      height: 19px;
    }
  `}
`

export const CartButton = styled.div`
  ${({ theme: { colors, components } }) => css`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: ${colors[components.cartButton.backgroundColor]};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -4px,
      rgb(0 0 0 / 6%) 0px 6px 10px 0px, rgb(0 0 0 / 6%) 0px 1px 18px 0px;

    svg {
      width: 32px;
      height: 32px;

      path {
        fill: ${colors[components.cartButton.iconColor]};
      }
    }

    @media (max-width: 768px) {
      width: 56px;
      height: 56px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  `}
`
