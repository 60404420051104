import produce from 'immer'

import storybookData from './storyDefaultParams'

const INITIAL_STATE = {
  contents: import.meta.env.VITE_STORYBOOK_RUNNING ? storybookData : [],
  loading: true,
  externalId: null,
}

export default function page(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@page/GET_DATA_REQUEST': {
        draft.loading = true
        draft.contents = []
        break
      }
      case '@page/GET_DATA_SUCCESS': {
        draft.contents = action.payload.contents
        draft.externalId = action.payload.externalId
        draft.loading = false
        break
      }
      case '@page/GET_DATA_ERROR': {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}
