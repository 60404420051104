import produce from 'immer'

const INITIAL_STATE = {
  products: [],
  card: null,
  infos: {},
  config: {
    especialPage: true,
  },
}

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@cart/CHANGE_QUANTITY': {
        const product = draft.products.find(
          (item) => item.id === action.payload.id
        )

        if (!product) break

        if (action.payload.action === 'increase') {
          product.quantity = Math.min(
            product.remaining_quantity,
            product.quantity + 1
          )
        } else {
          product.quantity = Math.max(1, product.quantity - 1)
        }
        break
      }
      case '@cart/CHANGE_VARIABLE_VALUE': {
        const product = draft.products.find(
          (item) => item.id === action.payload.id && item.variable_value
        )

        if (!product) break

        if (action.payload.action === 'update' && action.payload?.value) {
          product.price = action.payload.value
        } else if (action.payload.action === 'increase') {
          product.price = Number(product.price) + 1
        } else {
          product.price =
            Number(product.price) <= 1 ? 0 : Number(product.price) - 1
        }
        break
      }
      case '@cart/ADD_PRODUCT': {
        draft.products.push(action.payload.product)
        break
      }
      case '@cart/REMOVE_PRODUCT': {
        const index = draft.products.findIndex(
          (item) => item.id === action.payload.id
        )

        if (index < 0) break

        draft.products.splice(index, 1)
        break
      }
      case '@cart/ADD_CARD':
        draft.card = action.payload.card
        break
      case '@cart/REMOVE_CARD':
        draft.card = null
        break
      case '@cart/SAVE_FORM_DATA': {
        const { name, email, phone, phoneCountry, message } = action.payload
        draft.infos = {
          name,
          email,
          phone,
          phoneCountry,
          message,
        }
        break
      }
      case '@cart/CLEAR_CART':
        draft.products = []
        break
      case '@cart/SAVE_CONFIG': {
        const embeddedCardChanged =
          state.config.embeddedCard !== action.payload.embeddedCard
        draft.config = { ...action.payload, embeddedCardChanged }
        break
      }
      case '@cart/UPDATE_PRODUCTS_VALUES': {
        draft.products = draft.products.map((product) => {
          const productUpdated = action.payload.products.find(
            (up) => up.id === product.id
          )

          const updated = {
            ...product,
            price: productUpdated.price,
          }

          return updated
        })
        break
      }
      default:
        return state
    }
  })
}
