import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'page-title',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    padding: 0 15px;
  }
`
