import axios from 'axios'

import interceptorApi from './interceptors-api'

// eslint-disable-next-line prefer-const
let cancelTokenSource = axios.CancelToken.source()

const api = axios.create({
  baseURL: import.meta.env.VITE_STORYBOOK_RUNNING
    ? 'https://sites.icasei.com.br/'
    : '/',
  cancelToken: cancelTokenSource.token,
})

if (import.meta.env.VITE_REACT_APP_STATIC === 'static') {
  interceptorApi({ api, cancelTokenSource })
}
export default api
