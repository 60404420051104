import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { pageSuccessFetchData } from '../../store/modules/page/actions'
import Helmet from '../../utils/head'
import ListStore from '../../components/organisms/ListStore'
import Spacer from '../../components/atoms/Spacer'
import useMedia from '../../utils/useMedia'
import Welcome from '../../Markups/Welcome'
import Loading from '../../components/molecules/Loading'
import api from '../../services/api'

export default function SpecialStore() {
  const isMobile = useMedia('(max-width: 768px)')
  const { language } = useSelector((state) => state.language)
  const menu = useSelector((state) => state.menu)
  const user = useSelector((state) => state.user)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState([])

  const dispatch = useDispatch()

  const getMenuData = (menuItems, pageId) => {
    return menuItems
      .reduce((result, item) => [...result, ...item.pages], [])
      .find((page) => page.external_id === pageId)?.path
  }

  useEffect(() => {
    async function getStores() {
      const { data } = await api.get(`api/v1/store/get_lists_with_pages`, {
        params: { couple_id: user.profile.couple_id },
      })
      if (data.page_quantity === 0) {
        history.replace(menu.home.path)
      } else if (data.page_quantity === 1) {
        const page = data.items[0].pages[0]
        const path = getMenuData(menu.menu, page.id)
        history.replace(path)
      } else {
        setItems(
          data.items.map((item) => ({
            ...item,
            pages: item.pages.map((page) => ({
              ...page,
              path: getMenuData(menu.menu, page.id),
            })),
          }))
        )
        dispatch(pageSuccessFetchData([{ type: 'welcome' }]))
        setIsLoading(false)
      }
    }

    getStores()
  }, [dispatch, menu, history, user])

  return (
    <>
      <Loading loaded={!isLoading} />

      {!isLoading && (
        <>
          <Helmet
            pageName="Lista de casamento virtual"
            lang={language.htmlTag}
          />
          <Welcome
            data={{
              title: 'Lista de casamento virtual',
              subtitle: 'Escolha qual lista de presentes deseja acessar:',
            }}
            afterMarkup="text"
          />
          {isMobile ? <Spacer size="small" /> : <Spacer size="medium" />}
          <ListStore items={items} />
          <Spacer size="medium" />
        </>
      )}
    </>
  )
}
