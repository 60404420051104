import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Header from '../../../components/organisms/Header';
import ShareWhatsapp from '../../../components/molecules/ShareWhatsapp';
import BackToTop from '../../../components/molecules/BackToTop';
import Cart from '../../../components/atoms/Cart';
import Footer from '../../../components/organisms/Footer';
import Player from '../../../components/widgets/Player';

import * as S from './styles';

export default function DefaultLayout({ children }) {
  const { showHeader } = useSelector((state) => state.ui);
  const noHero = useSelector((state) => {
    return state.page.contents.length && state.page.contents[0].type !== 'hero';
  });
  const {
    user: { profile, preview },
    cart,
  } = useSelector((state) => state);
  const { pathname } = useLocation();

  const showCartAtom = useMemo(() => {
    return !(pathname.includes('cart') || !cart.products.length);
  }, [pathname, cart]);

  return (
    <>
      {profile.music.has_music && !preview && (
        <Player data={profile.music.data} />
      )}
      <Header />
      <S.Wrapper additionalMarginTop={noHero && showHeader ? 1 : 0}>
        {children}
      </S.Wrapper>
      {profile.exibition_configs.whatsapp && <ShareWhatsapp />}
      <BackToTop />
      {showCartAtom && <Cart />}
      <Footer />
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
