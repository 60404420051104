import styled, { css } from 'styled-components'
import { ContentMobile } from '../../_settings/typography'

import { ReactComponent as ArrowSVG } from '../../../assets/img/arrow.svg'

const arrowModifiers = {
  arrowColor: (theme, color) => css`
    path {
      fill: ${theme.colors[color]};
    }
  `,
}

export const Arrow = styled(ArrowSVG)`
  ${({ theme }) => css`
    z-index: -1;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(180deg);

    ${!!theme.components.selectField.arrowColor &&
    arrowModifiers.arrowColor(theme, theme.components.selectField.arrowColor)}
  `}
`

export const Wrapper = styled.div.attrs({
  className: 'select',
})`
  ${({ fullWidth }) => css`
    position: relative;
    display: inline-block;
    width: ${fullWidth ? '100%' : 'auto'};
    z-index: 1;
  `}
`

const selectModifiers = {
  invalid: css`
    border-color: var(--error);
  `,
  rounded: (theme) => css`
    border-radius: ${theme.components.selectField.borderRadius}px;
  `,
}

export const Select = styled.select.attrs({
  className: 'select__dropdown',
})`
  ${({ theme, fullWidth, invalid }) => css`
    appearance: none;
    &::-ms-expand {
      display: none;
    }

    z-index: 5;
    width: ${fullWidth ? '100%' : 'auto'};
    height: 56px;
    border: 1px solid var(--formBorder);
    color: var(--content);
    padding: 14px 30px 14px 16px;
    background: var(--transparentBackground);
    cursor: pointer;

    ${ContentMobile};
    text-align: left;

    &:focus {
      outline: none;
      border-color: ${theme.colors[
        theme.components.textField.focusBorderColor
      ]};
    }

    option {
      color: var(--content);
      background: var(--body);
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    ${!!invalid && selectModifiers.invalid}
    ${!!theme.components.selectField.rounded && selectModifiers.rounded(theme)}
  `}
`

export default Select
