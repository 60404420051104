const isKnowInvalid = (value) => {
  const sum = value.reduce((total, current, index) => {
    if (current === value[index + 1]) return total + 1
    return total
  }, 0)

  return sum === 10
}

const validateCPF = (value) => {
  const valueArray = Array.from(value)

  // Know invalid CPFs that pass the rule validation
  if (isKnowInvalid(valueArray)) return false

  // First digit validation
  let sum = valueArray.slice(0, 9).reduce((total, current, index) => {
    return total + current * (10 - index)
  }, 0)
  let rest = (sum * 10) % 11
  if (rest.toString().substr(-1) !== value.charAt(9)) return false

  // Second digit validation
  sum = valueArray.slice(0, 10).reduce((total, current, index) => {
    return total + current * (11 - index)
  }, 0)
  rest = (sum * 10) % 11
  if (rest.toString().substr(-1) !== value.charAt(10)) return false

  // CPF is valid
  return true
}

const validateCNPJ = (value) => {
  const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  // First digit validation
  let sum = Array.from(value)
    .slice(0, 12)
    .reduce((total, current, index) => {
      return total + current * weights[index + 1]
    }, 0)
  let rest = sum % 11
  let verifier = rest < 2 ? 0 : 11 - rest
  if (verifier.toString() !== value.charAt(12)) return false

  // Second digit validation
  sum = Array.from(value)
    .slice(0, 13)
    .reduce((total, current, index) => {
      return total + current * weights[index]
    }, 0)
  rest = sum % 11
  verifier = rest < 2 ? 0 : 11 - rest
  if (verifier.toString() !== value.charAt(13)) return false

  // CNPJ is valid
  return true
}

const documentValidator = (number) => {
  const numberClean = number.replace(/\D/gi, '')
  switch (numberClean.length) {
    case 11:
      return validateCPF(numberClean)
    case 14:
      return validateCNPJ(numberClean)
    default:
      return false
  }
}

export default documentValidator
