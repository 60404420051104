import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import 'moment/dist/locale/pt-br'

import getUrlParameterByName from './utils/getUrlParameterByName'
import getMeta from './utils/getMeta'

import {
  fetchUserDataRequest,
  customTemplate,
} from './store/modules/user/actions'
import { menuFetchData } from './store/modules/menu/actions'
import { getConfig } from './store/modules/cart/actions'
import { hideHeader } from './store/modules/ui/actions'

const Initializer = ({ children }) => {
  moment.tz.setDefault('America/Sao_Paulo')
  moment.locale('pt-br')

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const menu = useSelector((state) => state.menu)
  const groom = getMeta('login')
  useEffect(() => {
    const defineUiStates = () => {
      const mobile = getUrlParameterByName('mobile')

      if (mobile === 'true') {
        dispatch(hideHeader())
      }
    }
    const defineCustomTemplate = async () => {
      const templateId = getUrlParameterByName('template')
      await dispatch(customTemplate(templateId))
    }
    const getUserData = async () => {
      const preview = getUrlParameterByName('preview')
      const mobile = getUrlParameterByName('mobile')
      await dispatch(fetchUserDataRequest(groom, preview, mobile))
    }

    defineUiStates()
    defineCustomTemplate()
    getUserData()
  }, [])

  useEffect(() => {
    const getMenuData = async () => {
      await dispatch(menuFetchData(user.profile.couple_id))
    }

    const getConfigData = async () => {
      await dispatch(getConfig(user.profile.couple_id))
    }

    if (user.profile) {
      getMenuData()
      getConfigData()
    }
  }, [user])

  return <>{user.loading || menu.loading ? '' : children}</>
}

Initializer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Initializer
