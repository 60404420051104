import styled, { css } from 'styled-components'

import { Content, ContentMobile } from '../../../../_settings/typography'

export const Wrapper = styled.section.attrs({
  className: 'resume',
})`
  padding: 0 15px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const Container = styled.div`
  max-width: 921px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

export const Header = styled.div.attrs({
  className: 'resume__header',
})`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 40px 0 21px 0;
    border-bottom: 1px solid
      ${theme.components.cart?.resumeDividerBorderColor
        ? theme.colors[theme.components.cart.resumeDividerBorderColor]
        : `rgba(96, 95, 99, 0.1)`};
    margin-bottom: 24px;

    > p {
      ${Content}
      color: ${theme.colors[theme.components.cart.resumeTextColor]};
      flex: 1;
      text-align: left;
      font-weight: bold;

      &:first-of-type {
        flex: 3;
      }

      &:last-of-type {
        text-align: right;
      }

      @media (max-width: 768px) {
        ${ContentMobile}
        color: ${theme.colors[theme.components.cart.resumeTextColor]};

        &:nth-of-type(2) {
          text-align: right;
        }

        &:last-of-type {
          display: none;
        }
      }
    }
  `}
`

export const Row = styled.div.attrs({
  className: 'resume__item',
})`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 26px;
    margin-bottom: 24px;
    border-bottom: 1px solid
      ${theme.components.cart?.resumeDividerBorderColor
        ? theme.colors[theme.components.cart.resumeDividerBorderColor]
        : `rgba(96, 95, 99, 0.1)`};

    > p {
      ${ContentMobile}
      color: ${theme.colors[theme.components.cart.resumeTextColor]};
      flex: 1;
      text-align: left;

      &:first-of-type {
        flex: 3;
      }

      &:last-of-type {
        text-align: right;
      }

      @media (max-width: 768px) {
        flex: 1;

        &:nth-of-type(2) {
          text-align: right;
        }

        &:last-of-type {
          min-width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 40px;

          &::before {
            content: 'Valor';
          }
        }
      }
    }
  `}
`

export const Footer = styled.div.attrs({
  className: 'resume__footer',
})`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--formBorder);
  margin-bottom: 24px;

  p {
    ${Content}
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
    font-weight: bold;
    flex: 1;
    text-align: left;

    &:first-of-type {
      flex: 3;
    }

    @media (max-width: 768px) {
      ${ContentMobile}
      color: ${({ theme }) =>
        theme.colors[theme.components.cart.resumeTextColor]};
      text-align: left;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    & > p:first-of-type {
      display: none;
    }
  }

  span {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    color: var(--content);

    p:not(:first-child) {
      font-weight: normal;
    }
  }
`
