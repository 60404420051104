import { useHistory, useParams } from 'react-router-dom'

import isDomain from './isDomain'

const HistoryHook = () => {
  const history = useHistory()
  const { login } = useParams()

  const customHistory = { ...history }

  customHistory.push = (path, state = {}) =>
    history.push(
      isDomain() ? path : `/${login}/${path.replace(/^\//, '')}`,
      state
    )

  customHistory.replace = (path, state = {}) =>
    history.replace(
      isDomain() ? path : `/${login}/${path.replace(/^\//, '')}`,
      state
    )

  return customHistory
}

export default HistoryHook
