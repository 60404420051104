import styled from 'styled-components'

import arrow from '../../../assets/img/arrow-large.svg'

export const Container = styled.div.attrs({
  className: 'gift-cards',
})`
  max-width: 948px;
  margin: 0 auto;

  @media (max-width: 1130px) {
    max-width: 100vw;
  }

  /* Init react-slick Style  */
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 41px;
    height: 41px;
  }

  .slick-prev {
    left: -93px;
    background-color: ${({ theme }) =>
      theme.colors[theme.components.cart.slickButtonColor]};
    mask-image: url(${arrow});
    mask-repeat: no-repeat;
    mask-position: center;
    transform: translate3d(0, -50%, 0) rotate(180deg);
  }

  .slick-next {
    right: -93px;
    background-color: ${({ theme }) =>
      theme.colors[theme.components.cart.slickButtonColor]};
    mask-image: url(${arrow});
    mask-repeat: no-repeat;
    mask-position: center;
  }

  /* End react-slick Style  */
`

export const LoadingItem = styled.div`
  margin: 0 6px;
  padding-top: 66%;
  background: #fafafa;
`
