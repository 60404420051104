import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'decorative-divider',
})`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Divider = styled.span.attrs({
  className: 'decarative-divider__icon',
})`
  display: flex;

  svg {
    path {
      fill: ${({ theme }) =>
        theme.colors[theme.components.decorativeDivider.color]};
    }
  }
`
