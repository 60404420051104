export const pageview = (url) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  }
}

const createEcommerceEvent = (event) => (props) => {
  if (window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event,
      payment_type: props.payment_type,
      ecommerce: {
        currency: 'BRL',
        value: props.value,
        payment_type: props.payment_type,
        transaction_id: props.transaction_id,
        items: props.items,
      },
    })
  }
}

export const viewItem = createEcommerceEvent('view_item')

export const addToCart = createEcommerceEvent('add_to_cart')

export const removeFromCart = createEcommerceEvent('remove_from_cart')

export const viewCart = createEcommerceEvent('view_cart')

export const beginCheckout = createEcommerceEvent('begin_checkout')

export const addPaymentInfo = createEcommerceEvent('add_payment_info')

export const purchase = createEcommerceEvent('purchase')
