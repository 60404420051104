import styled from 'styled-components'
import CloseIcon from './CloseIcon'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  @media (max-width: 415px) {
    align-items: flex-end;
  }

  &.modal-enter {
    opacity: 0;
  }

  &.modal-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.modal-exit {
    opacity: 1;
  }

  &.modal-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`

export const Overlay = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #00000078;
`

export const Content = styled.div`
  height: 60vh;
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: all 600ms;
  transform: translateY(100vh);

  &.modal-content__show {
    transform: translateY(0);
  }

  @media (max-width: 415px) {
    height: 80vh;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

export const Title = styled.h1`
  font-size: 20px;
  margin-right: 15px;
`

export const Close = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const Body = styled.div`
  flex: 1;
  overflow: hidden;
`
