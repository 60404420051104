import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

const BackToTop = () => {
  const [Skin, setSkin] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.backToTop.skin}/index.jsx`
      )
      setSkin(() => response.default)
    }

    getSkin()
  }, [theme])

  function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return Skin && <Skin handleClick={handleClick} content="Voltar ao topo" />
}

export default BackToTop
