import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const markups = {
  album: 'Album',
  bestmen: 'Bestmen',
  event: 'Event',
  list_in_store: 'ExternalStore',
  hero: 'Hero',
  image: 'Image',
  map: 'Map',
  messages: 'Messages',
  messages_home: 'MessagesHome',
  parallax_image: 'ParallaxImage',
  polls: 'Polls',
  recent_photos: 'RecentPhotos',
  rsvp: 'Rsvp',
  rsvp_home: 'RsvpHome',
  suppliers: 'Suppliers',
  text: 'Text',
  tips: 'Tips',
  video: 'Video',
  welcome: 'Welcome',
  store: 'Store',
  sasf: 'Sasf',
}

export default function Markups({
  isEven,
  type,
  content,
  data,
  afterMarkup,
  beforeMarkup,
  slug,
  attachedToHero,
}) {
  const [Markup, setMarkup] = useState(null)

  useEffect(() => {
    async function getMarkup() {
      const response = await import(`./${markups[type]}/index.jsx`)
      setMarkup(() => response.default)
    }

    getMarkup()
  }, [type])

  if (!content || !Markup) {
    return null
  }

  return (
    <Markup
      isWidget
      isEven={isEven}
      data={content}
      main={data}
      slug={slug}
      afterMarkup={afterMarkup}
      beforeMarkup={beforeMarkup}
      attachedToHero={attachedToHero}
    />
  )
}

Markups.propTypes = {
  isEven: PropTypes.bool,
  type: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  afterMarkup: PropTypes.string,
  slug: PropTypes.oneOfType([PropTypes.string, () => undefined]),
  attachedToHero: PropTypes.bool.isRequired,
}

Markups.defaultProps = {
  isEven: false,
  afterMarkup: null,
  slug: undefined,
  content: null,
}
