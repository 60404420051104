import styled, { css } from 'styled-components'

import { TitleXS } from '../../_settings/typography'

export const Wrapper = styled.div.attrs({
  className: 'subtitle',
})`
  width: 100%;
  min-height: 70px;
  height: 100%;
  padding: 20px 15px;

  @media (max-width: 768px) {
    min-height: 64px;
  }
`

export const Subtitle = styled.h2`
  ${TitleXS};
  ${({
    theme,
    theme: {
      components: { subtitle },
    },
  }) => css`
    font-family: ${theme.fonts[subtitle.fontFamily].family};
    font-style: ${subtitle.fontStyle};
    font-size: ${subtitle.fontSize ? `${subtitle.fontSize}px` : ''};
    ${!!subtitle.letterSpacing &&
    css`
      letter-spacing: ${subtitle.letterSpacing};
    `}

    ${!!subtitle.lineHeight &&
    css`
      line-height: ${subtitle.lineHeight}px;

      ${!!subtitle.lineHeightWithoutPixel &&
      css`
        line-height: ${subtitle.lineHeight};
      `};
    `}

    color: ${theme.colors[subtitle.textColor]};
    text-transform: ${subtitle.textTransform};

    @media (max-width: 768px) {
      ${!!subtitle.lineHeightMobile &&
      css`
        line-height: ${subtitle.lineHeightMobile}px;

        ${!!subtitle.lineHeightWithoutPixel &&
        css`
          line-height: ${subtitle.lineHeightMobile};
        `};
      `}

      ${!!subtitle.letterSpacingMobile &&
      css`
        letter-spacing: ${subtitle.letterSpacingMobile};
      `}
    }
  `};

  max-width: 1110px;
  margin: 0 auto;
`
