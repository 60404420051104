import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'music-player',
})`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #3d3d3d;
  z-index: 10;

  & > svg {
    margin: 0 25px;
  }
`
