import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

export default function CardItem({ selected, image, onSelect, onPreview }) {
  const [Skin, setSkin] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.cardItem.skin}/index.jsx`
      );
      setSkin(() => response.default);
    }

    getSkin();
  }, [theme]);

  return (
    Skin && (
      <Skin
        selected={selected}
        image={image}
        onSelect={onSelect}
        onPreview={onPreview}
      />
    )
  );
}

CardItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
};
