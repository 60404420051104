import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  LoadingIcon,
  MusicIcon,
  CloseIcon,
  VolumeIcon,
  MutedIcon,
  PrevIcon,
  NextIcon,
  PlayIcon,
  PauseIcon,
} from './icons';

import * as S from './styles';

export default function Player80s({
  audioState,
  volume,
  muted,
  progress,
  duration,
  currentTime,
  timeFormatted,
  title,
  artist,
  musicTotal,
  musicSelected,
  loading,
  onPlay,
  onPause,
  onNext,
  onPrev,
  onChangeVolume,
  onToggleMuted,
  onChangeProgress,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const paused = useMemo(() => audioState !== 'playing', [audioState]);

  return (
    <Fragment>
      <S.ButtonToggle
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        title={isOpen ? 'Fechar player de música' : 'Abrir player de música'}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-controls="player"
      >
        <MusicIcon />
      </S.ButtonToggle>

      <S.Player id="player" isOpen={isOpen} aria-hidden={!isOpen}>
        <S.ButtonClose
          type="button"
          onClick={() => setIsOpen(false)}
          title="Fechar player de música"
          tabIndex={isOpen ? 0 : -1}
        >
          <CloseIcon />
        </S.ButtonClose>

        <S.Info>
          <S.Cover>
            <MusicIcon />
          </S.Cover>

          <S.MusicInfo>
            <S.MusicArtist>{artist}</S.MusicArtist>
            <S.MusicTitle>{title}</S.MusicTitle>
            <S.MusicTracks>
              {musicSelected}/{musicTotal}
            </S.MusicTracks>
          </S.MusicInfo>
        </S.Info>

        <S.Config>
          <S.ProgressWrapper>
            <S.Progress>
              <S.ProgressSlider progress={progress} isLoading={loading} />
              <S.ProgressRange
                min={0}
                max={duration}
                onChange={onChangeProgress}
                value={currentTime}
                aria-label="Progresso da música"
              />
            </S.Progress>
          </S.ProgressWrapper>

          <S.VolumeWrapper>
            <S.ButtonMute
              type="button"
              title={muted ? 'Ativar som' : 'Desativar som'}
              tabIndex={isOpen ? 0 : -1}
              onClick={onToggleMuted}
            >
              {muted ? <MutedIcon /> : <VolumeIcon />}
            </S.ButtonMute>

            <S.Volume>
              <S.VolumeSlider volume={volume} muted={muted} />
              <S.VolumeRange
                min={0}
                max={100}
                value={volume}
                onChange={onChangeVolume}
                aria-label="Volume"
              />
            </S.Volume>
          </S.VolumeWrapper>
        </S.Config>

        <S.Time>{timeFormatted}</S.Time>

        <S.Controls>
          <S.NextPrev
            title="Música anterior"
            onClick={onPrev}
            tabIndex={isOpen ? 0 : -1}
          >
            <PrevIcon />
          </S.NextPrev>

          {loading ? (
            <S.LoadingButton disabled title="Carregando...">
              <LoadingIcon />
            </S.LoadingButton>
          ) : (
            <S.TogglePlay
              title={paused ? 'Tocar música' : 'Pausar música'}
              onClick={paused ? onPlay : onPause}
              tabIndex={isOpen ? 0 : -1}
            >
              {paused ? <PlayIcon /> : <PauseIcon />}
            </S.TogglePlay>
          )}

          <S.NextPrev
            title="Próxima música"
            onClick={onNext}
            tabIndex={isOpen ? 0 : -1}
          >
            <NextIcon />
          </S.NextPrev>
        </S.Controls>
      </S.Player>
    </Fragment>
  );
}

Player80s.propTypes = {
  audioState: PropTypes.string,
  volume: PropTypes.number,
  muted: PropTypes.bool,
  progress: PropTypes.number,
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  timeFormatted: PropTypes.string,
  title: PropTypes.string,
  artist: PropTypes.string,
  musicTotal: PropTypes.number,
  musicSelected: PropTypes.number,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onChangeVolume: PropTypes.func,
  onToggleMuted: PropTypes.func,
  onChangeProgress: PropTypes.func,
};

Player80s.defaultProps = {
  audioState: 'stopped',
  volume: 100,
  muted: false,
  progress: 0,
  duration: 0,
  currentTime: 0,
  timeFormatted: '00:00 / 00:00',
  title: '',
  artist: '',
  musicTotal: 0,
  musicSelected: 0,
  loading: false,
  onPlay: () => {},
  onPause: () => {},
  onNext: () => {},
  onPrev: () => {},
  onChangeVolume: () => {},
  onToggleMuted: () => {},
  onChangeProgress: () => {},
};
