import styled, { css } from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'photo-wrapper',
})`
  position: relative;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
`

export const Photo = styled.figure.attrs({
  className: 'photo-wrapper__photo',
})`
  ${(props) => props.proportion === '1:1' && 'padding-top: 100%;'}
  ${(props) => props.proportion === '4:3' && 'padding-top: 75%;'}
  ${(props) => props.proportion === '16:9' && 'padding-top: 56.25%;'}

  background-image: url(${(props) => props.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: ${(props) => props.backgroundSize};

  @media (max-width: 768px) {
    ${(props) =>
      props.urlMobile &&
      css`
        background-image: url(${props.urlMobile});
      `}
  }
`
