import produce from 'immer'

const INITIAL_STATE = {
  languages: [
    {
      country: 'br',
      language: 'Português',
      title: 'Brasil',
      route: 'pt_br',
      htmlTag: 'pt-BR',
    },
    {
      country: 'us',
      language: 'Inglês',
      title: 'Estados Unidos',
      route: 'en',
      htmlTag: 'en',
    },
    {
      country: 'es',
      language: 'Espanhol',
      title: 'Espanha',
      route: 'es',
      htmlTag: 'es',
    },
    {
      country: 'fr',
      language: 'Francês',
      title: 'França',
      route: 'fr',
      htmlTag: 'fr',
    },
    {
      country: 'de',
      language: 'Alemão',
      title: 'Alemanha',
      route: 'de',
      htmlTag: 'de',
    },
  ],
  language: {
    country: 'br',
    language: 'Português',
    title: 'Brasil',
    route: 'pt_br',
    htmlTag: 'pt-BR',
  },
}

export default function language(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@language/SET_ROUTE_LANGUAGE': {
        const selectedItem = state.languages.filter(
          (lng) => lng.route === action.payload.language
        )[0]

        draft.language = selectedItem
        break
      }
      case '@language/SET_LANGUAGE': {
        draft.language = action.payload.language
        break
      }
      default:
        return state
    }
  })
}
