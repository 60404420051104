import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input'
import ptBR from 'react-phone-number-input/locale/pt-BR.json'
import flags from './flags'

export const countries = getCountries()
  .map((country) => ({
    id: country,
    name: ptBR[country],
    callingCode: getCountryCallingCode(country),
    flag: flags[country],
  }))
  .sort((a, b) => a.name.localeCompare(b.name))

export function getCountryById(id) {
  return countries.find((c) => c.id == id)
}
