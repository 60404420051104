const luhnCheck = (value) => {
  let nCheck = 0
  let bEven = false

  // eslint-disable-next-line
  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n)
    let nDigit = parseInt(cDigit, 10)

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9; // eslint-disable-line

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

export default luhnCheck
