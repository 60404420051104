import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

const Dialog = ({ content, contentHtml, topAction, bottomAction }) => {
  const [Skin, setSkin] = useState(null)
  const [active, setActive] = useState(true)
  const dialogRef = useRef()
  const theme = useTheme()

  const handleClose = (type) => {
    if (type === 'top') {
      topAction.action()
    } else {
      bottomAction.action()
    }

    window.onkeypress = null
    setActive(false)
  }

  useEffect(() => {
    window.onkeypress = (event) => {
      event.preventDefault()
    }

    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.dialog.skin}/index.jsx`
      )

      setSkin(() => response.default)
    }

    getSkin()
  }, [theme])

  return (
    Skin && (
      <Skin
        active={active}
        dialogRef={dialogRef}
        content={content}
        contentHtml={contentHtml}
        handleClose={handleClose}
        topAction={topAction}
        bottomAction={bottomAction}
      />
    )
  )
}

Dialog.defaultProps = {
  bottomAction: null,
  content: '',
  contentHtml: '',
}

Dialog.propTypes = {
  content: PropTypes.string,
  contentHtml: PropTypes.string,
  topAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  bottomAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
}

export default Dialog
