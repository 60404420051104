export function setRouteLanguage(language) {
  return {
    type: '@language/SET_ROUTE_LANGUAGE',
    payload: { language },
  }
}

export function setLanguage(language) {
  return {
    type: '@language/SET_LANGUAGE',
    payload: { language },
  }
}
