import styled, { css } from 'styled-components'

const containerModifiers = {
  hasTop: (top) => css`
    top: ${top};
    transform: ${top !== '0' && 'translateY(-50%)'};
  `,
  desktopInfiniteBackgroundSize: () => css`
    @media (min-width: 1281px) {
      && {
        background-size: 100% 100%;
      }
    }
  `,
}

export const Container = styled.div`
  ${({
    zIndex,
    width,
    height,
    left,
    right,
    top,
    bottom,
    transform,
    maskImage,
    background,
    backgroundSize,
    backgroundImage,
    backgroundPosition,
    desktopInfiniteBackgroundSize,
    tablet,
    mobile,
  }) => css`
    position: absolute;
    width: ${width};
    height: ${height};
    pointer-events: none;
    z-index: ${zIndex};

    left: ${left};
    right: ${right};
    ${!!top && containerModifiers.hasTop(top)};
    transform: ${transform};
    bottom: ${bottom};
    background: ${background};
    background-size: ${backgroundSize};
    background-position: ${backgroundPosition};
    background-repeat: no-repeat;

    ${maskImage
      ? css`
          mask-image: url(${backgroundImage});
        `
      : css`
          background-image: url(${backgroundImage});
        `};

    ${desktopInfiniteBackgroundSize &&
    containerModifiers.desktopInfiniteBackgroundSize()};

    @media (max-width: 768px) {
      width: ${tablet.width};
      height: ${tablet.height};

      left: ${tablet.left};
      top: ${tablet.top};
      right: ${tablet.right};
      bottom: ${tablet.bottom};

      transform: ${tablet.transform};

      background: ${tablet.background};
      background-size: ${tablet.backgroundSize};
      background-position: ${tablet.backgroundPosition};

      ${tablet.backgroundImage &&
      css`
        ${maskImage
          ? css`
              mask-image: url(${backgroundImage});
            `
          : css`
              background-image: url(${backgroundImage});
            `};
      `};
    }

    @media (max-width: 544px) {
      width: ${mobile.width};
      height: ${mobile.height};

      left: ${mobile.left};
      top: ${mobile.top};
      right: ${mobile.right};
      bottom: ${mobile.bottom};

      transform: ${mobile.transform};

      background: ${mobile.background};
      background-size: ${mobile.backgroundSize};
      background-position: ${mobile.backgroundPosition};

      ${mobile.backgroundImage &&
      css`
        ${maskImage
          ? css`
              mask-image: url(${backgroundImage});
            `
          : css`
              background-image: url(${backgroundImage});
            `};
      `};
    }
  `};
`
