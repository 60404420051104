import styled, { css } from 'styled-components'

import { ReactComponent as WhatsappIcon } from '../../../assets/img/whatsapp.svg'
import { ContentMobile } from '../../_settings/typography'
import addPixelUnitToNumber from '../../../utils/addPixelUnitToNumber'

export const Wrapper = styled.div.attrs({
  className: 'whatsapp',
})`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const buttonModifiers = {
  buttonBorderColor: (theme, color) => css`
    border-color: ${theme.colors[color]};
  `,
  buttonTextColor: (theme, color) => css`
    color: ${theme.colors[color]};
  `,
  buttonFontWeight: (theme, weight) => css`
    && {
      font-weight: ${weight};
    }
  `,
  buttonFontFamily: (theme, font) => css`
    font-family: ${theme.fonts[font].family};
  `,
  buttonFontSize: (theme, size) => css`
    @media (max-width: 768px) {
      font-size: ${addPixelUnitToNumber(size)};
    }
  `,
  buttonboxShadow: (boxShadow) => css`
    box-shadow: ${boxShadow};
  `,
}

export const Button = styled.button.attrs({
  className: 'whatsapp__button',
})`
  ${({ theme }) => css`
    margin: 0 15px;
    border: 1px solid var(--main);
    border-radius: ${theme.components.shareWhatsapp.buttonBorderRadius};
    padding: 14px 36px;
    background: transparent;
    width: calc(100% - 30px);
    position: relative;

    ${ContentMobile};
    color: var(--main);

    ${!!theme.components.shareWhatsapp.boxShadow &&
    buttonModifiers.buttonboxShadow(theme.components.shareWhatsapp.boxShadow)};

    ${!!theme.components.shareWhatsapp?.buttonBorderColor &&
    buttonModifiers.buttonBorderColor(
      theme,
      theme.components.shareWhatsapp.buttonBorderColor
    )};

    ${!!theme.components.shareWhatsapp?.buttonTextColor &&
    buttonModifiers.buttonTextColor(
      theme,
      theme.components.shareWhatsapp.buttonTextColor
    )};

    ${!!theme.components.shareWhatsapp?.buttonFontSize &&
    buttonModifiers.buttonFontSize(
      theme,
      theme.components.shareWhatsapp.buttonFontSize
    )};

    ${!!theme.components.shareWhatsapp?.fontWeight &&
    buttonModifiers.buttonFontWeight(
      theme,
      theme.components.shareWhatsapp.fontWeight
    )};

    ${!!theme.components.shareWhatsapp?.fontFamily &&
    buttonModifiers.buttonFontFamily(
      theme,
      theme.components.shareWhatsapp.fontFamily
    )};
  `}
`

const iconModifiers = {
  iconColor: (theme, color) => css`
    path {
      fill: ${theme.colors[color]};
    }
  `,
}

export const Icon = styled(WhatsappIcon)`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    path {
      fill: var(--main);
    }

    ${!!theme.components.shareWhatsapp?.iconColor &&
    iconModifiers.iconColor(theme, theme.components.shareWhatsapp.iconColor)};
  `}
`
