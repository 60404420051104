import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import EventIcon from '../../molecules/Collapse/EventIcon'
import ArrowRightIcon from './ArrowRightIcon'
import Collapse from '../../molecules/Collapse'
import * as S from './styles'

const ListRsvp = ({ events }) => {
  const history = useHistory()
  const theme = useTheme()

  const openSinglePage = (link) => {
    history.push(link)
  }

  console.log(events)

  return (
    <S.Container>
      <S.CollapseContainer>
        {events.map((event) => {
          const singlePage = event.pages.length === 1 ? event.pages[0] : null
          return (
            <S.Collapse key={event.id}>
              <Collapse
                icon={<EventIcon color={theme.colors.main} />}
                label={event.name}
                hideRightIcon={!!singlePage}
                onClick={
                  singlePage ? () => openSinglePage(singlePage.path) : null
                }
              >
                {!singlePage && (
                  <S.CollapseBody>
                    <p>
                      Encontramos {event.pages.length} páginas com esse evento:
                    </p>
                    <ul>
                      {event.pages.map((page) => (
                        <li key={page.id}>
                          <Link to={page.path}>
                            <ArrowRightIcon color={theme.colors.main} />{' '}
                            {page.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </S.CollapseBody>
                )}
              </Collapse>
            </S.Collapse>
          )
        })}
      </S.CollapseContainer>
    </S.Container>
  )
}

ListRsvp.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
}

export default ListRsvp
