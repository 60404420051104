import contrast from 'get-contrast'

const getTextColor = (backgroundColor, lightColor, darkColor, offset = 1) => {
  try {
    return contrast.ratio(backgroundColor, lightColor) <
      contrast.ratio(backgroundColor, darkColor) * offset
      ? darkColor
      : lightColor
  } catch (error) {
    return darkColor
  }
}
export default getTextColor
