import styled, { css } from 'styled-components'

import chevronDown from './assets/chevron-down.png'
import chevronUp from './assets/chevron-up.png'
import volumeIcon from './assets/volume-icon.png'

function getColorBySkin(skin) {
  switch (skin) {
    case 'skin1':
      return '#212121'
    case 'skin2':
      return '#E91E63'
    case 'skin3':
      return '#009688'
    case 'skin4':
      return '#03A9F4'
    case 'skin5':
      return '#03A9F4'
    default:
      return '#212121'
  }
}

export const Wrraper = styled.div``

export const ShowButton = styled.button`
  background-color: ${(props) => getColorBySkin(props.skin)};
  background-image: ${(props) =>
    props.show ? `url(${chevronDown})` : `url(${chevronUp})`};
  background-position: center center;
  background-repeat: no-repeat;

  width: 40px;
  height: 40px;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 60px;
  bottom: ${(props) => (props.show ? '60px' : '0')};
  transition: bottom 0.5s;
  z-index: 10;
`

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  width: 100%;
  height: 60px;
  color: #ffffff;
  background: ${(props) => getColorBySkin(props.skin)};
  position: fixed;
  bottom: ${(props) => (props.show ? 0 : '-60px')};
  transition: bottom 0.5s;
  padding: 0 15px;
  z-index: 10;
`

export const PlayerContainer = styled.div`
  max-width: 1110px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

export const Controls = styled.div.attrs({
  className: 'music-player__controls',
})`
  width: 100px;

  display: flex;
  justify-content: space-between;
`

export const Prev = styled.button.attrs({
  className: 'music-player__previous',
})`
  background: none;
  border: none;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #fff;
  }

  &::after {
    content: '';
    display: inline-block;
    margin-left: 7px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #fff;
  }
`

export const Play = styled.button.attrs({
  className: 'music-player__play',
})`
  background: none;
  border: none;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 18px solid #fff;
  }
`

export const Pause = styled.button.attrs({
  className: 'music-player__pause',
})`
  background: none;
  height: 20px;
  width: 15px;
  border-right: 5px solid #fff;
  border-bottom: 0 solid transparent;
  border-left: 5px solid #fff;
  border-top: 0 solid transparent;
`

export const Next = styled.button.attrs({
  className: 'music-player__next',
})`
  background: none;
  border: none;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #fff;
  }

  &::after {
    content: '';
    display: inline-block;
    margin-left: 7px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #fff;
  }
`

export const Volume = styled.div.attrs({
  className: 'music-player__volume',
})`
  width: 180px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const VolumeIcon = styled.div`
  background: url(${volumeIcon}) no-repeat center center;
  width: 15px;
  height: 15px;
  margin-right: 10px;
`

export const VolumeWrrapper = styled.div`
  position: relative;
  width: 80px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  height: 4px;
  transition: 0.3s;

  :hover {
    height: 8px;
  }
`

export const VolumeSlider = styled.div.attrs((props) => ({
  style: {
    width: `${props.volume}%`,
  },
}))`
  position: absolute;
  height: 100%;
  background-color: #fff;
`

export const VolumeRange = styled.input.attrs({
  type: 'range',
})`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`

export const Info = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`

export const ArtistCover = styled.img.attrs({
  className: 'music-player__cover',
})`
  width: 40px;
  height: 40px;
`

export const InfoRight = styled.div`
  flex: 1;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
  text-align: left;
`

export const InfoText = styled.p`
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  display: inline-block;
  margin-right: 15px;

  @media (max-width: 768px) {
    ${(props) =>
      props.hiddenMobile &&
      css`
        display: none;
      `}
  }
`

export const Progress = styled.div.attrs({
  className: 'music-player__progress',
})`
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
`

export const ProgressBar = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  height: 100%;
  background: #fff;
`
