/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const Previous = (props) => (
  <svg
    width={20}
    height={13}
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.637 6.235L18.818.918C19.5.532 20 .833 20 1.604v10.292c0 .771-.5 1.072-1.182.686l-8.181-5.317s-.319-.215-.319-.515c0-.3.319-.515.319-.515zm-10.319 0l8.5-5.317C9.455.532 10 .833 10 1.604v10.292c0 .771-.545 1.072-1.182.686l-8.5-5.317S0 7.05 0 6.75c0-.3.318-.515.318-.515z"
      fill="#828E93"
    />
  </svg>
);

export const Next = (props) => (
  <svg
    width={21}
    height={13}
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.58 6.75c0 .3-.319.515-.319.515l-8.182 5.317c-.682.386-1.182.085-1.182-.686V1.604c0-.771.5-1.072 1.182-.686l8.182 5.317s.319.215.319.515zm10.318 0c0 .3-.318.515-.318.515l-8.5 5.317c-.637.386-1.182.085-1.182-.686V1.604c0-.771.545-1.072 1.182-.686l8.5 5.317s.318.215.318.515z"
      fill="#828E93"
    />
  </svg>
);

export const Play = (props) => (
  <svg
    width={19}
    height={23}
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.899 11.75c0 .586-.575.953-.575.953l-15.41 9.681c-1.152.807-2.016.22-2.016-1.174V2.29c0-1.393.863-1.98 2.016-1.174l15.41 9.681c0-.001.575.439.575.953z"
      fill="#fff"
    />
  </svg>
);

export const Pause = (props) => (
  <svg
    width={15}
    height={23}
    viewBox="0 0 15 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.132 21.162c0 .876-.948 1.588-2.117 1.588S.899 22.038.899 21.162V2.339c0-.878.947-1.589 2.116-1.589s2.117.711 2.117 1.589v18.823zM14.899 21.162c0 .876-.948 1.588-2.116 1.588-1.17 0-2.117-.712-2.117-1.588V2.339c0-.878.947-1.589 2.117-1.589 1.168 0 2.116.711 2.116 1.589v18.823z"
      fill="#fff"
    />
  </svg>
);
