import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../../services/api'
import { saveConfig, updateProductsValues } from './actions'

export function* fetchCartConfig({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `/api/v1/store/config?couple_id=${payload.coupleId}`
    )

    yield put(saveConfig(data.config))
  } catch (error) {
    console.log(error)
  }
}

export function* updateProductsValuesSaga({ payload }) {
  if (!payload.coupleId || !payload.products) {
    return
  }

  const productsByStore = payload.products
    .map((p) => ({
      productId: p.id,
      storeId: p.id_template_store,
    }))
    .reduce((result, product) => {
      if (!result[product.storeId]) {
        result[product.storeId] = []
      }

      result[product.storeId].push(product.productId)

      return result
    }, {})

  const get = function* (storeId, productsIds) {
    const { data } = yield call(api.post, `api/v1/store/${storeId}/products`, {
      ids: productsIds,
      couple_id: payload.coupleId,
    })
    return data.products
  }

  let products = []
  for (let storeId of Object.keys(productsByStore)) {
    const updatedProducts = yield* get(storeId, productsByStore[storeId])
    products = [...products, ...updatedProducts]
  }

  yield put(updateProductsValues({ products }))
}

export default all([
  takeLatest('@cart/FETCH_CONFIG', fetchCartConfig),
  takeLatest('@cart/UPDATE_PRODUCTS_VALUES', updateProductsValuesSaga),
])
