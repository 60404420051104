import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import * as gtm from '../../../lib/gtm';
import api from '../../../services/api';
import { addCard } from '../../../store/modules/cart/actions';
import shuffleArray from '../../../utils/shuffleArray';

import CardItem from '../../molecules/CardItem';
import CardDialog from '../../molecules/CardDialog';

import * as S from './styles';

export default function CardSlider() {
  const [dialogImage, setDialogImage] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const cardSelectedId = useSelector((state) =>
    state.cart.card ? state.cart.card.id : null
  );

  function dispatchGtmAddToCart(card) {
    gtm.addToCart({
      value: card.price,
      items: [
        {
          item_id: String(card.id),
          item_name: 'Cartão iCasei',
          currency: 'BRL',
          item_category: 'Cartão impresso',
          item_category2: 'Site do casal',
          price: card.price,
          item_variant: card.description,
        },
      ],
    });
  }

  useEffect(() => {
    async function getCards() {
      const response = await api.get('api/v1/store/cards');
      let cardsShuffled = shuffleArray(response.data.cards);

      const finalCards = cardsShuffled.filter((card) =>
        [26, 27, 28, 29, 30, 31, 32].includes(card.id)
      );

      const restOfShuffledCards = cardsShuffled.filter(
        (card) => ![26, 27, 28, 29, 30, 31, 32].includes(card.id)
      );

      cardsShuffled = [...restOfShuffledCards, ...finalCards];

      if (cardSelectedId) {
        const selectedCard = cardsShuffled.find((card) => {
          return card.id === cardSelectedId;
        });

        cardsShuffled = cardsShuffled.filter(
          (card) => card.id !== cardSelectedId
        );

        cardsShuffled.unshift(selectedCard);
      }

      const firstCard = cardsShuffled[0];
      setCards(cardsShuffled);
      dispatch(addCard(firstCard));
      dispatchGtmAddToCart(firstCard);
      setLoading(false);
    }

    getCards();
  }, []);

  function handleSelect(card) {
    dispatch(addCard(card));
    dispatchGtmAddToCart(card);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function handlePreview(card) {
    setDialogImage(card.image_preview);
    setDialogDescription(card.description);
    setShowModal(true);
  }

  const settings = {
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 948,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };

  if (loading) {
    return (
      <S.Container>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          {[1, 2, 3, 4, 5].map((item) => (
            <div key={item}>
              <S.LoadingItem />
            </div>
          ))}
        </Slider>
      </S.Container>
    );
  }

  return (
    <S.Container>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider {...settings}>
        {cards.map((card) => (
          <CardItem
            key={String(card.id)}
            image={card.image}
            selected={cardSelectedId === card.id}
            onSelect={() => handleSelect(card)}
            onPreview={() => handlePreview(card)}
          />
        ))}
      </Slider>
      <CardDialog
        image={dialogImage}
        description={dialogDescription}
        show={showModal}
        onClose={handleModalClose}
      />
    </S.Container>
  );
}
