import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 80px 15px;
  background: var(--lightBackground);
`

export const Items = styled.div`
  display: grid;
  gap: 16px;
  margin: 0 auto;
  max-width: 350px;
`

export const StoreInfo = styled.div``

export const CollapseBody = styled.div`
  padding: 16px 16px 16px 24px;

  p {
    opacity: 0.5;
    margin-bottom: 20px;
    font-family: var(--base-font-family);
  }

  ul {
    li {
      a {
        display: flex;
        margin: 10px 0;
        padding: 10px 0;
        font-family: var(--base-font-family);
        color: var(--content);
        text-decoration: none;
        font-size: 14px;

        @media (max-width: 768px) {
          font-size: 13px;
        }

        svg {
          transition: all 500ms;
          margin-right: 12px;
        }

        &:hover {
          svg {
            transform: scale(1.2) translateX(4px);
          }
        }
      }
    }
  }
`
