/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const LoadingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto',
    }}
    width={30}
    height={30}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    display="block"
    {...props}
  >
    <circle
      cx={50}
      cy={50}
      fill="none"
      stroke="#348cc1"
      strokeWidth={8}
      r={40}
      strokeDasharray="188.49555921538757 64.83185307179586"
      transform="rotate(29.942 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export const MusicIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.982 5.48L17.966 5 8.227 6.655v8.461a4.055 4.055 0 00-1.329-.227C5.296 14.89 4 15.811 4 16.945 4 18.078 5.301 19 6.896 19c1.597 0 2.897-.922 2.897-2.055 0-.103-.026-5.9-.03-7.858l6.65-1.13v5.653a4.038 4.038 0 00-1.323-.227c-1.6 0-2.894.922-2.894 2.056 0 1.133 1.302 2.055 2.898 2.055 1.601 0 2.906-.922 2.906-2.055l-.018-9.959z"
      fill="currentColor"
    />
  </svg>
);

export const CoverNullIcon = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm5.968 13l-9.043 1.655v8.461a3.522 3.522 0 00-1.234-.227c-1.487 0-2.691.922-2.691 2.056C13 26.078 14.208 27 15.69 27s2.69-.922 2.69-2.055v-.057l-.001-.059v-.171c-.003-.62-.01-2.151-.015-3.726l-.002-.525c-.005-1.311-.01-2.585-.011-3.32l6.175-1.13v5.654a3.506 3.506 0 00-1.228-.227c-1.486 0-2.688.922-2.688 2.056 0 1.133 1.21 2.055 2.691 2.055 1.487 0 2.699-.922 2.699-2.055v-.578l-.001-.161v-.37l-.001-.206-.001-.453-.011-6.477v-.448l-.001-.205v-.363l-.001-.158-.001-.444v-.096L25.968 13z"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1l16 16m0-16L1 17" stroke="currentColor" />
  </svg>
);

export const VolumeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.586 10.522c-.067-.303.15-.597.488-.658.982-.182 1.685-.96 1.682-1.862.04-.906-.67-1.7-1.662-1.863-.339-.05-.57-.336-.514-.64.054-.305.373-.513.713-.463h.007c1.587.26 2.731 1.517 2.7 2.966.003 1.434-1.12 2.67-2.683 2.957a.579.579 0 01-.122.012c-.296 0-.55-.188-.61-.448l.001-.001zM10.021 15.9l-6.078-4.495H1.915c-1.038 0-1.883-.688-1.883-1.534L0 6.117c0-.85.845-1.537 1.882-1.537h2.03l6.11-4.483a.635.635 0 01.845.154.548.548 0 01.104.319v14.86c0 .313-.271.569-.608.57a.643.643 0 01-.341-.098l-.001-.001z"
      fill="currentColor"
    />
  </svg>
);

export const MutedIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.074 9.865c-.338.06-.555.355-.488.657v.001c.059.26.313.449.61.449a.579.579 0 00.121-.012c1.563-.288 2.686-1.523 2.682-2.958.032-1.448-1.112-2.705-2.7-2.965h-.006c-.34-.05-.659.157-.713.462-.056.305.175.591.514.64.992.163 1.702.958 1.662 1.863.003.902-.7 1.68-1.682 1.863zM5.13 12.284l4.891 3.617v.001c.101.064.22.098.341.098.338 0 .61-.256.61-.57V6.443L5.13 12.284zm-1.95-.878H1.914c-1.038 0-1.881-.688-1.881-1.533L0 6.117c0-.85.845-1.537 1.882-1.537h2.03l6.11-4.482a.635.635 0 01.845.154.548.548 0 01.104.318v3.044l-.678.679-7 7-.113.113z"
        fill="currentColor"
      />
      <path d="M.5 15.5l15-15" stroke="currentColor" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const PrevIcon = (props) => (
  <svg
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.175 11.694c.098.18.231.332.39.443l13.954 9.667c.559.385 1.288.185 1.628-.448.114-.212.173-.453.173-.7v-8.469l13.88 9.617c.558.386 1.287.186 1.627-.448a1.49 1.49 0 00.173-.7V1.328C31.992.586 31.456-.009 30.801 0a1.084 1.084 0 00-.578.18L16.319 9.797V1.328C16.312.586 15.775-.009 15.121 0a1.09 1.09 0 00-.579.18L.566 9.847c-.558.388-.733 1.215-.391 1.847z"
      fill="currentColor"
    />
  </svg>
);

export const NextIcon = (props) => (
  <svg
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.825 10.306a1.288 1.288 0 00-.39-.443L17.481.196c-.559-.385-1.288-.185-1.628.448a1.478 1.478 0 00-.173.7v8.469L1.8.196C1.242-.19.513.01.173.644a1.49 1.49 0 00-.173.7v19.328c.008.742.544 1.337 1.199 1.328.203-.003.402-.065.578-.18l13.904-9.617v8.469c.007.742.544 1.337 1.198 1.328a1.09 1.09 0 00.579-.18l13.976-9.667c.558-.388.733-1.215.391-1.847z"
      fill="currentColor"
    />
  </svg>
);

export const PlayIcon = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.5 20c0 10.77-8.73 19.5-19.5 19.5S.5 30.77.5 20 9.23.5 20 .5 39.5 9.23 39.5 20z"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 26.03a.832.832 0 001.245.715l9.788-6.027a.84.84 0 000-1.427l-9.772-6.026a.833.833 0 00-1.261.716V26.03z"
      fill="currentColor"
    />
  </svg>
);

export const PauseIcon = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.5 20c0 10.77-8.73 19.5-19.5 19.5S.5 30.77.5 20 9.23.5 20 .5 39.5 9.23 39.5 20z"
      stroke="currentColor"
    />
    <path
      d="M18.286 15.143a2 2 0 10-4 0v9.714a2 2 0 004 0v-9.714zM25.714 15.143a2 2 0 10-4 0v9.714a2 2 0 004 0v-9.714z"
      fill="currentColor"
    />
  </svg>
);
