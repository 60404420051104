import styled, { css } from 'styled-components'

const wrapperModifiers = {
  additionalMarginTop: () => css`
    margin-top: 100px;
    @media (max-width: 544px) {
      margin-top: 66px;
    }
  `,
}

export const Wrapper = styled.div.attrs({
  className: 'cart',
})`
  ${({ additionalMarginTop }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${!!additionalMarginTop && wrapperModifiers.additionalMarginTop()}
  `}
`

export const FixedTop = styled.div`
  order: -2;
  width: 100%;
`

export const ControlsWrapper = styled.div.attrs({
  className: 'cart__controls',
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 950px;
  padding: 0 15px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    & > button {
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
`
