import styled, { css, keyframes } from 'styled-components'
import { darken } from 'polished'

import getTextColor from '../../../utils/getTextColor'

import { Content } from '../../_settings/typography'

const buttonModifiers = {
  customTextColor: (theme) => css`
    color: ${theme.colors[theme.components.button.textColor]};

    &:hover {
      color: ${theme.colors[theme.components.button.textColor]};
    }
  `,
  darkenOnHover: (theme) => css`
    &&:hover {
      color: ${theme.colors.body};
      background: ${darken(0.1, theme.colors.main)};
      filter: none;
    }
  `,
  customFontWeight: (theme) => css`
    && {
      font-weight: ${theme.components.button.fontWeight};
    }
  `,
}

const styles = ({
  colors,
  components: {
    button: {
      background,
      contrastOffset,
      secondaryProps: {
        textColor,
        borderColor,
        hover: {
          textColor: secondaryHoverTextColor,
          background: secondaryHoverBackgroundColor,
        },
      },
    },
  },
}) => ({
  primary: {
    fontWeight: background ? '' : 'bold',
    color: background
      ? getTextColor(colors.main, colors.body, colors.content, contrastOffset)
      : 'var(--main)',
    background: background ? 'var(--main)' : 'transparent',
    border: background ? '' : '2px solid var(--main)',
    hover: {
      background: 'var(--main)',
      color: getTextColor(
        colors.main,
        colors.body,
        colors.content,
        contrastOffset
      ),
      filter: background ? 'brightness(80%)' : '',
      border: background ? '' : '2px solid var(--main)',
    },
  },
  secondary: {
    fontWeight: '',
    color: colors[textColor],
    background: 'transparent',
    border: `1px solid ${colors[borderColor]}`,
    hover: {
      background: colors[secondaryHoverBackgroundColor],
      color: colors[secondaryHoverTextColor],
      filter: '',
      border: `1px solid transparent`,
    },
  },
  inverted: {
    fontWeight: '',
    color: 'var(--content)',
    background: 'var(--body)',
    border: '',
    hover: {
      background: 'var(--body)',
      color: 'var(--content)',
      filter: '',
      border: '',
    },
  },
})

export const Button = styled.button.attrs(({ variant }) => ({
  className: ['button', `button--${variant}`],
}))`
  ${({ theme, variant }) => css`
    ${Content};
    font-weight: ${styles(theme)[variant].fontWeight};
    color: ${styles(theme)[variant].color};
    text-transform: ${theme.components.button.textTransform};
    padding: 12px 36px;
    width: fit-content;
    transition: all 300ms ease-in-out;
    background: ${styles(theme)[variant].background};
    border: ${styles(theme)[variant].border};

    border-radius: ${theme.components.button.rounded ? '4px' : '0'};

    &:hover {
      background: ${styles(theme)[variant].hover.background};
      color: ${styles(theme)[variant].hover.color};
      filter: ${styles(theme)[variant].hover.filter};
      border: ${styles(theme)[variant].hover.border};
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    ${!!theme.components.button.fontWeight &&
    variant === 'primary' &&
    buttonModifiers.customFontWeight(theme)};

    ${!!theme.components.button.textColor &&
    variant === 'primary' &&
    buttonModifiers.customTextColor(theme)};

    ${variant === 'primary' &&
    !!theme.components.button.darkenOnHover &&
    buttonModifiers.darkenOnHover(theme)};
  `};
`

const move = keyframes`
  0% {
    transform: translateX(-102%);
  }
  100% {
    transform: translateX(0);
  }
`

export const Progress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translateX(-102%);
  background: ${(p) =>
    p.theme.components.button.background
      ? darken(0.15, p.theme.colors.main)
      : 'transparent'};

  ${(p) =>
    p.show &&
    css`
      animation: ${move} ${p.duration}ms linear infinite;
    `}
`

export const Children = styled.span`
  position: relative;
`

export default Button
