import React from 'react';

const Icon = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 7.99984C11.4737 7.99984 12.667 6.8065 12.667 5.33317C12.667 3.85984 11.4737 2.6665 10.0003 2.6665C8.52699 2.6665 7.33366 3.85984 7.33366 5.33317C7.33366 6.8065 8.52699 7.99984 10.0003 7.99984ZM4.00033 6.6665V4.6665H2.66699V6.6665H0.666992V7.99984H2.66699V9.99984H4.00033V7.99984H6.00033V6.6665H4.00033ZM10.0003 9.33317C8.22033 9.33317 4.66699 10.2265 4.66699 11.9998V13.3332H15.3337V11.9998C15.3337 10.2265 11.7803 9.33317 10.0003 9.33317Z"
      fill={color || '#666'}
    />
  </svg>
);

export default Icon;
