import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as gtm from '../../../../lib/gtm'
import Photo from '../../../atoms/Photo'
import { ReactComponent as RemoveIcon } from '../../../../assets/img/remove.svg'
import { ReactComponent as AddIcon } from '../../../../assets/img/add.svg'
import { ReactComponent as ArrowsGroupIcon } from '../../../../assets/img/arrows-group.svg'
import {
  changeQuantity,
  removeProduct,
  changeVariableValue,
} from '../../../../store/modules/cart/actions'

import * as S from './styles'

const Resume = () => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { language } = useSelector((state) => state.language)
  const [cipher, setCipher] = useState(null)
  const formatCurrency = (price, locale = 'pt-BR', currency = 'BRL') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(price)
  }

  const total = useSelector((state) =>
    state.cart.products.reduce(
      (partialTotal, product) =>
        partialTotal + product.price * product.quantity,
      0
    )
  )

  const formatVariableValueCurrency = (price, locale) => {
    const val = formatCurrency(price, locale)
    if (!cipher) {
      setCipher(val.replace(/[0-9,.]/g, ''))
    }
    return val.replace(/^\D+\s*/, '')
  }

  const handleChangeProductQuantity = (action, productId) => {
    dispatch(changeQuantity(action, productId))
    const product = cart.products.find((p) => p.id === productId)
    const gtmEvent = action === 'increase' ? gtm.addToCart : gtm.removeFromCart
    gtmEvent({
      value: product.price,
      items: [
        {
          item_id: String(product.id),
          item_name: product.name,
          currency: 'BRL',
          item_category: product.category.name,
          item_category2: 'Site do casal',
          item_list_id: product.item_list_id,
          item_list_name: product.item_list_name,
          price: product.price,
        },
      ],
    })
  }

  const handleRemoveProduct = (productId) => {
    dispatch(removeProduct(productId))
    const product = cart.products.find((p) => p.id === productId)
    gtm.removeFromCart({
      value: product.price * product.quantity,
      items: [
        {
          item_id: String(product.id),
          item_name: product.name,
          currency: 'BRL',
          item_category: product.category.name,
          item_category2: 'Site do casal',
          item_list_id: product.item_list_id,
          item_list_name: product.item_list_name,
          price: product.price,
          quantity: product.quantity,
        },
      ],
    })
  }

  function convertTofixed(str) {
    var result = str.replace(/[.]/g, '').replace(/,/g, '.')
    return parseFloat(result).toFixed(2)
  }

  const currencyMask = (event, productId) => {
    const onlyDigits = event.target.value
      .split('')
      .filter((s) => /\d/.test(s))
      .join('')
      .padStart(3, '0')
    const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)
    event.target.value = formatCurrency(digitsFloat).replace(/^\D+\s*/, '')
    dispatch(
      changeVariableValue(
        'update',
        productId,
        convertTofixed(event.target.value)
      )
    )
  }

  const handleChangeProductValue = (action, product) => {
    dispatch(changeVariableValue(action, product.id))
  }

  useEffect(() => {
    gtm.viewCart({
      value: cart.products.reduce(
        (partialTotal, product) =>
          partialTotal + product.price * product.quantity,
        0
      ),
      items: cart.products.map((product, index) => ({
        index,
        item_id: String(product.id),
        item_name: product.name,
        currency: 'BRL',
        item_category: product.category.name,
        item_category2: 'Site do casal',
        item_list_id: product.item_list_id,
        item_list_name: product.item_list_name,
        price: product.price,
        quantity: product.quantity,
      })),
    })
  }, [])

  return (
    <S.Wrapper data-testid="ResumeCart">
      <S.Container>
        <S.Header>
          <S.Column>Descrição</S.Column>
          <S.Column>Quantidade</S.Column>
          <S.Column rightAlign>Valor</S.Column>
        </S.Header>
        {cart.products.map((product) => (
          <S.Product key={product.id}>
            <S.Column>
              <Photo proportion="1:1" url={product.image} maxWidth="65px" />
              <S.ProductTitle>{product.name}</S.ProductTitle>
            </S.Column>
            <S.Column>
              <S.QuantityContainer isVariableValue={product?.variable_value}>
                <S.QuantityWrapper>
                  {!product?.variable_value && (
                    <S.QuantityButton
                      disabled={product.quantity <= 1}
                      onClick={() =>
                        handleChangeProductQuantity('decrease', product.id)
                      }
                    >
                      <RemoveIcon />
                    </S.QuantityButton>
                  )}
                  <S.QuantityBox disabled={product?.variable_value}>
                    {product.quantity}
                  </S.QuantityBox>
                  {!product?.variable_value && (
                    <S.QuantityButton
                      disabled={product.quantity >= product.remaining_quantity}
                      onClick={() =>
                        handleChangeProductQuantity('increase', product.id)
                      }
                    >
                      <AddIcon />
                    </S.QuantityButton>
                  )}
                </S.QuantityWrapper>
                <S.DeleteItem onClick={() => handleRemoveProduct(product.id)}>
                  Remover
                </S.DeleteItem>
              </S.QuantityContainer>
            </S.Column>
            {!product?.variable_value && (
              <S.Column
                rightAlign
                flexDirection="column"
                flexAlignItems="end"
                flexJustifyContent="center"
              >
                <S.ColumnItens>
                  <S.LabelPrice isVariableValue={false}>Valor</S.LabelPrice>
                </S.ColumnItens>
                <S.ColumnItens>
                  {formatCurrency(
                    product.price * product.quantity,
                    language.htmlTag
                  )}
                </S.ColumnItens>
              </S.Column>
            )}
            {product?.variable_value && (
              <S.Column rightAlign flexDirection="column" flexAlignItems="end">
                <S.ColumnItens>
                  <S.LabelPrice isVariableValue={true}>Valor</S.LabelPrice>
                </S.ColumnItens>
                <S.ColumnItens>
                  <S.NumberFieldWrapper>
                    <S.Cipher
                      isInvalid={product.price < product.price_variable_value}
                    >
                      {cipher}
                    </S.Cipher>
                    <S.NumberFielBox
                      isInvalid={product.price < product.price_variable_value}
                    >
                      <S.NumberField
                        isInvalid={product.price < product.price_variable_value}
                        type="text"
                        onChange={(event) => currencyMask(event, product.id)}
                        name="variable-value"
                        value={formatVariableValueCurrency(
                          product.price,
                          language.htmlTag
                        )}
                      />
                      <S.GrupArrowsButtons
                        isInvalid={product.price < product.price_variable_value}
                      >
                        <S.ArrowUpButton
                          onClick={() =>
                            handleChangeProductValue('increase', product)
                          }
                        >
                          <ArrowsGroupIcon />
                        </S.ArrowUpButton>
                        <S.ArrowDownButton
                          onClick={() =>
                            handleChangeProductValue('decrease', product)
                          }
                        >
                          <ArrowsGroupIcon />
                        </S.ArrowDownButton>
                      </S.GrupArrowsButtons>
                    </S.NumberFielBox>
                  </S.NumberFieldWrapper>
                  <S.HelpText
                    isInvalid={product.price < product.price_variable_value}
                  >
                    {product.price < product.price_variable_value
                      ? `Valor mínimo: ${formatCurrency(
                          product.price_variable_value
                        )}`
                      : `Digite o valor desejado`}
                  </S.HelpText>
                </S.ColumnItens>
              </S.Column>
            )}
          </S.Product>
        ))}
        <S.Footer>
          <S.Column />
          <S.Column>Total</S.Column>
          <S.Column rightAlign>
            {formatCurrency(total, language.htmlTag)}
          </S.Column>
        </S.Footer>
      </S.Container>
    </S.Wrapper>
  )
}

export default Resume
