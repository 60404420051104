/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import throttle from 'lodash/throttle'

import isToday from '../../../utils/isToday'
import Flag from '../../atoms/Flag'
import * as S from './styles'
import useMedia from '../../../utils/useMedia'

const Header = () => {
  const { showHeader } = useSelector((state) => state.ui)

  const isMobile = useMedia('(max-width: 768px)')
  const theme = useTheme()
  const { resourceId, cartStep } = useParams()
  const { profile } = useSelector((state) => state.user)
  const firstContentIsHero = useSelector(
    (state) =>
      state.page?.contents[0]?.type === 'hero' && !resourceId && !cartStep
  )
  const isMarried = useMemo(() => {
    const weddingDate = new Date(profile.wedding_datetime)
    const currentDate = new Date()

    if (isToday(weddingDate)) return false
    return weddingDate < currentDate
  }, [profile.wedding_datetime])
  const [showNavigation, setShowNavigation] = useState(false)
  const [isAtTheTop, setIsAtTheTop] = useState(true)
  const [Skin, setSkin] = useState(null)

  function handleToggleNavigation() {
    setShowNavigation(!showNavigation)
  }

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.header.skin}/index.jsx`
      )
      setSkin(() => response.default)
    }
    getSkin()

    const handleScroll = throttle(() => {
      setIsAtTheTop(window.scrollY < 100)
    }, 500)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [theme])

  useEffect(() => {
    if (showNavigation && isMobile) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [showNavigation, isMobile])

  if (!showHeader) return null

  return (
    <S.Header
      attachedToHero={firstContentIsHero && isAtTheTop}
      showNavigation={showNavigation}
    >
      {Skin && (
        <Skin
          showNavigation={showNavigation}
          handleToggleNavigation={handleToggleNavigation}
          attachedToHero={firstContentIsHero && isAtTheTop}
          isStore={!!resourceId || !!cartStep}
        />
      )}

      {isMarried && profile.exibition_configs.flag && (
        <S.FlagWrapper
          attachedToHero={firstContentIsHero && isAtTheTop}
          showNavigation={showNavigation}
        >
          <Flag />
        </S.FlagWrapper>
      )}
    </S.Header>
  )
}

export default Header
