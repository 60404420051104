import styled, { css } from 'styled-components'
import hexToRgba from '../../utils/hexToRgba'

const borderColor = (theme) => hexToRgba(theme.colors.content, 0.16)
const barColor = (theme) => hexToRgba(theme.colors.content, 0.08)

export const ButtonToggle = styled.button.attrs({
  className: 'music-player__button-toggle',
})`
  ${({ theme }) => css`
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 42px;
    height: 42px;
    background: ${theme.colors.body};
    border: 1px solid ${borderColor(theme)};
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${theme.colors.main};
    z-index: 100;
    transition: filter 0.2s linear;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      filter: brightness(0.96);
    }

    > svg {
      width: 24px;
      height: 24px;
    }
  `}
`

const playerModifiers = {
  isOpen: () => css`
    opacity: 1;
    pointer-events: all;
  `,
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  ${({ theme, isOpen }) => css`
    position: fixed;
    bottom: 40px;
    left: 56px;
    width: 320px;
    background: ${theme.colors.body};
    padding: 24px;
    border: 1px solid ${borderColor(theme)};
    border-radius: 4px;
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
    box-sizing: border-box;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    ${isOpen && playerModifiers.isOpen()}

    @media (max-width: 540px) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
      z-index: 1000;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `}
`

export const ButtonClose = styled.button.attrs({
  className: 'music-player__button-close',
})`
  ${({ theme }) => css`
    color: ${theme.colors.content};
    background: none;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    transition: opacity 0.2s linear;
    opacity: 0.6;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      opacity: 1;
    }

    > svg {
      width: 12px;
      height: 12px;
    }

    @media (max-width: 540px) {
      width: 56px;
      height: 56px;

      > svg {
        width: 16px;
        height: 16px;
      }
    }
  `}
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 540px) {
    flex-direction: column;
    margin-bottom: 32px;
  }
`

export const Cover = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.main};
    width: 80px;
    height: 80px;
    margin-right: 16px;

    @media (max-width: 540px) {
      width: 120px;
      height: 120px;
      margin-right: unset;
      margin-bottom: 16px;
    }
  `}
`

export const MusicInfo = styled.div`
  flex: 1;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  ${({ theme }) => css`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${theme.colors.content};
    text-align: left;

    margin-bottom: 2px;
  `}
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  ${({ theme }) => css`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: ${theme.colors.content};
    text-align: left;

    margin-bottom: 4px;
  `}
`

export const MusicTracks = styled.p.attrs({
  className: 'music-player__tracks',
})`
  ${({ theme }) => css`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.colors.content};
    text-align: left;
  `}
`

export const Config = styled.div`
  display: flex;
  align-items: center;
`

export const ProgressWrapper = styled.div.attrs({
  className: 'music-player__progress',
})`
  width: 100%;
`

export const Progress = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 6px;
    background: ${barColor(theme)};
  `}
`

const progressWidth = ({ progress, isLoading }) => ({
  style: {
    width: isLoading ? '0%' : `${progress}%`,
  },
})

export const ProgressSlider = styled.div.attrs(progressWidth)`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 6px;
    background: ${theme.colors.main};
    box-shadow: 0 0 6px 0 ${theme.colors.main};

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.16);
      background-color: ${theme.colors.main};
    }
  `}
`

export const ProgressRange = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const VolumeWrapper = styled.div.attrs({
  className: 'music-player__volume',
})`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonMute = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.main};
    font-size: 0;
    margin: 0 8px 0 16px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 0.2s linear;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &:focus:hover {
      opacity: 0.6;
    }
  `}
`

export const Volume = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 64px;
    height: 6px;
    border-radius: 6px;
    background: ${barColor(theme)};
  `}
`

const volumeWidth = ({ volume, muted }) => ({
  style: {
    width: muted ? '0%' : `${volume}%`,
  },
})

export const VolumeSlider = styled.div.attrs(volumeWidth)`
  ${({ theme }) => css`
    position: relative;
    height: 6px;
    border-radius: 6px;
    background: ${theme.colors.main};
    box-shadow: 0 0 6px 0 ${theme.colors.main};
  `}
`

export const VolumeRange = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const Time = styled.p.attrs({
  className: 'music-player__time',
})`
  ${({ theme }) => css`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.colors.content};
    text-align: left;

    margin-top: 8px;
  `}
`

export const Controls = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 540px) {
    margin-top: 32px;
  }
`

const Action = styled.button`
  ${({ theme }) => css`
    font-size: 0;
    background: none;
    border: none;
    color: ${theme.colors.main};
    transition: opacity 0.2s linear;
    cursor: pointer;

    width: 48px;
    height: 48px;

    &:hover:not(:disabled),
    &:focus {
      opacity: 0.7;
    }

    &:focus:hover {
      opacity: 0.6;
    }

    &:disabled {
      cursor: initial;
    }

    > svg {
      filter: drop-shadow(0px 0px 3px ${theme.colors.main});
    }

    > svg circle {
      stroke: ${theme.colors.main};
    }

    @media (max-width: 540px) {
      width: 64px;
      height: 64px;
    }
  `}
`

export const Next = styled(Action)`
  > svg {
    width: 24px;
  }

  @media (max-width: 540px) {
    > svg {
      width: 32px;
    }
  }
`

export const Prev = styled(Action)`
  > svg {
    width: 24px;
  }

  @media (max-width: 540px) {
    > svg {
      width: 32px;
    }
  }
`

export const Play = styled(Action)`
  margin: 0 8px;
`

export const Pause = styled(Action)`
  margin: 0 8px;
`
