import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Title = ({ children, className, ...rest }) => (
  <S.Wrapper {...rest}>
    <S.Title className={className}>{children}</S.Title>
  </S.Wrapper>
);

Title.defaultProps = {
  children: '',
  className: '',
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

export default Title;
