const typography = {
  titleXL: {
    desktop: {
      fontSize: 120,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 60,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  titleLG: {
    desktop: {
      fontSize: 64,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 48,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  titleMD: {
    desktop: {
      fontSize: 48,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 32,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  titleSM: {
    desktop: {
      fontSize: 32,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 24,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  titleXS: {
    desktop: {
      fontSize: 20,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 18,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  content: {
    desktop: {
      fontSize: 16,
      letterSpacing: '1px',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 16,
      letterSpacing: '1px',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  contentMobile: {
    desktop: {
      fontSize: 14,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 14,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  menu: {
    desktop: {
      fontSize: 14,
      letterSpacing: '0.92px',
      textTransform: 'uppercase',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 14,
      letterSpacing: '0.93px',
      textTransform: 'uppercase',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
  warning: {
    desktop: {
      fontSize: 10,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
    mobile: {
      fontSize: 10,
      letterSpacing: 'normal',
      fontWeight: 300,
      fontStretch: 'normal',
    },
  },
}

export default typography
