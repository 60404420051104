/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import PlayerMobile from '../skins/PlayerMobile';

export default function withPlayerMobile(WrappedComponent) {
  return (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < 768);
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return isMobile ? (
      <PlayerMobile {...props} />
    ) : (
      <WrappedComponent {...props} />
    );
  };
}
