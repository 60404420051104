import React from 'react';

const CloseIcon = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 10 10"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#666666" fillRule="evenodd">
      <path d="M9.565 -0.797L-0.797 9.565 0.435 10.797 10.797 0.435z" />
      <path d="M10.797 9.565L0.435 -0.797 -0.797 0.435 9.565 10.797z" />
    </g>
  </svg>
);

export default CloseIcon;
