import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import _merge from 'lodash/merge'

import api from './services/api'
import defaultComponents from './components/_settings/defaultComponents'
import defaultTypography from './components/_settings/defaultTypography'
import defaultFont from './components/_settings/defaultFont'

const Theme = ({ template, children }) => {
  const [theme, setTheme] = useState({})
  const { pathname } = useLocation()
  const { user } = useSelector((state) => state)
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const getData = async () => {
      const coupleId = user.profile.couple_id

      const response = await api.get(`/api/v1/template`, {
        params: {
          couple_id: coupleId,
          template_id: template || user.templateId,
          preview: user.preview,
          is_mobile: queryParams.get('mobile'),
        },
      })

      const {
        template_config: templateConfig,
        components,
        typography,
      } = response.data

      const colors = {
        coupleName: templateConfig.coupleName,
        menu: templateConfig.menu,
        content: templateConfig.content,
        main: templateConfig.main,
        body: templateConfig.body,
        alert: templateConfig.alert,
        formBorder: templateConfig.formBorder,
        lightBackground: templateConfig.lightBackground,
        darkBackground: templateConfig.darkBackground,
        transparentBackground: templateConfig.transparentBackground,
        black: '#000000',
        white: '#ffffff',
      }

      const fonts = {
        fontText: templateConfig?.fontText || defaultFont,
        fontMenu: templateConfig?.fontMenu || defaultFont,
        fontTitle: templateConfig?.fontTitle || defaultFont,
        fontNameTop: templateConfig?.fontNameTop || defaultFont,
      }

      const configs = {
        customCss: templateConfig.custom_css,
        customJs: templateConfig.custom_js,
        variant: templateConfig.variant,
        isTailorMade: templateConfig.tailor_made,
        menu: templateConfig?.menuConfig
          ? JSON.parse(templateConfig.menuConfig)
          : null,
      }

      setTheme({
        colors,
        fonts,
        configs,
        components: _merge(defaultComponents, components),
        typography: _merge(defaultTypography, typography),
      })
    }
    getData()
  }, [user.profile.couple_id, user.templateId, user.preview, template])

  useEffect(() => {
    if (theme.configs?.customJs) setTimeout(() => eval(theme.configs.customJs)); // eslint-disable-line
  }, [theme, pathname])

  return (
    <>
      <ThemeProvider theme={theme}>
        {JSON.stringify(theme) !== '{}' ? <>{children}</> : null}
      </ThemeProvider>
    </>
  )
}

Theme.defaultProps = {
  template: null,
}

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  template: PropTypes.string,
}

export default Theme
