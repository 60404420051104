import brands from './creditCards'
import luhnCheck from './luhnCheck'
import exception from './exceptionCreditCards'

const validator = (number) => {
  const cleanNumber = number.replace(/\D/g, '')

  if (cleanNumber.length >= 6) {
    // eslint-disable-next-line
    for (let brand of brands) {
      if (brand.regex.test(cleanNumber)) {
        // Length validation
        let isValid = brand.size.includes(cleanNumber.length)

        // Luhn validation
        if (isValid)
          isValid = exception(cleanNumber) ? true : luhnCheck(cleanNumber)

        return { ...brand, isValid }
      }
    }
  }

  return false
}

export default validator
