import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MainPage from '../Main';
import Loading from '../../components/molecules/Loading';
import api from '../../services/api';
import Helmet from '../../utils/head';
import Markups from '../../Markups';
import ListRsvp from '../../components/organisms/ListRsvp';
import { pageSuccessFetchData } from '../../store/modules/page/actions';

const MainRsvp = ({ match }) => {
  const menu = useSelector((state) => state.menu);
  const history = useHistory();
  const [render, setRender] = useState(null);
  const [events, setEvents] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const user = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!render) {
      if (isV1(menu.menu)) {
        setRender('v1');
      } else {
        (async () => {
          const res = await getRsvpEvents();
          if (res.page_quantity === 0) {
            history.replace(menu.home.path);
          } else if (res.page_quantity === 1) {
            const page = res.events[0].pages[0];
            const pageMenu = getMenuData(menu.menu, page.id);
            history.replace(pageMenu.path);
          } else {
            dispatch(pageSuccessFetchData([{ type: 'welcome' }]));
            setEvents(
              res.events.map((event) => {
                return {
                  ...event,
                  pages: event.pages.map((page) => {
                    const pageMenu = getMenuData(menu.menu, page.id);
                    return {
                      ...page,
                      path: pageMenu.path,
                    };
                  }),
                };
              })
            );
            setRender('v2');
          }
        })();
      }
    }
  }, [menu]);

  const isV1 = (menuItems) => {
    return menuItems.some(({ pages }) => pages.some((p) => p.slug === 'rsvp'));
  };

  const getMenuData = (menuItems, pageId) => {
    return menuItems
      .reduce((result, item) => [...result, ...item.pages], [])
      .find((page) => page.external_id === pageId);
  };

  const getRsvpEvents = async () => {
    const response = await api.get(`api/v1/rsvp/get_events_with_pages`, {
      params: { couple_id: user.profile.couple_id },
    });
    return response.data;
  };

  const loading = !render && !redirectTo;

  return (
    <>
      <Loading loaded={!loading} />
      {!loading && (
        <>
          {render === 'v1' && <MainPage pageSlug="rsvp" match={match} />}
          {render === 'v2' && (
            <>
              <Helmet
                pageName="Confirmação de presença"
                lang={language.htmlTag}
              />
              <Markups
                id={0}
                type="welcome"
                slug="about"
                afterMarkup="text"
                attachedToHero={false}
                content={{
                  image: undefined,
                  title: 'Confirmação de presença',
                  subtitle:
                    'Escolha para qual evento deseja confirmar presença:',
                }}
              />
              <ListRsvp events={events} />
            </>
          )}
        </>
      )}
    </>
  );
};

MainRsvp.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MainRsvp;
