import React from 'react';

import Subtitle from '../../../../atoms/Subtitle';
import Spacer from '../../../../atoms/Spacer';
import { ReactComponent as BankSlipIcon } from '../../../../../assets/img/bank-slip.svg';

import * as S from '../styles';

const BankSlip = ({ name, email, redirectToBoleto }) => (
  <>
    <Subtitle>Agora falta pouco!</Subtitle>
    <S.Content>
      {name}, um email de confirmação junto com o boleto bancário para pagamento
      foi enviado para <strong>{email}</strong>
    </S.Content>
    <Spacer size="small" />
    <S.Card clickable onClick={redirectToBoleto}>
      <S.CardIcon>
        <BankSlipIcon />
      </S.CardIcon>
      <S.CardContent>
        <p>
          <strong>Clique aqui e imprima o boleto bancário.</strong>
        </p>
        <p>
          Sua compra será concluída após a compensação do boleto. Efetue o
          pagamento até a data de vencimento. Não será necessário nos encaminhar
          qualquer comprovante de pagamento. Após a confirmação do pagamento
          você receberá um novo e-mail confirmando o recebimento.
        </p>
      </S.CardContent>
    </S.Card>
  </>
);

export default BankSlip;
