import styled from 'styled-components'
import { Warning } from '../../components/_settings/typography'

export const Wrapper = styled.section.attrs({
  className: 'signin',
})`
  width: 100%;
  height: 100vh;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Form = styled.form.attrs({
  className: 'signin__form',
})`
  max-width: 346px;
  width: 100%;
  margin: 20px 0;

  > button {
    width: 100%;
  }

  > input {
    width: 100%;
  }

  > div {
    margin-bottom: 20px;
  }
`

export const InfoText = styled.p`
  ${Warning}
  text-align: left;
  max-width: 346px;
`
