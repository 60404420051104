export function changeQuantity(action, id) {
  return {
    type: '@cart/CHANGE_QUANTITY',
    payload: { action, id },
  }
}

export function changeVariableValue(action, id, value) {
  return {
    type: '@cart/CHANGE_VARIABLE_VALUE',
    payload: { action, id, value },
  }
}

export function addProduct(product) {
  return {
    type: '@cart/ADD_PRODUCT',
    payload: { product },
  }
}

export function removeProduct(id) {
  return {
    type: '@cart/REMOVE_PRODUCT',
    payload: { id },
  }
}

export function addCard(card) {
  return {
    type: '@cart/ADD_CARD',
    payload: { card },
  }
}

export function removeCard() {
  return {
    type: '@cart/REMOVE_CARD',
  }
}

export function saveFormData(data) {
  return {
    type: '@cart/SAVE_FORM_DATA',
    payload: { ...data },
  }
}

export function clearCart() {
  return {
    type: '@cart/CLEAR_CART',
  }
}

export function getConfig(coupleId) {
  return {
    type: '@cart/FETCH_CONFIG',
    payload: { coupleId },
  }
}

export function saveConfig(data) {
  return {
    type: '@cart/SAVE_CONFIG',
    payload: data,
  }
}

export function updateProductsValues(products) {
  return {
    type: '@cart/UPDATE_PRODUCTS_VALUES',
    payload: products,
  }
}
