import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

export default (reducers) => {
  const DYNAMIC_KEY =
    document.head.querySelector('meta[name="login"]')?.content || 'development'

  const persistedReducer = persistReducer(
    {
      key: DYNAMIC_KEY,
      storage: storageSession,
      whitelist: ['appBanner', 'cart', 'language'],
    },
    reducers
  )

  return persistedReducer
}
