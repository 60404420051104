import produce from 'immer'

const INITIAL_STATE = {
  error: '',
  loading: false,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true
        draft.error = ''
        break
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.error = ''
        draft.loading = false
        break
      }
      case '@auth/SIGN_SET_ERROR': {
        draft.loading = false
        draft.error = action.payload.error
        break
      }
      default:
        return state
    }
  })
}
