import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import withPlayerMobile from '../../utils/withPlayerMobile';
import radioAudio from './assets/Loop.mp3';

import * as S from './styles';

function Player6({
  skin,
  audioState,
  volume,
  progress,
  title,
  musicTotal,
  musicSelected,
  loading,
  onChangeVolume,
  onPlay,
  onPause,
  onNext,
  onPrev,
}) {
  const [animaBar, setAnimaBar] = useState(false);
  const [show, setShow] = useState(false);
  const paused = useMemo(() => audioState !== 'playing', [audioState]);

  function handleToggleShow() {
    setShow(!show);
  }

  function animaAudioBar() {
    const audio = new Audio(radioAudio);
    audio.volume = 0.4;

    if (!animaBar) {
      audio.play();
      setAnimaBar(true);
      setTimeout(() => {
        audio.pause();
        setAnimaBar(false);
      }, 2000);
    }
  }

  function handleClickPrev() {
    animaAudioBar();
    onPrev();
  }

  function handleClickNext() {
    animaAudioBar();
    onNext();
  }

  return (
    <S.Player show={show ? 1 : 0} skin={skin}>
      <S.PlayerTop>
        <S.RadioStations skin={skin}>
          <S.Bar skin={skin} anima={animaBar ? 1 : 0} />
        </S.RadioStations>

        <S.ButtonOnOff
          skin={skin}
          onClick={paused ? onPlay : onPause}
          paused={paused ? 1 : 0}
        />
      </S.PlayerTop>

      <S.PlayerMiddle>
        <S.PlayerMiddleLeft>
          <S.MusicInfo>
            <S.MusicTitle>{loading ? 'Carregando...' : title}</S.MusicTitle>
          </S.MusicInfo>

          <S.Tracks>
            {musicSelected}/{musicTotal}
          </S.Tracks>

          <S.Controls>
            <S.Prev
              title="Música anterior"
              skin={skin}
              onClick={handleClickPrev}
            />
            {paused ? (
              <S.Play title="Tocar Música" onClick={onPlay} skin={skin} />
            ) : (
              <S.Pause title="Pausar" onClick={onPause} skin={skin} />
            )}
            <S.Next
              title="Próxima canção"
              skin={skin}
              onClick={handleClickNext}
            />
          </S.Controls>
        </S.PlayerMiddleLeft>

        <S.ShowButton
          type="button"
          onClick={handleToggleShow}
          skin={skin}
          aria-label="Abrir/Fechar player de música"
        >
          <S.ArrowIcon show={show ? 1 : 0} skin={skin} />
        </S.ShowButton>
      </S.PlayerMiddle>

      <S.PlayerBottom>
        <S.PlayerBottomLeft>
          <S.Progress>
            <S.PregressTrack progress={progress} />
            <S.PregressBar skin={skin} progress={progress} />
          </S.Progress>

          <S.Volume>
            <S.VolumeIcon />
            <S.VolumeWrrapper>
              <S.VolumeSlider volume={volume} skin={skin} />
              <S.VolumeRange
                min={0}
                max={100}
                value={volume}
                onChange={onChangeVolume}
                aria-label="Volume"
              />
            </S.VolumeWrrapper>
          </S.Volume>
        </S.PlayerBottomLeft>
        <S.EqButton
          paused={loading || paused ? 1 : 0}
          skin={skin}
          onClick={paused ? onPlay : onPause}
          aria-label="Equalizador"
        />
      </S.PlayerBottom>
    </S.Player>
  );
}

Player6.propTypes = {
  skin: PropTypes.string.isRequired,
  audioState: PropTypes.string,
  volume: PropTypes.number,
  progress: PropTypes.number,
  title: PropTypes.string,
  musicTotal: PropTypes.number,
  musicSelected: PropTypes.number,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onChangeVolume: PropTypes.func,
};

Player6.defaultProps = {
  audioState: 'stopped',
  volume: 100,
  progress: 0,
  title: '',
  musicTotal: 0,
  musicSelected: 0,
  loading: false,
  onPlay: () => {},
  onPause: () => {},
  onNext: () => {},
  onPrev: () => {},
  onChangeVolume: () => {},
};

export default withPlayerMobile(Player6);
