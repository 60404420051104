import styled from 'styled-components'

export const Spacer = styled.div.attrs(({ size }) => ({
  className: ['spacer', `spacer--${size}`],
}))`
  width: 100%;
  height: ${(props) => (props.size === 'small' ? '40px' : '80px')};
`

export default Spacer
