import React from 'react';
import FacebokLogin from 'react-facebook-login';

import PropTypes from 'prop-types';

import api from '../../../../services/api';

import * as S from './styles';

const FacebookAuthButton = ({ idNoivo, getAccountUser }) => {
  const isShowRender = true;

  const responseFacebook = async (response) => {
    if (response === 'unknown') return;

    const data = {
      id_noivo: idNoivo,
      facebook_id: response.accessToken,
      social: {
        type: 'facebook',
        email: response.email,
        token: response.accessToken,
        id: response.id,
        first_name: response.first_name,
        last_name: response.last_name,
        name: response.name,
      },
    };

    const isDomain = () => {
      return (
        window.origin !== 'https://sites.icasei.com.br' &&
        window.origin !== 'https://mirage.icasei.com.br'
      );
    };

    api
      .post('/api/v1/auth/facebook', data)
      .then((facebookRresponse) => {
        if (facebookRresponse.status === 200) {
          if (isDomain()) {
            window.parent.postMessage(facebookRresponse.data.token, '*');
          } else if (typeof getAccountUser === 'function') {
            getAccountUser(facebookRresponse.data.token);
          } else {
            window.parent.postMessage(facebookRresponse.data.token, '*');
          }
        }
      })
      .catch((error) => {
        console.error(error);
        window.parent.postMessage(error, '*');
      });
  };

  return (
    <>
      {isShowRender && (
        <S.Wrapper>
          <FacebokLogin
            autoLoad={false}
            disableMobileRedirect
            appId="211260932253820"
            fields="name,email,picture,first_name,last_name"
            callback={responseFacebook.bind(this)} //eslint-disable-line
            textButton="Entrar com Facebook"
            cssClass="login-container__button"
            icon="login-container__button-icon login-container__button-icon--facebook"
            version="13.0"
          />
        </S.Wrapper>
      )}
    </>
  );
};

FacebookAuthButton.propTypes = {
  idNoivo: PropTypes.number.isRequired,
};

export default FacebookAuthButton;
