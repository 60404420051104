import React, { forwardRef, useState } from 'react';
import SelectCountry from '../../organisms/SelectCountry';
import * as S from './styles';

const PhoneTextField = forwardRef(
  (
    {
      id,
      value,
      onChange,
      errorText,
      onBlur,
      onFocus,
      phoneCountry,
      setPhoneCountry,
      ...props
    },
    ref
  ) => {
    const [inputIsFocused, setInputIsFocused] = useState(false);

    const handleInputFocus = () => {
      if (typeof onFocus === 'function') onFocus();
      setInputIsFocused(true);
    };

    const handleInputBlur = () => {
      if (typeof onBlur === 'function') onBlur();
      setInputIsFocused(false);
    };

    return (
      <S.Container>
        <S.Wrapper inputIsFocused={inputIsFocused} invalid={!!errorText}>
          <SelectCountry
            country={phoneCountry}
            onChange={(country) => setPhoneCountry(country)}
          />
          <S.InputWrapper>
            <S.Input
              ref={ref}
              id={id || 'phone'}
              type="tel"
              required
              country={phoneCountry}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={value}
              onChange={onChange}
              {...props}
            />
            <S.Label htmlFor={id || 'phone'}>Telefone *</S.Label>
          </S.InputWrapper>
        </S.Wrapper>
        {errorText && (
          <S.ErrorText className="text-input__hint--invalid">
            {errorText}
          </S.ErrorText>
        )}
      </S.Container>
    );
  }
);

export default PhoneTextField;
