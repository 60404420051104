import produce from 'immer'

const INITIAL_STATE = {
  showHeader: true,
}

export default function ui(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@ui/HIDE_HEADER': {
        draft.showHeader = false
        break
      }
      default:
    }
  })
}
