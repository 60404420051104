import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTheme } from 'styled-components';

const TextField = ({
  label,
  register,
  required,
  type,
  id,
  name,
  dataTestId,
  placeholder,
  value,
  invalid,
  helperText,
  lines,
  maxLength,
  onBlur,
  onFocus,
  onChange,
  disabled,
  mask,
  icon,
  variant,
}) => {
  const theme = useTheme();
  const [Skin, setSkin] = useState(null);

  const handleRegister = () => {
    return {
      onChange: () => {},
      onBlur: () => {},
      ref: () => {},
    };
  };

  const registeredComponent = register || handleRegister;

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.textField.skin}/index.jsx`
      );
      setSkin(() => response.default);
    }

    getSkin();
  }, [theme]);
  return (
    Skin && (
      <Skin
        type={type}
        id={id || name}
        name={name}
        dataTestId={dataTestId}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        invalid={invalid}
        helperText={helperText}
        lines={lines}
        maxLength={maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        mask={mask}
        icon={icon}
        variant={variant}
        register={registeredComponent}
        label={label}
        required={required}
      />
    )
  );
};

TextField.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  invalid: false,
  helperText: '',
  lines: null,
  maxLength: null,
  disabled: false,
  mask: null,
  icon: null,
  variant: 'default',
};

TextField.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  value: propTypes.string,
  onChange: propTypes.func,
  invalid: propTypes.bool,
  helperText: propTypes.string,
  lines: propTypes.number,
  maxLength: propTypes.number,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  disabled: propTypes.bool,
  mask: propTypes.string,
  icon: propTypes.element,
  variant: propTypes.oneOf(['default', 'inverted']),
};

export default TextField;
