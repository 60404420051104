import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';


import Helmet from '../../utils/head';
import isDomain from '../../utils/isDomain';
import AnimatedIntro from '../../components/widgets/AnimatedIntro'; // eslint-disable-line

const Intro = ({ match }) => {
  const { language } = useSelector((state) => state.language);

  const [coupleInfo, introSettings] = useSelector((state) => [
    {
      coupleId: state.user.profile.couple_id,
      brideName: state.user.profile.couple_nickname1,
      groomName: state.user.profile.couple_nickname2,
      weddingDate: state.user.profile.wedding_datetime,
    },
    {
      has_intro: state.user.profile.intro.has_intro,
      id: state.user.profile.intro.id,
      path: state.user.profile.intro?.data?.path || state.user.profile.intro?.id,
      background_image:
        state.user.profile.intro.has_intro &&
        state.user.profile.intro.data?.background_image, // eslint-disable-line
    },
  ]);
  const { login } = useParams();
  const [{ home }, { preview }] = useSelector((state) => [
    state.menu,
    state.user,
  ]);

  return (
    introSettings &&
    (!preview && introSettings.has_intro ? (
      <>
        <Helmet pageName="Introdução" lang={language.htmlTag} />
        <AnimatedIntro
          introSettings={introSettings}
          coupleInfo={coupleInfo}
          customBackground={introSettings.background_image}
          isCd={false}
        />
      </>
    ) : (
      <Redirect to={isDomain() ? `/${home.slug}` : `/${login}/${home.slug}`} />
    ))
  );
};

Intro.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Intro;
