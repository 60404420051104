import React from 'react';
import propTypes from 'prop-types';

import { ReactComponent as Amex } from './card-brands/amex.svg';
import { ReactComponent as Elo } from './card-brands/elo.svg';
import { ReactComponent as Hipercard } from './card-brands/hipercard.svg';
import { ReactComponent as Master } from './card-brands/master.svg';
import { ReactComponent as Visa } from './card-brands/visa.svg';

const BrandIcon = ({ brand }) => {
  switch (brand) {
    case 'amex':
      return <Amex />;
    case 'elo':
      return <Elo />;
    case 'hipercard':
      return <Hipercard />;
    case 'master':
      return <Master />;
    case 'visa':
      return <Visa />;
    default:
      return null;
  }
};

BrandIcon.propTypes = {
  brand: propTypes.string.isRequired,
};

export default BrandIcon;
