/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const LoadingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto',
    }}
    width={30}
    height={30}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    display="block"
    {...props}
  >
    <circle
      cx={50}
      cy={50}
      fill="none"
      stroke="#348cc1"
      strokeWidth={8}
      r={40}
      strokeDasharray="188.49555921538757 64.83185307179586"
      transform="rotate(29.942 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

export const MusicIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.982 5.48L17.966 5 8.227 6.655v8.461a4.055 4.055 0 00-1.329-.227C5.296 14.89 4 15.811 4 16.945 4 18.078 5.301 19 6.896 19c1.597 0 2.897-.922 2.897-2.055 0-.103-.026-5.9-.03-7.858l6.65-1.13v5.653a4.038 4.038 0 00-1.323-.227c-1.6 0-2.894.922-2.894 2.056 0 1.133 1.302 2.055 2.898 2.055 1.601 0 2.906-.922 2.906-2.055l-.018-9.959z"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1l16 16m0-16L1 17" stroke="currentColor" />
  </svg>
);

export const VolumeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.586 10.522c-.067-.303.15-.597.488-.658.982-.182 1.685-.96 1.682-1.862.04-.906-.67-1.7-1.662-1.863-.339-.05-.57-.336-.514-.64.054-.305.373-.513.713-.463h.007c1.587.26 2.731 1.517 2.7 2.966.003 1.434-1.12 2.67-2.683 2.957a.579.579 0 01-.122.012c-.296 0-.55-.188-.61-.448l.001-.001zM10.021 15.9l-6.078-4.495H1.915c-1.038 0-1.883-.688-1.883-1.534L0 6.117c0-.85.845-1.537 1.882-1.537h2.03l6.11-4.483a.635.635 0 01.845.154.548.548 0 01.104.319v14.86c0 .313-.271.569-.608.57a.643.643 0 01-.341-.098l-.001-.001z"
      fill="currentColor"
    />
  </svg>
);

export const MutedIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#muted-icon0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.074 9.865c-.338.06-.555.355-.488.657v.001c.059.26.313.449.61.449a.579.579 0 00.121-.012c1.563-.288 2.686-1.523 2.682-2.958.032-1.448-1.112-2.705-2.7-2.965h-.006c-.34-.05-.659.157-.713.462-.056.305.175.591.514.64.992.163 1.702.958 1.662 1.863.003.902-.7 1.68-1.682 1.863zM5.13 12.284l4.891 3.617v.001c.101.064.22.098.341.098.338 0 .61-.256.61-.57V6.443L5.13 12.284zm-1.95-.878H1.914c-1.038 0-1.881-.688-1.881-1.533L0 6.117c0-.85.845-1.537 1.882-1.537h2.03l6.11-4.482a.635.635 0 01.845.154.548.548 0 01.104.318v3.044l-.678.679-7 7-.113.113z"
        fill="currentColor"
      />
      <path d="M.5 15.5l15-15" stroke="currentColor" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="muted-icon0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const PrevIcon = (props) => (
  <svg
    viewBox="0 0 34 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.735 12.932v.001c.133.243.316.455.544.614l.001.001 13.954 9.667h.001c.83.572 1.886.246 2.352-.622.155-.288.233-.61.233-.937v-7.514l13.095 9.073h0c.83.574 1.887.246 2.352-.623h.001a1.99 1.99 0 00.232-.936V2.323C33.49 1.366 32.784.487 31.794.5h0a1.584 1.584 0 00-.845.262h0l-.01.007-13.12 9.074v-7.52c-.01-.957-.716-1.836-1.705-1.823h0a1.59 1.59 0 00-.845.26l-.006.005-.006.004-13.976 9.667h0c-.786.546-.998 1.66-.546 2.496z"
      stroke="red"
    />
    <g filter="url(#prev-icon0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.175 12.694c.098.18.23.332.39.443l13.954 9.667c.559.385 1.288.185 1.628-.448.114-.212.173-.453.173-.7v-8.469l13.88 9.617c.558.386 1.287.186 1.627-.448a1.49 1.49 0 00.173-.7V2.328C32.992 1.586 32.456.991 31.8 1a1.084 1.084 0 00-.577.18l-13.904 9.617V2.328C17.312 1.586 16.775.991 16.12 1a1.09 1.09 0 00-.58.18L1.567 10.847c-.558.388-.733 1.215-.391 1.847z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="prev-icon0"
        x={0}
        y={0}
        width={34}
        height={24.001}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1} dy={-1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend in2="effect1_innerShadow" result="effect2_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export const NextIcon = (props) => (
  <svg
    viewBox="0 0 34 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.265 12.069v-.002a1.788 1.788 0 00-.544-.614h-.001L18.766 1.786l-.001-.001c-.83-.571-1.886-.245-2.352.623-.155.287-.233.61-.233.937v7.514L3.085 1.786h0c-.83-.574-1.887-.246-2.353.622v.001a1.99 1.99 0 00-.232.936v19.333c.01.957.716 1.836 1.706 1.822h0c.302-.004.592-.096.844-.26h0l.011-.008 13.12-9.075v7.52c.009.958.716 1.837 1.705 1.823h0a1.59 1.59 0 00.844-.26l.006-.004.006-.004 13.976-9.667.001-.001c.786-.546.998-1.66.546-2.495z"
      stroke="red"
    />
    <g filter="url(#next-icon0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.825 12.306a1.288 1.288 0 00-.39-.442L18.481 2.197c-.559-.385-1.288-.185-1.628.448a1.478 1.478 0 00-.173.7v8.468L2.8 2.197c-.558-.386-1.287-.186-1.627.448a1.49 1.49 0 00-.173.7v19.328c.008.742.544 1.337 1.199 1.328.203-.004.402-.066.578-.18l13.904-9.618v8.47c.007.742.544 1.337 1.198 1.328a1.09 1.09 0 00.579-.18l13.976-9.668c.558-.388.733-1.215.391-1.847z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="next-icon0"
        x={0}
        y={1}
        width={34}
        height={24.001}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1} dy={-1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend in2="effect1_innerShadow" result="effect2_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export const PlayIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#prefix__filter0_i)">
      <path
        d="M18 31.464c.006.555.473.999 1.042.993.178-.003.352-.048.505-.135l12.17-7.232a.99.99 0 000-1.712l-12.149-7.232a1.047 1.047 0 00-1.418.336.963.963 0 00-.15.524v14.458z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="prefix__filter0_i"
        x={18}
        y={16}
        width={14.21}
        height={16.457}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.2} dy={1.2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export const PauseIcon = (props) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#puse-icon0)">
      <path
        d="M21.943 18.172a2.4 2.4 0 10-4.8 0v11.656a2.4 2.4 0 104.8 0V18.172z"
        fill="currentColor"
      />
    </g>
    <g filter="url(#pause-icon1)">
      <path
        d="M30.857 18.172a2.4 2.4 0 10-4.8 0v11.656a2.4 2.4 0 104.8 0V18.172z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="puse-icon0"
        x={17.143}
        y={15.771}
        width={4.8}
        height={16.457}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.2} dy={1.2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="pause-icon1"
        x={26.057}
        y={15.771}
        width={4.8}
        height={16.457}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.2} dy={1.2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
);
