import styled, { css, keyframes } from 'styled-components'

import {
  Content as ContentTypography,
  ContentMobile,
  Warning,
} from '../../../_settings/typography'
import { ReactComponent as PixIconSvg } from '../../../../assets/img/icon-pix.svg'
import { ReactComponent as IconPaperSvg } from '../../../../assets/img/icon-paper.svg'
import { ReactComponent as IconClockSvg } from '../../../../assets/img/icon-clock.svg'

const strongFontFamily = (theme) =>
  theme.fonts[theme.components.cartConfirmation.strong.fontFamily].family

const strongFontWeight = (theme) =>
  theme.components.cartConfirmation.strong.fontWeight

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Wrapper = styled.div.attrs({
  className: 'confirmation',
})`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-family: ${strongFontFamily(theme)};
      font-weight: ${strongFontWeight(theme)};
    }
  `}
`

export const Content = styled.p.attrs({
  className: 'confirmation__text',
})`
  ${ContentTypography}
  color: ${({ theme }) => theme.colors[theme.components.cart.resumeTextColor]};
  padding: 0 15px;

  @media (max-width: 544px) {
    ${ContentMobile};
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
  }
`

export const Card = styled.div.attrs({
  className: 'confirmation__card',
})`
  max-width: 921px;
  width: calc(100% - 30px);
  display: flex;
  border: 1px solid var(--formBorder);
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};

  @media (max-width: 544px) {
    flex-direction: column;
  }
`

export const CardIcon = styled.div.attrs({
  className: 'confirmation__card-icon',
})`
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--formBorder);

  @media (max-width: 544px) {
    border-right: none;
    border-bottom: 1px solid var(--formBorder);
    padding: 20px 0;
  }

  & > svg {
    path {
      fill: var(--main);
    }
  }
`

export const PixIcon = styled(PixIconSvg)`
  path:nth-child(1) {
    fill: unset;
  }

  path:nth-child(2),
  path:nth-child(3) {
    fill: var(--main);
  }
`

export const CardContent = styled.div.attrs({
  className: 'confirmation__card-content',
})`
  padding: 24px;
  flex: 1;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p {
    ${Warning};
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
  }
`

export const CardContentPix = styled(CardContent).attrs({
  className: 'confirmation__card-content-pix',
})`
  &,
  & > p {
    font-size: 12px;
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};

    @media (max-width: 544px) {
      font-size: 12px;
    }
  }
`

export const ResumeInfo = styled.div`
  padding: 40px 15px 80px;
  width: 100%;
  background: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};

  @media (max-width: 544px) {
    padding: 0 15px 40px;
  }
`

export const ConfirmationInfo = styled.div.attrs({
  className: 'confirmation__info',
})`
  display: flex;
  justify-content: center;

  & > p {
    ${ContentTypography};
    color: ${({ theme }) =>
      theme.colors[theme.components.cart.resumeTextColor]};
    text-align: left;

    max-width: 921px;
    width: 100%;
  }
`

export const PixCard = styled.div`
  padding: 32px;
  max-width: 921px;
  width: calc(100% - 30px);
  background: var(--lightBackground);
  margin-top: 40px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 544px) {
    width: 100%;
    padding: 32px 15px;
  }
`

export const PixCardCodeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }

    & > img {
      width: 100%;
      height: 160px;
      margin-bottom: 5px;

      @media (max-width: 768px) {
        margin-bottom: 9px;
      }
    }

    & > p {
      ${Warning};
      font-size: 9px;
      font-weight: 300;
      color: ${theme.colors[theme.components.cart.resumeTextColor]};
    }
  `}
`

export const PixCardInfoWrapper = styled.div`
  ${() => css`
    flex: 1;
    margin-left: 32px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  `};
`

export const CardInfoPixCodeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      display: flex;
      flex-direction: column;

      max-width: 601px;

      & > p {
        ${ContentMobile};
        font-size: 12px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 4px;
        color: ${theme.colors[theme.components.cart.resumeTextColor]};

        @media (max-width: 768px) {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }

      & > span {
        ${Warning};
        font-size: 9px;
        word-break: break-all;
        color: ${theme.colors[theme.components.cart.resumeTextColor]};

        @media (max-width: 768px) {
          font-size: 9px;
        }
      }
    }

    & > button {
      position: relative;
      background: transparent;

      & > svg {
        path {
          fill: var(--content);
        }
      }
    }
  `}
`

export const CardInfoInfoColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;

    @media (max-width: 544px) {
      flex-direction: column;
      margin-bottom: 24px;

      & > div:first-child {
        margin-right: unset;
        margin-bottom: 24px;
      }
    }

    & > div:first-child {
      margin-right: 24px;
    }

    & > div {
      flex: 1;
      display: flex;
      align-items: flex-start;

      & > div {
        & > p {
          ${ContentMobile};
          font-size: 11px;
          font-weight: bold;
          text-align: left;
          color: ${theme.colors[theme.components.cart.resumeTextColor]};
        }

        & > span {
          ${ContentMobile};
          font-size: 11px;
          color: ${theme.colors[theme.components.cart.resumeTextColor]};
        }
      }
    }
  `}
`

export const CardInfoIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  flex-shrink: 0;
  border: solid 1px var(--formBorder);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 544px) {
    margin-right: 16px;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`

export const IconPaper = styled(IconPaperSvg)`
  path:nth-child(1),
  path:nth-child(3) {
    fill: var(--main);
  }
`

export const IconClock = styled(IconClockSvg)`
  path:nth-child(1),
  path:nth-child(3) {
    fill: var(--main);
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 24px;
  border-top: solid 1px var(--formBorder);
`

export const Tooltip = styled.div`
  ${({ copied = false }) => css`
    position: absolute;
    padding: 8px;
    background: var(--body);
    border-radius: 4px;
    color: var(--content);
    left: -25px;
    bottom: -35px;
    opacity: 0;
    opacity: ${copied ? 1 : 0};
    pointer-events: ${copied ? 'all' : 'none'};

    & > p {
      color: var(--content);
    }

    ${copied &&
    css`
      animation: 2s ${fadeIn};
    `}

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--body);
    }
  `}
`
