import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useHistory from '../../../utils/useHistory';

import * as S from './styles';

import { ReactComponent as CartIcon } from '../../../assets/img/cart.svg';

const Cart = () => {
  const history = useHistory();
  const [isEnd, setIsEnd] = useState(false);
  const cartItems = useSelector((state) => state.cart.products.length);

  const redirectToCart = () => {
    history.push('/cart/resume');
  };

  useEffect(() => {
    const scrollHandler = () => {
      const windowHeight = document?.documentElement?.clientHeight;
      const windowWidth = document?.documentElement?.clientWidth;
      const { scrollHeight, scrollTop } = document.documentElement;
      const footerHeight =
        document.querySelector('#footer')?.clientHeight || 120;
      // Desktop: half the CartButton height + bottom distance
      // Mobile: bottom distance
      const triggerOffset = windowWidth > 768 ? 66 : 15;

      if (
        windowHeight + scrollTop <
        scrollHeight - footerHeight + triggerOffset
      ) {
        setIsEnd(false);
      } else {
        setIsEnd(true);
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <S.Wrapper isEnd={isEnd}>
      <S.Container>
        <S.FloatingCart onClick={redirectToCart}>
          <S.Notification>{cartItems}</S.Notification>
          <S.CartButton>
            <CartIcon />
          </S.CartButton>
        </S.FloatingCart>
      </S.Container>
    </S.Wrapper>
  );
};

export default Cart;
