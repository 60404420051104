import React from 'react'

const StoreIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M4.886 4.247c-.21-.014-.63-.232-.837-.587-.17-.291-.157-.597.042-.935.223-.38.634-.598 1.127-.598.117 0 .237.013.359.037.73.147 1.615.793 1.788 2.13-1.265-.006-2.12-.021-2.48-.047Zm5.538-2.083c.12-.024.241-.037.358-.037.493 0 .904.218 1.127.598.2.338.213.644.042.935-.207.355-.627.573-.837.587-.359.026-1.214.04-2.48.046.174-1.336 1.06-1.982 1.79-2.13Zm2.214.172c-.37-.628-1.066-1.003-1.862-1.003-.174 0-.35.018-.526.053-.834.168-1.813.787-2.25 1.994-.438-1.207-1.417-1.826-2.25-1.994a2.665 2.665 0 0 0-.526-.053c-.796 0-1.492.375-1.862 1.003-.433.735-.262 1.336-.043 1.711.355.608 1.027.96 1.506.994.337.023.998.036 1.737.043v.004h.162c.046.001.434.003.967.003h.617c.534 0 .922-.002.968-.002l.162-.001v-.004c.739-.007 1.4-.02 1.737-.043.479-.034 1.15-.386 1.506-.994.22-.375.39-.976-.043-1.711ZM8.39 8.604h5.023V13.613c0 .722-.336 1.051-1.071 1.052-1.208.002-2.415.001-3.622 0-.105 0-.21-.01-.33-.015V8.604ZM7.59 8.604H2.566V13.613c0 .722.336 1.051 1.071 1.052 1.207.002 2.415.001 3.622 0 .105 0 .21-.01.33-.015V8.604ZM7.595 5.437H2.648A.646.646 0 0 0 2 6.076V7.2c0 .351.292.64.648.64h4.947V5.436ZM13.353 5.437H8.39v2.402h4.962A.645.645 0 0 0 14 7.2V6.077c0-.351-.291-.64-.647-.64Z"
      clipRule="evenodd"
    />
  </svg>
)
export default StoreIcon
