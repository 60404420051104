import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Options = styled.div`
  overflow: hidden;
  position: relative;
  flex: 1;
`

export const Option = styled.div`
  cursor: pointer;
  &:focus-visible {
    box-shadow: 0px 0px 1px 1px #888;
  }
`

export const TextField = styled.input`
  font-family: var(--base-font-family), sans-serif;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  font-size: 16px;
  &:focus {
    border: 1px solid #888;
  }
`
