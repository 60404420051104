export function fetchUserDataRequest(user, preview, mobile) {
  return {
    type: '@user/GET_DATA_REQUEST',
    payload: { user, preview, mobile },
  }
}

export function fetchUserDataSuccess(user, preview, mobile) {
  return {
    type: '@user/GET_DATA_SUCCESS',
    payload: { user, preview, mobile },
  }
}

export function customTemplate(templateId) {
  return {
    type: '@user/DEFINE_TEMPLATE',
    payload: { templateId },
  }
}
