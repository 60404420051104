import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import getImage from '../../../utils/getImage'

const Social = ({ name }) => {
  const { profile } = useSelector((state) => state.user)
  const theme = useTheme()
  const [Skin, setSkin] = useState(null)

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.socialIcon.skin}/index.jsx`
      )
      setSkin(() => response.default)
    }

    getSkin()
  }, [theme])

  const shareFacebook = () => {
    const link = encodeURIComponent(document.location.href)
    const redirectUri = 'https://facebook.com'
    const url = `https://www.facebook.com/dialog/feed?app_id=211260932253820&redirect_uri=${redirectUri}&display=page&link=${link}`

    window.open(url, '_blank')
  }

  const shareTwitter = () => {
    const coupleName = profile.couple_name.replace(/&/, 'e')
    const link = encodeURIComponent(document.location.href)
    const text = `Compartilhou uma página do site de casamento de ${coupleName}`
    const url = `https://www.twitter.com/intent/tweet?text=${text}&url=${link}`

    window.open(url, '_blank')
  }

  const sharePinterest = () => {
    const coupleName = profile.couple_name.replace(/&/, 'e')
    const image = profile.avatar
      ? getImage(profile.login, profile.avatar, 'home', 'userfile')
      : null
    const link = encodeURIComponent(document.location.href)
    const description = `Site de casamento de ${coupleName}`
    const url = `https://www.pinterest.com/pin/create/button/?url=${link}${
      image ? `&media=${image}` : ''
    }&description=${description}`

    window.open(url, '_blank')
  }

  const share = () => {
    const shareFunctions = {
      facebook: shareFacebook,
      twitter: shareTwitter,
      pinterest: sharePinterest,
    }

    if (shareFunctions[name]) shareFunctions[name]()
  }

  return Skin && <Skin name={name} share={share} />
}

Social.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Social
