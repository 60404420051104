import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as gtm from '../../../../lib/gtm';
import getPhoneRegionCode from '../../../../utils/getPhoneRegionCode';
import TextField from '../../../atoms/TextField';
import PhoneTextField from '../../../molecules/PhoneTextField';
import { saveFormData } from '../../../../store/modules/cart/actions';

import schema from './validations';
import * as S from './styles';

const phoneRegionCode = getPhoneRegionCode();

const Info = forwardRef((props, ref) => {
  const { infos, products, card } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState(null);
  const [phoneCountry, setPhoneCountry] = useState(
    infos?.phoneCountry || phoneRegionCode
  );
  const {
    watch,
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: infos.name || '',
      email: infos.email || '',
      phone: infos.phone || '',
      message: infos.message || '',
    },
  });
  const messageField = watch('message');

  const maxLength = 460;

  const messageLength = useMemo(() => {
    const remaining = maxLength - (messageField?.length || 0);
    const text = remaining > 1 ? 'caracteres restantes' : 'caractere restante';

    return `${remaining} ${text}`;
  }, [messageField]);

  const onSubmit = ({ name, email, phone, message }) => {
    dispatch(
      saveFormData({
        name,
        email,
        phone,
        phoneCountry,
        message,
      })
    );

    setFeedback(null);
  };

  const returnValidationToParent = () => {
    handleSubmit(onSubmit)();
    return schema.isValidSync(getValues());
  };

  useEffect(() => {
    const items = products.map((product, index) => ({
      index,
      item_id: String(product.id),
      item_name: product.name,
      currency: 'BRL',
      item_category: product.category.name,
      item_category2: 'Site do casal',
      item_list_id: product.item_list_id,
      item_list_name: product.item_list_name,
      price: product.price,
      quantity: product.quantity,
    }));

    if (card) {
      items.push({
        index: items.length,
        item_id: String(card.id),
        item_name: 'Cartão iCasei',
        currency: 'BRL',
        item_category: 'Cartão impresso',
        item_category2: 'Site do casal',
        price: card.price,
        item_variant: card.description,
      });
    }

    gtm.beginCheckout({
      value: products.reduce(
        (partialTotal, product) =>
          partialTotal + product.price * product.quantity,
        card?.price || 0
      ),
      items,
    });
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    handleSubmit: returnValidationToParent,
  }));

  return (
    <S.Wrapper>
      {feedback && (
        <S.Feedback type={feedback.type}>{feedback.message}</S.Feedback>
      )}
      <S.Form novalidate>
        <TextField
          required
          type="text"
          name="name"
          label="name"
          register={register}
          invalid={!!errors?.name?.message}
          helperText={errors?.name?.message}
          placeholder="Nome completo"
        />
        <TextField
          required
          type="text"
          name="email"
          label="email"
          register={register}
          invalid={!!errors?.email?.message}
          helperText={errors?.email?.message}
          placeholder="E-mail"
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <PhoneTextField
              {...field}
              errorText={errors?.phone?.message}
              phoneCountry={phoneCountry}
              setPhoneCountry={setPhoneCountry}
            />
          )}
        />
        <TextField
          required
          lines={3}
          type="text"
          name="message"
          label="message"
          register={register}
          maxLength={maxLength}
          invalid={!!errors?.message?.message}
          helperText={errors?.message?.message || messageLength}
          placeholder="Mensagem"
        />
      </S.Form>
    </S.Wrapper>
  );
});

export default Info;
