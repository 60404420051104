import styled from 'styled-components'

export const Collapse = styled.div`
  max-width: 350px;
  width: 100%;
  color: var(--content);
  box-shadow: 0 0 3px #00000017;
  background: var(--body);
`

export const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  align-items: center;
  font-family: var(--base-font-family);
  cursor: pointer;
  user-select: none;
`

export const CollapseHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const CollapseHeaderAction = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transform: rotate(180deg);
  transition: transform 800ms;

  ${(p) =>
    p.open
      ? `
      transform: rotate(0);
  `
      : ''}

  svg path {
    fill: var(--content);
  }
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lightBackground);
  margin-right: 12px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  font-family: var(--base-font-family);
  color: var(--content);
  font-size: 14px;
  line-height: 28px;
`

export const Description = styled.span`
  font-family: var(--base-font-family);
  color: var(--content);
  font-size: 10px;
  line-height: 18px;
`

export const CollapseBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--content);
  transition: max-height 1000ms;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 13px;
  }

  ${(p) =>
    p.render
      ? `
      overflow: hidden;
      max-height: 0;
      position: initial;
      opacity: 1;
  `
      : `
      position: absolute;
      opacity: 0;
  `}

  ${(p) =>
    p.open
      ? `
      max-height: ${p.maxHeight}px;
  `
      : ''}
`
