import styled, { css } from 'styled-components'

export const Wrapper = styled.a.attrs({
  className: 'brand',
})`
  ${({ theme }) => css`
    display: block;
    max-width: 80px;
    width: 100%;
    max-height: 45px;
    height: 100%;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    color: ${theme.colors[theme.components.brand.color]};
    opacity: ${theme.components.brand.opacity
      ? theme.components.brand.opacityLevel
      : 1};

    &:hover,
    &:focus {
      color: ${theme.colors[theme.components.brand.colorHover]};
      opacity: ${theme.components.brand.opacity
        ? theme.components.brand.opacityLevelHover
        : 1};
    }
  `};
`

export default Wrapper
