import AC from './assets/AC.svg'
import AD from './assets/AD.svg'
import AE from './assets/AE.svg'
import AF from './assets/AF.svg'
import AG from './assets/AG.svg'
import AI from './assets/AI.svg'
import AL from './assets/AL.svg'
import AM from './assets/AM.svg'
import AO from './assets/AO.svg'
import AQ from './assets/AQ.svg'
import AR from './assets/AR.svg'
import AS from './assets/AS.svg'
import AT from './assets/AT.svg'
import AU from './assets/AU.svg'
import AW from './assets/AW.svg'
import AX from './assets/AX.svg'
import AZ from './assets/AZ.svg'
import BA from './assets/BA.svg'
import BB from './assets/BB.svg'
import BD from './assets/BD.svg'
import BE from './assets/BE.svg'
import BF from './assets/BF.svg'
import BG from './assets/BG.svg'
import BH from './assets/BH.svg'
import BI from './assets/BI.svg'
import BJ from './assets/BJ.svg'
import BL from './assets/BL.svg'
import BM from './assets/BM.svg'
import BN from './assets/BN.svg'
import BO from './assets/BO.svg'
import BQ from './assets/BQ.svg'
import BR from './assets/BR.svg'
import BS from './assets/BS.svg'
import BT from './assets/BT.svg'
import BV from './assets/BV.svg'
import BW from './assets/BW.svg'
import BY from './assets/BY.svg'
import BZ from './assets/BZ.svg'
import CA from './assets/CA.svg'
import CC from './assets/CC.svg'
import CD from './assets/CD.svg'
import CF from './assets/CF.svg'
import CG from './assets/CG.svg'
import CH from './assets/CH.svg'
import CI from './assets/CI.svg'
import CK from './assets/CK.svg'
import CL from './assets/CL.svg'
import CM from './assets/CM.svg'
import CN from './assets/CN.svg'
import CO from './assets/CO.svg'
import CR from './assets/CR.svg'
import CU from './assets/CU.svg'
import CV from './assets/CV.svg'
import CW from './assets/CW.svg'
import CX from './assets/CX.svg'
import CY from './assets/CY.svg'
import CZ from './assets/CZ.svg'
import DE from './assets/DE.svg'
import DJ from './assets/DJ.svg'
import DK from './assets/DK.svg'
import DM from './assets/DM.svg'
import DO from './assets/DO.svg'
import DZ from './assets/DZ.svg'
import EC from './assets/EC.svg'
import EE from './assets/EE.svg'
import EG from './assets/EG.svg'
import EH from './assets/EH.svg'
import ER from './assets/ER.svg'
import ES from './assets/ES.svg'
import ET from './assets/ET.svg'
import EU from './assets/EU.svg'
import FI from './assets/FI.svg'
import FJ from './assets/FJ.svg'
import FK from './assets/FK.svg'
import FM from './assets/FM.svg'
import FO from './assets/FO.svg'
import FR from './assets/FR.svg'
import GA from './assets/GA.svg'
import GB from './assets/GB.svg'
import GD from './assets/GD.svg'
import GE from './assets/GE.svg'
import GF from './assets/GF.svg'
import GG from './assets/GG.svg'
import GH from './assets/GH.svg'
import GI from './assets/GI.svg'
import GL from './assets/GL.svg'
import GM from './assets/GM.svg'
import GN from './assets/GN.svg'
import GP from './assets/GP.svg'
import GQ from './assets/GQ.svg'
import GR from './assets/GR.svg'
import GS from './assets/GS.svg'
import GT from './assets/GT.svg'
import GU from './assets/GU.svg'
import GW from './assets/GW.svg'
import GY from './assets/GY.svg'
import HK from './assets/HK.svg'
import HM from './assets/HM.svg'
import HN from './assets/HN.svg'
import HR from './assets/HR.svg'
import HT from './assets/HT.svg'
import HU from './assets/HU.svg'
import IC from './assets/IC.svg'
import ID from './assets/ID.svg'
import IE from './assets/IE.svg'
import IL from './assets/IL.svg'
import IM from './assets/IM.svg'
import IN from './assets/IN.svg'
import IO from './assets/IO.svg'
import IQ from './assets/IQ.svg'
import IR from './assets/IR.svg'
import IS from './assets/IS.svg'
import IT from './assets/IT.svg'
import JE from './assets/JE.svg'
import JM from './assets/JM.svg'
import JO from './assets/JO.svg'
import JP from './assets/JP.svg'
import KE from './assets/KE.svg'
import KG from './assets/KG.svg'
import KH from './assets/KH.svg'
import KI from './assets/KI.svg'
import KM from './assets/KM.svg'
import KN from './assets/KN.svg'
import KP from './assets/KP.svg'
import KR from './assets/KR.svg'
import KW from './assets/KW.svg'
import KY from './assets/KY.svg'
import KZ from './assets/KZ.svg'
import LA from './assets/LA.svg'
import LB from './assets/LB.svg'
import LC from './assets/LC.svg'
import LI from './assets/LI.svg'
import LK from './assets/LK.svg'
import LR from './assets/LR.svg'
import LS from './assets/LS.svg'
import LT from './assets/LT.svg'
import LU from './assets/LU.svg'
import LV from './assets/LV.svg'
import LY from './assets/LY.svg'
import MA from './assets/MA.svg'
import MC from './assets/MC.svg'
import MD from './assets/MD.svg'
import ME from './assets/ME.svg'
import MF from './assets/MF.svg'
import MG from './assets/MG.svg'
import MH from './assets/MH.svg'
import MK from './assets/MK.svg'
import ML from './assets/ML.svg'
import MM from './assets/MM.svg'
import MN from './assets/MN.svg'
import MO from './assets/MO.svg'
import MP from './assets/MP.svg'
import MQ from './assets/MQ.svg'
import MR from './assets/MR.svg'
import MS from './assets/MS.svg'
import MT from './assets/MT.svg'
import MU from './assets/MU.svg'
import MV from './assets/MV.svg'
import MW from './assets/MW.svg'
import MX from './assets/MX.svg'
import MY from './assets/MY.svg'
import MZ from './assets/MZ.svg'
import NA from './assets/NA.svg'
import NC from './assets/NC.svg'
import NE from './assets/NE.svg'
import NF from './assets/NF.svg'
import NG from './assets/NG.svg'
import NI from './assets/NI.svg'
import NL from './assets/NL.svg'
import NO from './assets/NO.svg'
import NP from './assets/NP.svg'
import NR from './assets/NR.svg'
import NU from './assets/NU.svg'
import NZ from './assets/NZ.svg'
import OM from './assets/OM.svg'
import PA from './assets/PA.svg'
import PE from './assets/PE.svg'
import PF from './assets/PF.svg'
import PG from './assets/PG.svg'
import PH from './assets/PH.svg'
import PK from './assets/PK.svg'
import PL from './assets/PL.svg'
import PM from './assets/PM.svg'
import PN from './assets/PN.svg'
import PR from './assets/PR.svg'
import PS from './assets/PS.svg'
import PT from './assets/PT.svg'
import PW from './assets/PW.svg'
import PY from './assets/PY.svg'
import QA from './assets/QA.svg'
import RE from './assets/RE.svg'
import RO from './assets/RO.svg'
import RS from './assets/RS.svg'
import RU from './assets/RU.svg'
import RW from './assets/RW.svg'
import SA from './assets/SA.svg'
import SB from './assets/SB.svg'
import SC from './assets/SC.svg'
import SD from './assets/SD.svg'
import SE from './assets/SE.svg'
import SG from './assets/SG.svg'
import SH from './assets/SH.svg'
import SI from './assets/SI.svg'
import SJ from './assets/SJ.svg'
import SK from './assets/SK.svg'
import SL from './assets/SL.svg'
import SM from './assets/SM.svg'
import SN from './assets/SN.svg'
import SO from './assets/SO.svg'
import SR from './assets/SR.svg'
import SS from './assets/SS.svg'
import ST from './assets/ST.svg'
import SV from './assets/SV.svg'
import SX from './assets/SX.svg'
import SY from './assets/SY.svg'
import SZ from './assets/SZ.svg'
import TA from './assets/TA.svg'
import TC from './assets/TC.svg'
import TD from './assets/TD.svg'
import TF from './assets/TF.svg'
import TG from './assets/TG.svg'
import TH from './assets/TH.svg'
import TJ from './assets/TJ.svg'
import TK from './assets/TK.svg'
import TL from './assets/TL.svg'
import TM from './assets/TM.svg'
import TN from './assets/TN.svg'
import TO from './assets/TO.svg'
import TR from './assets/TR.svg'
import TT from './assets/TT.svg'
import TV from './assets/TV.svg'
import TW from './assets/TW.svg'
import TZ from './assets/TZ.svg'
import UA from './assets/UA.svg'
import UG from './assets/UG.svg'
import UM from './assets/UM.svg'
import US from './assets/US.svg'
import UY from './assets/UY.svg'
import UZ from './assets/UZ.svg'
import VA from './assets/VA.svg'
import VC from './assets/VC.svg'
import VE from './assets/VE.svg'
import VG from './assets/VG.svg'
import VI from './assets/VI.svg'
import VN from './assets/VN.svg'
import VU from './assets/VU.svg'
import WF from './assets/WF.svg'
import WS from './assets/WS.svg'
import XK from './assets/XK.svg'
import YE from './assets/YE.svg'
import YT from './assets/YT.svg'
import ZA from './assets/ZA.svg'
import ZM from './assets/ZM.svg'
import ZW from './assets/ZW.svg'
import GE_AB from './assets/GE-AB.svg'
import GE_OS from './assets/GE-OS.svg'

const flags = {
  AC,
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
  'GE-AB': GE_AB,
  'GE-OS': GE_OS,
}

export default flags
