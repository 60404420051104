import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeBanner } from '../../../store/modules/appBanner/actions';
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

import * as S from './styles';
import LogoIcasei from '../../../assets/img/icasei-480x480.png';

const AppBanner = () => {
  const dispatch = useDispatch();

  const {
    user: { profile, mobile },
    appBanner,
  } = useSelector((state) => state);

  const close = useCallback(() => {
    dispatch(closeBanner());
  }, []);

  const redirectToDownload = useCallback(() => {
    if (window.device.android()) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.mobile.iCasei',
        '_blank'
      );
    } else {
      window.open(
        `https://icasei.app.link/t2tdkraXyH?id=${profile.couple_id}`,
        '_blank'
      );
    }

    close();
  }, []);

  return (
    <S.Wrapper show={mobile ? 0 : appBanner.show ? 1 : 0}>
      <S.CloseButton onClick={close}><CloseIcon /></S.CloseButton>
      <S.Image src={LogoIcasei} alt="Logo iCasei" />
      <S.Text>
        Baixe o App do nosso casamento e busque: <strong>{profile.login}</strong>
      </S.Text>
      <S.DownloadButton onClick={redirectToDownload}>
        Baixar
      </S.DownloadButton>
    </S.Wrapper>
  );
};

export default AppBanner;
