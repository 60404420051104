import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import withPlayerMobile from '../../utils/withPlayerMobile';

import * as S from './styles';

function Player5({
  skin,
  audioState,
  volume,
  progress,
  title,
  artist,
  musicTotal,
  musicSelected,
  loading,
  onChangeVolume,
  onPlay,
  onPause,
  onNext,
  onPrev,
}) {
  const [show, setShow] = useState(false);
  const paused = useMemo(() => audioState !== 'playing', [audioState]);

  function handleToggleShow() {
    setShow(!show);
  }

  return (
    <S.Player show={show ? 1 : 0} skin={skin}>
      <S.PlayerTop>
        <S.PlayerTopLeft>
          <S.MusicInfo>
            <S.MusicTitle>{loading ? 'Carregando...' : title}</S.MusicTitle>
            <S.MusicArtist>{loading ? 'Carregando...' : artist}</S.MusicArtist>
          </S.MusicInfo>

          <S.Tracks>
            {musicSelected}/{musicTotal}
          </S.Tracks>

          <S.Controls>
            <S.Prev title="Música anterior" skin={skin} onClick={onPrev} />
            {paused ? (
              <S.Play title="Tocar Música" onClick={onPlay} skin={skin} />
            ) : (
              <S.Pause title="Pausar" onClick={onPause} skin={skin} />
            )}
            <S.Next title="Próxima canção" skin={skin} onClick={onNext} />
          </S.Controls>
        </S.PlayerTopLeft>

        <S.ShowButton
          type="button"
          onClick={handleToggleShow}
          skin={skin}
          aria-label="Abrir/Fechar player de música"
        >
          <S.ArrowIcon show={show ? 1 : 0} skin={skin} />
        </S.ShowButton>
      </S.PlayerTop>

      <S.PlayerBottom>
        <S.PlayerBottomLeft>
          <S.Progress>
            <S.PregressTrack progress={progress} skin={skin} />
            <S.PregressBar skin={skin} progress={progress} />
          </S.Progress>

          <S.Volume>
            <S.VolumeIcon skin={skin} />
            <S.VolumeWrrapper skin={skin}>
              <S.VolumeSlider volume={volume} skin={skin} />
              <S.VolumeRange
                min={0}
                max={100}
                value={volume}
                onChange={onChangeVolume}
                aria-label="Volume"
              />
            </S.VolumeWrrapper>
          </S.Volume>
        </S.PlayerBottomLeft>

        <S.EqButton
          onClick={paused ? onPlay : onPause}
          skin={skin}
          aria-label="Equalizador"
        >
          <S.EqIcon paused={paused || loading ? 1 : 0} skin={skin} />
        </S.EqButton>
      </S.PlayerBottom>
    </S.Player>
  );
}

Player5.propTypes = {
  skin: PropTypes.string.isRequired,
  audioState: PropTypes.string,
  volume: PropTypes.number,
  progress: PropTypes.number,
  title: PropTypes.string,
  artist: PropTypes.string,
  musicTotal: PropTypes.number,
  musicSelected: PropTypes.number,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onChangeVolume: PropTypes.func,
};

Player5.defaultProps = {
  audioState: 'stopped',
  volume: 100,
  progress: 0,
  title: '',
  artist: '',
  musicTotal: 0,
  musicSelected: 0,
  loading: false,
  onPlay: () => {},
  onPause: () => {},
  onNext: () => {},
  onPrev: () => {},
  onChangeVolume: () => {},
};

export default withPlayerMobile(Player5);
