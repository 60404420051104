import PlayerVintage from './PlayerVintage'
import Player80s from './Player80s'
import PlayerNeon from './PlayerNeon'
import PlayerModern from './PlayerModern'
import PlayerMobile from './PlayerMobile'
import PlayerDefault from './PlayerDefault'
import Player1 from './Player1'
import Player2 from './Player2'
import Player3 from './Player3'
import Player4 from './Player4'
import Player5 from './Player5'
import Player6 from './Player6'

const skins = {
  'player-vintage': PlayerVintage,
  'player-80s': Player80s,
  'player-neon': PlayerNeon,
  'player-modern': PlayerModern,
  'player-mobile': PlayerMobile,
  'player-default': PlayerDefault,
  player1: Player1,
  player2: Player2,
  player3: Player3,
  player4: Player4,
  player5: Player5,
  player6: Player6,
}

export default skins
