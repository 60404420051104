import axios from 'axios'
import PropTypes from 'prop-types'
import getUrlParameterByName from '../utils/getUrlParameterByName'
import * as repositoryStatic from './static/api/v1'

const interceptorApi = ({ api, cancelTokenSource }) => {
  // Add a request interceptor
  api.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      cancelTokenSource = axios.CancelToken.source()
      // eslint-disable-next-line no-param-reassign
      config.cancelToken = cancelTokenSource.token
      cancelTokenSource.cancel({
        cd: true,
        url: config?.url,
        params: config?.params,
      })
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => {
      return response
    },
    (response) => {
      if (response?.message?.cd && response?.message?.url) {
        const id = String(
          getUrlParameterByName('id', response.message.url) ||
            response.message?.params?.id ||
            response.message?.params?.external_id
        )

        let key = response.message.url
          .replace(/(^\/api\/v1\/)|(api\/v1\/)|(.json)|(\?.*)|(#.*)/g, '')
          .split('/')

        if (!!id && id !== 'undefined' && !key.includes(id)) {
          key.push(id)
        }

        switch (key.length) {
          case 1:
            key = key.join('')
            return repositoryStatic[key]
          case 2:
            key = key.join('_')
            return repositoryStatic[key]
          case 3:
            key = key.slice(1).join('_')
            return repositoryStatic[key]
          default:
            return Promise.reject(response)
        }
      }
      return Promise.reject(response)
    }
  )
}

interceptorApi.propTypes = {
  api: PropTypes.instanceOf(axios).isRequired,
  cancelTokenSource: PropTypes.object.isRequired,
}

export default interceptorApi
