import styled from 'styled-components'

import Icons from './assets/sprt-pl4.png'
import eq from './assets/equalizer.gif'
import eqOff from './assets/eq_off.gif'

const config = {
  skin1: {
    color: '#00a1ff',
    hover: '#85d0ff',
    progressColor: 'rgba(0, 174, 239, 0.2)',
  },
  skin2: {
    color: '#E31B5F',
    hover: '#f5a0be',
    progressColor: 'rgba(240, 89, 95, 0.2)',
  },
  skin3: {
    color: '#ffffff',
    hover: '#b3b3b3',
    progressColor: 'rgba(255, 255, 255, 0.2)',
  },
  skin4: {
    color: '#92bc01',
    hover: '#deebb2',
    progressColor: 'rgba(146, 188, 1, 0.2)',
  },
  skin5: {
    color: '#A001EC',
    hover: '#e3b2fa',
    progressColor: 'rgba(255,99, 93, 0.2)',
  },
  skin6: {
    color: '#FFD700',
    hover: '#fff4b2',
    progressColor: 'rgba(255, 215, 0, 0.2)',
  },
}

export const Player = styled.div.attrs({
  className: 'music-player',
})`
  height: 93px;
  width: 616px;
  max-width: ${(props) => (props.show ? '100%' : 'auto')};
  background: #01131d;
  color: ${(props) => config[props.skin].color};
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  text-align: left;
  position: fixed;
  bottom: 0;
  left: ${(props) => (props.show ? '0' : '-566px')};
  transition: left 0.5s;
  z-index: 10;
`

export const PlayerTop = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerTopLeft = styled.div`
  flex: 1;
  padding: 0 15px;

  display: flex;
  align-items: center;
`

export const MusicInfo = styled.div`
  flex: 1;
`

export const MusicTitle = styled.p.attrs({
  className: 'music-player__title notranslate',
})`
  font-size: 13px;
  font-size: 0.8125rem;
`

export const MusicArtist = styled.p.attrs({
  className: 'music-player__artist notranslate',
})`
  font-size: 11px;
  font-size: 0.6875rem;
`

export const Tracks = styled.p`
  font-size: 20px;
  font-size: 1.25rem;
  padding: 0 15px;
`

export const Controls = styled.div.attrs({
  className: 'music-player__controls',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Prev = styled.button.attrs({
  className: 'music-player__previous',
})`
  background: url(${Icons}) no-repeat;
  background-color: ${(props) => config[props.skin].color};
  background-position: 0 -8px;
  width: 24px;
  height: 24px;
  border: none;
  &:hover {
    background-color: ${(props) => config[props.skin].hover};
  }
`

export const Pause = styled.button.attrs({
  className: 'music-player__pause',
})`
  background: url(${Icons}) no-repeat;
  background-color: ${(props) => config[props.skin].color};
  background-position: -26px -42px;
  width: 32px;
  height: 32px;
  border: none;
  &:hover {
    background-color: ${(props) => config[props.skin].hover};
  }
`

export const Play = styled.button.attrs({
  className: 'music-player__play',
})`
  background: url(${Icons}) no-repeat;
  background-color: ${(props) => config[props.skin].color};
  background-position: -26px -3px;
  width: 32px;
  height: 32px;
  border: none;
  &:hover {
    background-color: ${(props) => config[props.skin].hover};
  }
`

export const Next = styled.button.attrs({
  className: 'music-player__next',
})`
  background: url(${Icons}) no-repeat;
  background-color: ${(props) => config[props.skin].color};
  background-position: -61px -8px;
  width: 24px;
  height: 24px;
  border: none;
  &:hover {
    background-color: ${(props) => config[props.skin].hover};
  }
`

export const ShowButton = styled.button`
  border: none;
  background: none;
  height: 100%;
  width: 50px;
  border-left: 1px solid ${(props) => config[props.skin].progressColor};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ArrowIcon = styled.div`
  width: 13px;
  height: 15px;
  background: url(${Icons}) no-repeat;
  background-position: ${(props) =>
    props.show ? '-96px -12px' : '-110px -12px'};
  background-color: ${(props) => config[props.skin].color};
`

export const PlayerBottom = styled.div`
  height: 50%;
  display: flex;
`

export const PlayerBottomLeft = styled.div`
  padding: 0 15px;

  flex: 1;
  display: flex;
  align-items: center;
`

export const Progress = styled.div.attrs({
  className: 'music-player__progress',
})`
  position: relative;
  flex: 1;
  height: 6px;
  background: rgba(150, 150, 150, 0.2);
`

export const PregressTrack = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress * 2}%`,
  },
}))`
  position: absolute;
  height: 100%;
  max-width: 100%;
  background: ${(props) => config[props.skin].progressColor};
`

export const PregressBar = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  position: relative;
  height: 100%;
  background: ${(props) => config[props.skin].color};
  box-shadow: 0 0 12px ${(props) => config[props.skin].color};
`

export const Volume = styled.div.attrs({
  className: 'music-player__volume',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  margin-left: 15px;

  @media (max-width: 616px) {
    display: none;
  }
`

export const VolumeIcon = styled.div`
  height: 12px;
  width: 12px;
  background: url(${Icons}) no-repeat;
  background-position: -127px -15px;
  background-color: ${(props) => config[props.skin].color};
`

export const VolumeWrrapper = styled.div`
  position: relative;
  width: 130px;
  height: 6px;
  background-color: ${(props) => config[props.skin].progressColor};
`

export const VolumeSlider = styled.div.attrs((props) => ({
  style: {
    width: `${props.volume}%`,
  },
}))`
  position: absolute;
  height: 100%;
  background-color: ${(props) => config[props.skin].color};
  box-shadow: 0 0 12px ${(props) => config[props.skin].color};
`

export const VolumeRange = styled.input.attrs({
  type: 'range',
})`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`

export const EqButton = styled.button.attrs({
  className: 'music-player__equalizer',
})`
  border: none;
  background: none;
  height: 100%;
  width: 50px;
  border-left: 1px solid ${(props) => config[props.skin].progressColor};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const EqIcon = styled.div`
  width: 29px;
  height: 15px;
  background: url(${(props) => (props.paused ? eqOff : eq)}) no-repeat center
    center;
  background-color: ${(props) => config[props.skin].color};
`
